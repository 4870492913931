import app from "../firebase/FirebaseAppService";
import store from "../../redux/store/store";
import _ from "lodash"

const db = app.firestore();
const collectionName = "ServiceRequests";
const collectionGroupName = "requestsList";

export default async function getAllServiceRequests(filterValue) {
    try {
        const state = store.getState();
        if (state.authReducer.claims === "admin") {
            const snapshot = await db.collectionGroup(collectionGroupName).orderBy("referenceId").get();
            if (snapshot.empty) {
                return {
                    status: "success",
                    docs: []
                };
            }
            var promises = snapshot.docs.map(async function(doc) {
                const uid = doc.data().uid;
                const result = {}
                result.docData = doc.data();
                const userDetails = await db.collection('Users').doc(uid).get();
                if (userDetails.exists) {
                    result.userDetails = userDetails.data();
                }
                if (
                    doc.data().serviceRequestStatus === "Work Completed" ||
                    doc.data().serviceRequestStatus === "Cancelled By User" ||
                    doc.data().serviceRequestStatus === "Cancelled By Admin" ||
                    doc.data().serviceRequestStatus === "Cancelled By Engineer"
                ) {
                    result.serviceRequestStatus =
                        `${doc.data().serviceRequestStatus} & ${doc.data().paymentStatus
                        }`

                } else {
                    result.serviceRequestStatus = doc.data().serviceRequestStatus;
                }
                console.log(result);
                return {
                    result
                }
            })
            const docs = await Promise.all(promises);
            if (filterValue === "All requests") {
                return {
                    status: "success",
                    docs: docs
                };
            }
            console.log(filterValue);
            const docByFilterValue = _.filter(docs, function(o) {
                return o.result.serviceRequestStatus === filterValue;
            });
            return {
                status: "success",
                docs: docByFilterValue
            };
        } else if (state.authReducer.claims === "serviceEngineer") {
            const snapshot = await db.collectionGroup(collectionGroupName).where("workAssignment.engineer.uid", "==", app.auth().currentUser.uid).orderBy("referenceId").get();
            if (snapshot.empty) {
                console.log("empty");
                return {
                    status: "failure",
                    docs: []
                };
            } else {
                console.log(snapshot.docs.length);
                const promises = snapshot.docs.map(async(doc) => {
                    const uid = doc.data().uid;
                    const result = {}
                    result.docData = doc.data();
                    const userDetails = await db.collection('Users').doc(uid).get();
                    if (userDetails.exists) {
                        result.userDetails = userDetails.data();
                    }
                    if (
                        doc.data().serviceRequestStatus === "Work Completed" ||
                        doc.data().serviceRequestStatus === "Cancelled By User" ||
                        doc.data().serviceRequestStatus === "Cancelled By Admin" ||
                        doc.data().serviceRequestStatus === "Cancelled By Engineer"
                    ) {
                        result.serviceRequestStatus =
                            `${doc.data().serviceRequestStatus} & ${doc.data().paymentStatus
                            }`

                    } else {
                        result.serviceRequestStatus = doc.data().serviceRequestStatus;
                    }
                    console.log(result);
                    return {
                        result
                    }
                });
                const docs = await Promise.all(promises);
                if (filterValue === "All requests") {
                    return {
                        status: "success",
                        docs: docs
                    };
                }
                console.log(filterValue);
                const docByFilterValue = _.filter(docs, function(o) {
                    return o.result.serviceRequestStatus === filterValue;
                });
                return {
                    status: "success",
                    docs: docByFilterValue
                };
            }
        }
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            docs: []
        };
    }
}

export async function getAnSrDetails(docId, uid) {
    console.log(app.auth().currentUser.uid);
    try {
        const doc = await db.collection(collectionName).doc(uid).collection(collectionGroupName).doc(docId).get();
        console.log(app.auth().currentUser.uid);
        const userDetails = await db.collection('Users').doc(uid).get();
        if (userDetails.exists) {
            _.merge({ userDetails: userDetails.data() }, doc.data())
        }
        return {
            status: "success",
            doc: {
                docData: _.merge({ userDetails: userDetails.data() }, doc.data()),
                docId: doc.id,
                docRef: doc.ref
            }
        };
    } catch (error) {
        return {
            status: "failure",
            doc: {

            }
        };
    }
}

export async function postServiceRequest(sr) {
    const docRef = db.collection(collectionName).doc(app.auth().currentUser.uid).collection(collectionGroupName).doc();
    try {
        await docRef.set(sr);
        return {
            status: "success",
            docId: docRef.id,
            docData: sr
        };
    } catch (error) {
        return {
            status: "failure",
            docId: docRef.id,
            docData: {

            }
        };
    }
}

export async function putServiceRequest(enquiry, docId) {
    const docRef = db.collection(collectionName).doc(app.auth().currentUser.uid).collection(collectionGroupName).doc(docId);
    try {
        await docRef.set(enquiry, {
            merge: true
        });
        return {
            status: "success",
            docId: docRef.id,
            docData: enquiry
        };
    } catch (error) {
        return {
            status: "failure",
            docId: docRef.id,
            docData: {

            }
        };
    }
}

export async function deleteServiceRequest(docId) {
    const docRef = db.collection(collectionName).doc(app.auth().currentUser.uid).collection(collectionGroupName).doc(docId);
    try {
        await docRef.delete();
        return {
            status: "success",
            docId: docRef.id,
            docData: {

            }
        };
    } catch (error) {
        return {
            status: "failure",
            docId: docRef.id,
            docData: {

            }
        };
    }
}

export async function getAllServiceEngineers(productCategory) {
    try {
        const snapshot = await db.collection("Users").where("userType", "==", "Service Engineer").where("category","array-contains",productCategory).get();
        if (snapshot.empty) {
            return {
                status: "success",
                docs: []
            };
        }
        var docs = snapshot.docs.map(function(doc) {
            return {
                docData: doc.data(),
                docId: doc.id
            }
        })
        return {
            status: "success",
            docs
        };
    } catch (error) {
        return {
            status: "failure",
            docs: []
        };
    }
}