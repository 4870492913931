import app from "../firebase/FirebaseAppService";

const db = app.firestore();

export default async function AddCorporateProducts(ProductData,status,uid){
        const docRef = db.collection("Corporate").doc();
        try {
            return await docRef.set({corporateProducts:ProductData,status:status,uid:uid},{merge:true}).then(() => {
                return {
                    status: "success",
                    docId: docRef.id,
                    docData: ProductData,
                };
            });
        } catch (error) {
            console.log(error);
            return {
                status: "failure",
                docId: docRef.id,
                docData: {
    
                }
            };
        }
    }

    export async function DeleteCorporateProduct(docId,status){
        const docRef = db.collection("Corporate").doc(docId);
        try {
            return await docRef.set({status:status},{merge:true}).then(() => {
                return {
                    status: "success",
                    docId: docRef.id,
                };
            });
        } catch (error) {
            console.log(error);
            return {
                status: "failure",
                docId: docRef.id,
                docData: {
    
                }
            };
        }
    }
    export async function getCorporateProducts(uid){
        const snapshot = await db.collection("Corporate").where("uid", "==",uid).get();
        try {

            if (snapshot.empty) {
                console.log("here")
                return {
                    status: "success",
                    docData: []
                };
            }
            var docs = snapshot.docs.map(function(doc) {
                return {
                    docData: doc.data(),
                    docId: doc.id
                }
            })
            return {
                status: "success",
                docs
            };
            
        } catch (error) {
            console.log(error);
            return {
                status: "failure",
                docData: {
    
                }
            };
        }
    }