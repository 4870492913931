import app from "../firebase/FirebaseAppService";

export default async function verifyEmail() {
    var user = app.auth().currentUser;
    return user.sendEmailVerification().then(function() {
        return {
            status: "success"
        }
    }).catch(function(error) {
        console.log(error);
        return {
            status: "failure"
        }
    });
}
