import { Button } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import signOut from "../../services/auth/SignOutService";

export default function SignOut() {
  function handleClick(e) {
    e.preventDefault();
    signOut();
  }
  return (
    <>
      <Button color="inherit" onClick={handleClick}>
        <ExitToAppIcon fontSize="small" style={{ marginRight: "8px" }} />
        Sign Out
      </Button>
    </>
  );
}
