import app from "../firebase/FirebaseAppService";
import _ from "lodash";

const db = app.firestore();

export async function getAllPayments() {
    try {
        const requestsSnapshot = await app.firestore().collectionGroup('requestsList').get();
        if (requestsSnapshot.empty) {
            console.log("empty");
            return [];
        } else {
            const promises = requestsSnapshot.docs.map(async function(doc) {
                if (doc.data().paymentInfo && doc.data().paymentInfo.paymentMethod === "Cash") {
                    return [{
                        razorpayPaymentId: `cash_${doc.id}`,
                        razorpayOrderId: "",
                        serviceRequestId: doc.id,
                        referenceId: doc.data().referenceId,
                        paymentDate: doc.data().paymentInfo.paymentDate ? doc.data().paymentInfo.paymentDate : "",
                        paymentMethod: "cash",
                        paymentStatus: "paid",
                        refundStatus: "",
                        amount: parseInt(doc.data().estimate.FinalTotalWithTax)
                    }]
                } else if (doc.data().paymentInfo && doc.data().paymentInfo.paymentMethod === "Online") {
                    if (doc.data().digitalPaymentInfo) {
                        const paymentsArray = doc.data().digitalPaymentInfo.payments.items.map(function(payment) {
                            console.log("online" + payment.created_at);
                            return {
                                razorpayPaymentId: payment.id,
                                razorpayOrderId: payment.order_id,
                                serviceRequestId: doc.id,
                                referenceId: doc.data().referenceId,
                                paymentDate: payment.created_at,
                                paymentMethod: payment.method,
                                paymentStatus: payment.status,
                                refundStatus: payment.refund_status,
                                amount: (parseInt(payment.amount) / 100)
                            }
                        });
                        return paymentsArray;
                    } else {
                        console.log("none")
                        return null;
                    }
                } else {
                    return null;
                }
            });
            const payments = await Promise.all(promises);
            const notNullPayments = _.filter(payments, function(o) {
                return o != null
            });
            const sortedPayments = [];
            notNullPayments.forEach(function(arr) {
                arr.forEach((payment) => {
                    sortedPayments.push(payment);
                })
            });
            return sortedPayments;
        }
    } catch (error) {
        console.log(error);
        return [];
    }
}