export const logOutSuccess = (user) => {
    return {
        type: "logout/sucess",
    };
};

export const logOutFailure = (user) => {
    return {
        type: "logout/failure",
        payload: user
    };
};