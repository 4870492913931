import app from "../firebase/FirebaseAppService";
const db = app.firestore();

export async function setEstimateAccepatance(docId, uid, status, srCancelationReason) {
    try {
        const docRef = db.doc(`ServiceRequests/${uid}/requestsList/${docId}`);
        docRef.set({
            serviceRequestStatus: status,
            cancellationReason: srCancelationReason
        }, {
            merge: true
        })
        return {
            status: "success",
        };
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
        };
    }
}