import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { getAllServiceEngineers } from "../../../../../services/service-request";
import { sendNotificationEmail } from "../../../../../services/notification/Notify";
import { getUserDetailsByUid } from "../../../../../services/ManageUsers/ManageUsersService";
import {
  assignEngineer,
  markWorkCompletion,
} from "../../../../../services/service-request/engineer";
import { setWorkFlowActiveState } from "../../../../../redux/actions/servicerequest";
import store from "../../../../../redux/store/store";
import sendPaymentMethod, {
  CorporateWorkCompleted,
} from "../../../../../services/actions/PaymentMethod";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { setEstimateAccepatance } from "../../../../../services/service-request/srAcceptance";
import RescheduleSr from "../../../../../services/service-request/rescheduleSr";
import { postEstimate } from "../../../../../services/estimate/sendEstimate";
import _ from "lodash";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
  actionGrid: {
    padding: theme.spacing(1),
  },
}));

function Actions(props) {
  console.log("reducer:" + props.srReducer.workFlowStatus);

  const classes = useStyles();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [serviceFromTime, setServiceFromTime] = useState(new Date());
  const [serviceTillTime, setServiceTillTime] = useState(new Date());
  const [tillTimeError, setTillTimeError] = useState("");

  const [engineerProductCategory, setEngineerProductCategory] = useState("");

  const [cancelReqBtnDisable, setCancelRequestBtnDisable] = useState(false);
  const [rescheduleReqBtndisable, setRescheduleReqBtnDisabled] = useState(
    false
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function handelServiceFromTime(time) {
    setServiceFromTime(time);
  }

  function handelServiceTillTime(time) {
    setServiceTillTime(time);
  }

  useEffect(() => {
    validateTime();
    function validateTime() {
      var start_time = moment(serviceFromTime.getTime()).format("HH:mm A");
      var end_time = moment(serviceTillTime.getTime()).format("HH:mm A");
      if (start_time !== "" && end_time !== "") {
        setTillTimeError("");
        if (end_time < start_time) {
          setTillTimeError(
            "Service Till Time should always be greater than From Time"
          );
        }
      }
    }
  }, [serviceFromTime, serviceTillTime]);

  const [open, setOpen] = useState(false);
  const [openRescheduleReq, setOpenRescheduleReq] = useState(false);
  const [openWcDialog, setOpenWcDialog] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenWc = () => {
    setOpenWcDialog(true);
  };

  const handleCloseWc = () => {
    setOpenWcDialog(false);
  };

  const handleClickRescheduleReq = () => {
    setOpenRescheduleReq(true);
  };

  const handleCloseRescheduleReq = () => {
    setOpenRescheduleReq(false);
  };

  const [serviceEngineers, setServiceEngineers] = useState([]);
  const [selectedEngineer, setSelectedEngineer] = useState("None");

  useEffect(() => {
    async function fetchData() {
      if (props.serviceRequest.serviceRequest) {
        setEngineerProductCategory(
          props.serviceRequest.serviceRequest.productDetails.productCategory
        );
        const result = await getAllServiceEngineers(engineerProductCategory);
        if (result.status === "success") {
          setServiceEngineers([
            ...result.docs,
            {
              docData: {
                firstName: "None",
                lastName: "",
              },
              docId: "None",
            },
          ]);
        }
      }
    }
    fetchData();
  }, [engineerProductCategory, props.serviceRequest.serviceRequest]);

  console.log(serviceEngineers);

  function handleChange(e) {
    if (serviceEngineers.length !== 0) {
      const selected = serviceEngineers.filter(
        (engineer) => engineer.docId === e.target.value
      );
      setSelectedEngineer(selected[0].docId);
    }
  }
  function handleChangePaymentMethod(e) {
    setPaymentMethod(e.target.value);
  }

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("");

  const handleClickOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };
  async function handleAssignENgineer() {
    //console.log(store.getState());
    const result = await postEstimate(
      props.srReducer.estimate,
      props.srReducer.workFlowStatus,
      props.uid,
      props.docId
    );
    if (result.status === "success") {
      const selected = serviceEngineers.filter(
        (engineer) => engineer.docId === selectedEngineer
      );
      if (selected[0].docData.firstName !== "None") {
        const result = await assignEngineer(
          {
            firstName: selected[0].docData.firstName,
            lastName: selected[0].docData.lastName,
            email: selected[0].docData.email,
            phoneNumber: selected[0].docData.phoneNumber,
            uid: selected[0].docId,
          },
          props.docId,
          props.uid
        );
        const user = await getUserDetailsByUid(props.uid);
        console.log(result, user);
        if (result.status === "success" && user.status === "success") {
          setSnackBarMsg("Engineer Assigned Successfully");
          setSnackBarSeverity("success");
          handleClickOpenSnackBar();
          store.dispatch(
            setWorkFlowActiveState({ step: 4, status: "Work in progress" })
          );
          /*sendNotificationEmail(
            selected[0].docData.email,
            "new work item",
            `A new work  has been assigned to you by ${process.env.REACT_APP_NAME} admin`
          );
          sendNotificationEmail(
            user.docData.email,
            `#sr ${props.docId}`,
            `  An enginner has been assinged to #sr ${
              props.docId
            }. Details are as follows: \nName: ${
              selected[0].docData.firstName + " " + selected[0].docData.lastName
            }, \nEmail: ${selected[0].docData.email}, \nPhone Number: ${
              selected[0].docData.phoneNumber
            }`
          );*/
          setOpen(false);
        } else {
          setSnackBarMsg("Engineer Assignment failed...Try again Later");
          setSnackBarSeverity("error");
          handleClickOpenSnackBar();
        }
      }
    } else {
      setSnackBarMsg("Engineer Assignment failed...Try again Later");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  }

  async function handleCompletion() {
    const result = await markWorkCompletion(props.docId, props.uid);
    if (result.status === "success") {
      store.dispatch(
        setWorkFlowActiveState({ step: 5, status: "Work is completed" })
      );
      setSnackBarMsg("Marked as Completed");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
    } else {
      setSnackBarMsg("Unable to Mark as Completed");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  }

  const handleSubmitPaymentMethod = async () => {
    const PaymentMethod = await sendPaymentMethod(
      paymentMethod,
      props.uid,
      props.docId
    );
    if (PaymentMethod.status === "success") {
      handleCompletion();
      setOpenWcDialog(false);
      setSnackBarMsg("Response Saved");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
    } else {
      setOpenWcDialog(true);
      setSnackBarMsg("Unable to Save Response");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  };

  const handleCorpWc = async () => {
    const result = await markWorkCompletion(props.docId, props.uid);
    if (result.status === "success") {
      store.dispatch(
        setWorkFlowActiveState({ step: 5, status: "Work is completed" })
      );
      handleCompletion();
      setSnackBarMsg("Response Saved");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
    } else {
      setSnackBarMsg("Unable to Save Response");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  };

  async function handleReschedule(e) {
    e.preventDefault();
    const reschedule = await RescheduleSr(
      selectedDate,
      serviceFromTime,
      serviceTillTime,
      props.docId,
      props.uid
    );
    console.log(reschedule);
    if (reschedule.status === "success") {
      setOpenRescheduleReq(false);
      setSnackBarMsg("Service Request Resheduled");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
    } else {
      setOpenRescheduleReq(true);
      setSnackBarMsg("Unable to Reshedule Service Request");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  }

  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const [cancelRequestReason, setCancelRequestReason] = useState("");
  const handleCancelReasonChange = (e) => {
    setCancelRequestReason(e.target.value);
  };

  const handleClickOpenCancelRequest = () => {
    setOpenCancelDialog(true);
  };

  const handleCloseCancelRequest = () => {
    setOpenCancelDialog(false);
    setCancelRequestReason("");
  };
  async function handleEstimateCancellation(e) {
    e.preventDefault();
    let status = "";
    if (props.authReducer.claims === "admin") {
      status = "Cancelled By Admin";
    } else {
      status = "Cancelled By Engineer";
    }
    const result = await setEstimateAccepatance(
      props.docId,
      props.uid,
      status,
      cancelRequestReason
    );
    if (result.status === "success") {
      store.dispatch(setWorkFlowActiveState({ step: 5, status: status }));
      setSnackBarMsg("Response Saved");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
      handleCloseCancelRequest();
      setCancelRequestReason("");
      setCancelRequestBtnDisable(true);
      setRescheduleReqBtnDisabled(true);
    } else {
      alert("Unable to save Response");
      setSnackBarMsg("Unable to save response");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
      setCancelRequestBtnDisable(false);
      setRescheduleReqBtnDisabled(false);
    }
  }

  console.log(props.userType.includes("Corporate"));
  console.log(props);
  return (
    <div className={classes.root}>
      {/* Cancel Request Dialog */}
      <Dialog
        open={openCancelDialog}
        onClose={handleCloseCancelRequest}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="form-dialog-title">Cancel Request</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <form className={classes.form} noValidate>
              <TextField
                id="outlined-multiline-static"
                label="Cancelation Reason"
                multiline
                rows={4}
                variant="outlined"
                size="medium"
                value={cancelRequestReason}
                onChange={handleCancelReasonChange}
                fullWidth
                marginLeft="normal"
              />
            </form>{" "}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelRequest} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleEstimateCancellation}
            color="primary"
            disabled={_.isEmpty(cancelRequestReason) ? true : false}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* Service engineer dialog */}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Select Service Engineer
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              The work will be Assigned to selected Service Engineer
            </DialogContentText>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel htmlFor="demo-simple-select-outlined-label">
                Service Engineer
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedEngineer}
                onChange={handleChange}
                label="Service Engineer"
              >
                {serviceEngineers.slice(0, 5).map((serviceEngineer) => (
                  <MenuItem value={`${serviceEngineer.docId}`}>
                    {`${serviceEngineer.docData.firstName} ${serviceEngineer.docData.lastName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleAssignENgineer}
              color="primary"
              disabled={selectedEngineer === "None" ? true : false}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* Work completed dialog */}
      <div>
        <Dialog
          open={openWcDialog}
          onClose={handleCloseWc}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Select Payment Method
          </DialogTitle>
          <DialogContent>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
              size="small"
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Payment Method
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={paymentMethod}
                onChange={handleChangePaymentMethod}
                label="Service Engineer"
              >
                <MenuItem value="Online">Online</MenuItem>
                <MenuItem value="Cash">Cash</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseWc} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmitPaymentMethod} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* Reschedule Request Dialog */}
      <div>
        <Dialog
          open={openRescheduleReq}
          onClose={handleCloseRescheduleReq}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Select Date and Time</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Request will be Resheduled to selected Date and Time
            </DialogContentText>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container xs={12} spacing={1}>
                <Grid item xs={12} className={classes.pickers}>
                  <KeyboardDatePicker
                    fullWidth
                    margin="normal"
                    id="date-picker-dialog"
                    label="Date"
                    format="dd/MM/yyyy"
                    minDate={new Date()}
                    value={selectedDate}
                    onChange={handleDateChange}
                    variant="outlined"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.pickers}>
                  <KeyboardTimePicker
                    fullWidth
                    margin="normal"
                    id="time-picker"
                    label="From"
                    value={serviceFromTime}
                    onChange={handelServiceFromTime}
                    variant="outlined"
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.pickers}>
                  <KeyboardTimePicker
                    fullWidth
                    margin="normal"
                    id="time-picker1"
                    label="Till"
                    value={serviceTillTime}
                    onChange={handelServiceTillTime}
                    helperText={tillTimeError}
                    error={tillTimeError}
                    variant="outlined"
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseRescheduleReq} color="primary">
              Cancel
            </Button>
            <Button color="primary" onClick={handleReschedule}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* Buttons */}
      <Grid container spacing={3} className={classes.actionGrid}>
        <Grid item xs={12} sm={3}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClickOpenCancelRequest}
            style={{ width: "100%" }}
            disabled={
              props.srReducer.workFlowStatus.includes("Cancelled") ||
              props.srReducer.workFlowStatus.includes("Work Completed")
                ? true
                : false
            }
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClickRescheduleReq}
            style={{ width: "100%" }}
            disabled={
              props.srReducer.workFlowStatus.includes("Cancelled") ||
              props.srReducer.workFlowStatus.includes("Work Completed")
                ? true
                : false
            }
          >
            Reschedule
          </Button>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClickOpen}
            style={{
              width: "100%",
            }}
            disabled={
              props.srReducer.workFlowStatus.includes("Cancelled") ||
              !props.srReducer.workFlowStatus.includes("Pending")
                ? true
                : false
            }
          >
            Assign Engineer
          </Button>
        </Grid>
        {console.log(props.userType === "Corporate")}
        <Grid item xs={12} sm={3}>
          <Button
            variant="outlined"
            color="primary"
            onClick={
              props.userType.includes("Corporate")
                ? handleCorpWc
                : handleClickOpenWc
            }
            style={{
              width: "100%",
            }}
            disabled={
              props.srReducer.workFlowStatus.includes("Cancelled") ||
              !props.srReducer.workFlowStatus.includes("Accepted By User")
                ? true
                : false
            }
          >
            Mark Complete
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(Actions);
