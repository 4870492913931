import app from "../firebase/FirebaseAppService";

const db = app.firestore();
//const uid = app.auth().currentUser ? app.auth().currentUser.uid : ""
const collectionName = "ProductCategories";

export default async function getAllProductCategories() {
    try {
        const snapshot = await db.collection(collectionName).get();
        if (snapshot.empty) {
            return {
                status: "success",
                docs: []
            };
        }
        var docs = snapshot.docs.map(function(doc) {
            return {
                docData: doc.data(),
                docId: doc.id
            }
        })
        return {
            status: "success",
            docs
        };
    } catch (error) {
        return {
            status: "failure",
            docs: []
        };
    }
}