import app from "../firebase/FirebaseAppService";
import _ from "lodash";

const db = app.firestore();
const collectionName = "ProductCategories";

export default async function getProductCatagories() {
    try {
        const snapshot = await db.collection(collectionName).where("isEnabled", "==", true).get();
        console.log(snapshot.size);
        if (snapshot.empty) {
            return {
                status: "success",
                docs: []
            };
        }
        var docs = snapshot.docs.map(function(doc) {
            return {
                docData: doc.data(),
                docId: doc.id,
                docRef: doc.ref
            }
        });
        const uniqueArray = _.uniqBy(docs, function(o) { return o.docData.categoryName; });
        const sortedArray = _.sortBy(uniqueArray, function(o) { return o.docData.categoryName; });
        return {
            status: "success",
            docs: sortedArray
        };
    } catch (error) {
        return {
            status: "failure",
            docs: []
        };
    }
}

export async function addCategory(type, category, parentCategoryId, parentCategoryName,minCharges) {
    const docRef = db.collection(collectionName).doc();
    try {
        if (type === "parent") {
            await docRef.set({
                categoryName: _.capitalize(category),
                isParentCategory: true,
                parentCategoryId: parentCategoryId,
                parentCategoryName: parentCategoryName,
                isEnabled: true
            });
            return {
                status: "success",
                docData: category,
                docId:docRef.id,
                docRef:docRef
            };
        } else {
            await docRef.set({
                categoryName: _.capitalize(category),
                isParentCategory: false,
                parentCategoryId: parentCategoryId,
                parentCategoryName: parentCategoryName,
                isEnabled: true,
                minimumCharges:minCharges
            });
            return {
                status: "success",
                docData: category,
                docId:docRef.id,
                docRef:docRef
            };
        }
    } catch (error) {
        return {
            status: "failure",
            docData: {

            }
        };
    }
}

export async function getUserCategories() {
    try {
        const snapshot = await db.collectionGroup("categorySuggestions").get();
        console.log(snapshot.size);
        if (snapshot.empty) {
            return {
                status: "success",
                docs: []
            };
        }
        var docs = snapshot.docs.map(function(doc) {
            return {
                docData: doc.data(),
                docId: doc.id,
                docRef: doc.ref
            }
        });
        const uniqueArray = _.uniqBy(docs, function(o) { return o.docData.categoryName; });
        const sortedArray = _.sortBy(uniqueArray, function(o) { return o.docData.categoryName; });
        return {
            status: "success",
            docs: sortedArray
        };
    } catch (error) {
        return {
            status: "failure",
            docs: []
        };
    }
}

export async function deleteUserCategory(docRef) {
    return docRef.delete().then(() => {
        return {
            status: "success"
        };
    });
}

export async function deleteExistingCategory(docRef) {
    return docRef.delete().then(() => {
        return {
            status: "success"
        };
    });
}