import app from "../firebase/FirebaseAppService";
import moment from "moment";

const db = app.firestore();
const collectionGroupName = "enquiriesList";

export default async function getAllEnquiries() {
    try {
        const snapshot = await db.collectionGroup(collectionGroupName).orderBy("referenceId").get();
        console.log(snapshot.size);
        if (snapshot.empty) {
            return {
                status: "success",
                docs: []
            };
        }
        var promises = snapshot.docs.map(async function(doc) {
            const result = {}
            result.docId = doc.id;
            result.docData = doc.data();
            result.responseDetails = doc.data().responseDetails;
            const userDetails = await db.collection('Users').doc(doc.data().uid).get();
            if (userDetails.exists) {
                result.userDetails = userDetails.data();
            }
            return result;
        });
        const docs = await Promise.all(promises);
        return {
            status: "success",
            docs
        };
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            docs: []
        };
    }
}

export async function postEnquiryResponse(response, uid, docId) {
    const docRef = db.collection("Enquiries").doc(uid).collection("enquiriesList").doc(docId);
    try {
        await docRef.set({
            responseDetails: {
                response: response,
                date: moment().format()
            },
        }, {
            merge: true
        });
        return {
            status: "success",
            docId: docRef.id,
            docData: response
        };
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            docId: docRef.id,
            docData: {

            }
        };
    }
}