import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Chip,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  Paper,
  Grid,
  Typography,
  Backdrop,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import store from "../../../../redux/store/store";
import { fetchNewServiceRequests } from "../../../../redux/actions/servicerequest";
import AsynchronousSearch from "../../search/AsyncSearch";
import getAllServiceRequests from "../../../../services/service-request";
import CircularProgressBar from "../../../support/progress/CircularProgressBar";

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },
  avatar: {
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  paper1: {
    padding: theme.spacing(1),
    textAlign: "right",
    color: theme.palette.text.secondary,
  },
  toolbar: {
    marginLeft: theme.spacing(-2),
  },
  header: {
    margin: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Results = ({ className, ...rest }) => {
  const classes = useStyles();

  const [isMobileView, setIsMobileView] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 767) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  });

  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const props = { ...rest };
    setCustomers(props.newSrList);
  }, [rest]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [sortValue, setSortValue] = useState("All requests");
  const handleChange = async (event) => {
    setSortValue(event.target.value);
    handleBackDropOpen();
    const result = await getAllServiceRequests(event.target.value);
    if (result.status === "success") {
      const sortedSrList = _.sortBy(
        [...result.docs],
        [
          function (o) {
            return o.result.docData.referenceId;
          },
        ]
      ).reverse();
      store.dispatch(fetchNewServiceRequests([...sortedSrList]));
      handleBackDropClose();
    } else {
      handleBackDropClose();
    }
  };

  const [searchByValue] = useState("RequestId");

  const [openBackdrop, setOpenBackdrop] = useState(false);

  function handleBackDropClose() {
    setOpenBackdrop(false);
  }

  function handleBackDropOpen() {
    setOpenBackdrop(true);
  }

  return (
    <Paper className={classes.paper}>
     <Grid container spacing={1}>
     <Grid item xs={12} sm={4} >
      <Typography variant="h4" gutterBottom className={classes.header}>
        Service Requests
      </Typography>
      </Grid>
        <Grid item xs={12} sm={4} >
          <Toolbar className={classes.toolbar}>
            <AsynchronousSearch
              searchType="serviceRequest"
              searchByValue={searchByValue}
            />
          </Toolbar>
        </Grid>
        <Grid item xs={12} sm={4} >
          <Toolbar className={classes.toolbar}>
            <Box display="flex" flexGrow={1}>
              {/* whatever is on the left side */}
            </Box>
            <FormControl
              className={classes.formControl}
              variant="outlined"
              size="small"
            >
              <InputLabel htmlFor="age-native-simple">Sort</InputLabel>
              <Select
                label="Sort"
                native
                value={sortValue}
                onChange={handleChange}
              >
                <option value={"All requests"}>All requests</option>
                <option value={"Pending"}>Pending</option>
                <option value={"Engineer Assigned"}>Engineer Assigned</option>
                <option value={"Accepted By User"}>Accepted By User</option>
                <option value={"Work Completed & Paid"}>
                  Work Completed & Paid
                </option>
                <option value={"Work Completed & Unpaid"}>
                  Work Completed & Unpaid
                </option>
                <option value={"Cancelled By User & Paid"}>
                  Cancelled By User & Paid
                </option>
                <option value={"Cancelled By User & Unpaid"}>
                  Cancelled By User & Unpaid
                </option>
                <option value={"Cancelled By Admin & Paid"}>
                  Cancelled By Admin & Paid
                </option>
                <option value={"Cancelled By Admin & Unpaid"}>
                  Cancelled By Admin & Unpaid
                </option>
                <option value={"Cancelled By Engineer & Paid"}>
                  Cancelled By Engineer & Paid
                </option>
                <option value={"Cancelled By Engineer & Unpaid"}>
                  Cancelled By Engineer & Unpaid
                </option>
              </Select>
            </FormControl>
          </Toolbar>
        </Grid>
      </Grid>
      <TablePagination
        component="div"
        count={customers.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[25, 50, 200]}
      />
      <div
        style={{
          overflowX: "auto",
          display: isMobileView === true ? "block" : "none",
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Service Requests</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(limit > 0
              ? customers.slice(page * limit, page * limit + limit)
              : customers
            ).map((customer) => (
              <TableRow hover key={customer.docId}>
                <TableCell>
                  <div className={classes.root}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>
                          <b>Sr Id : </b>
                          {`${customer.result.docData.referenceId}`}
                        </Grid>
                        <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>
                          <b>Product Name : </b>
                          {`${customer.result.docData.serviceRequest.productDetails.productName}`}
                        </Grid>
                        <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>
                          <b>Product Category : </b>
                          {`${customer.result.docData.serviceRequest.productDetails.productCategory}`}
                        </Grid>
                        <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>
                          <b>Phone : </b>
                          {` ${customer.result.userDetails.phoneNumber}`}
                        </Grid>
                        <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>
                          <b>Email : </b>
                          {`${customer.result.userDetails.email} `}
                        </Grid>
                        <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>
                          <b>Request Time : </b>
                          {_.isUndefined(customer.result.docData)
                            ? "loading..."
                            : _.isUndefined(
                                customer.result.docData.serviceRequest
                                  .serviceDetails.rescheduledDate
                              )
                            ? moment
                                .unix(
                                  customer.result.docData.serviceRequest
                                    .serviceDetails.selectedDate.seconds
                                )
                                .format("DD/MM/YYYY")
                            : moment
                                .unix(
                                  customer.result.docData.serviceRequest
                                    .serviceDetails.rescheduledDate.seconds
                                )
                                .format("DD/MM/YYYY")}{" "}
                          {_.isUndefined(customer.result.docData)
                            ? "loading..."
                            : _.isUndefined(
                                customer.result.docData.serviceRequest
                                  .serviceDetails.rescheduledTillTime
                              )
                            ? moment
                                .unix(
                                  customer.result.docData.serviceRequest
                                    .serviceDetails.serviceFromTime
                                )
                                .format("HH:mm A")
                            : moment
                                .unix(
                                  customer.result.docData.serviceRequest
                                    .serviceDetails.rescheduledFromTime
                                )
                                .format("HH:mm A")}{" "}
                          {_.isUndefined(customer.result.docData)
                            ? "loading..."
                            : _.isUndefined(
                                customer.result.docData.serviceRequest
                                  .serviceDetails.rescheduledTillTime
                              )
                            ? moment
                                .unix(
                                  customer.result.docData.serviceRequest
                                    .serviceDetails.serviceTillTime
                                )
                                .format("HH:mm A")
                            : moment
                                .unix(
                                  customer.result.docData.serviceRequest
                                    .serviceDetails.rescheduledTillTime
                                )
                                .format("HH:mm A")}
                        </Grid>
                        <Grid item xs={12} sm={2} style={{ textAlign: "left" }}>
                          <Chip
                            label={`${customer.result.serviceRequestStatus}`}
                            style={{
                              border:
                                customer.result.serviceRequestStatus ===
                                  "Work Completed & Paid" ||
                                customer.result.serviceRequestStatus.includes(
                                  "Paid"
                                )
                                  ? "2px solid #8bc34a"
                                  : customer.result.serviceRequestStatus ===
                                      "Estimate to be sent" ||
                                    customer.result.serviceRequestStatus ===
                                      "Estimate is sent to user" ||
                                    customer.result.serviceRequestStatus ===
                                      "Accepted by user" ||
                                    customer.result.serviceRequestStatus ===
                                      "Engineer is not assigned"
                                  ? "2px solid #1E90FF"
                                  : customer.result.serviceRequestStatus.includes(
                                      "Cancelled"
                                    )
                                  ? "2px solid #e53935"
                                  : "2px solid #ffc400",
                              color:
                                customer.result.serviceRequestStatus ===
                                  "Work Completed & Paid" ||
                                _.includes(
                                  customer.result.serviceRequestStatus ===
                                    "Paid"
                                )
                                  ? "#8bc34a"
                                  : customer.result.serviceRequestStatus ===
                                      "Estimate to be sent" ||
                                    customer.result.serviceRequestStatus ===
                                      "Estimate is sent to user" ||
                                    customer.result.serviceRequestStatus ===
                                      "Accepted by user" ||
                                    customer.result.serviceRequestStatus ===
                                      "Engineer is not assigned"
                                  ? "#1E90FF"
                                  : customer.result.serviceRequestStatus.includes(
                                      "Cancelled"
                                    )
                                  ? "#e53935"
                                  : "#ffc400",
                              fontWeight: "bold",
                              width: "100%",
                              backgroundColor: "#fff",
                            }}
                            size="small"
                          ></Chip>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          style={{ textAlign: "center" }}
                        >
                          <Link
                            to={`sr-details/${customer.result.docData.docId}/${customer.result.docData.uid}`}
                            style={{
                              textDecoration: "none",
                              width: "100%",
                            }}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              style={{
                                width: "100%",
                              }}
                            >
                              VIEW
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </Paper>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div
        style={{
          overflowX: "auto",
          display: isMobileView === true ? "none" : "block",
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Service Request ID</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Product Category</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Request Date & Time</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(limit > 0
              ? customers.slice(page * limit, page * limit + limit)
              : customers
            ).map((customer) => (
              <TableRow hover key={customer.docId}>
                <TableCell>{`${customer.result.docData.referenceId}`}</TableCell>
                <TableCell>
                  {`${customer.result.docData.serviceRequest.productDetails.productName}`}
                </TableCell>
                <TableCell>
                  {`${customer.result.docData.serviceRequest.productDetails.productCategory}`}
                </TableCell>

                <TableCell>
                  {` ${customer.result.userDetails.phoneNumber}`}
                </TableCell>
                <TableCell>
                  {`  ${customer.result.userDetails.email}`}
                </TableCell>

                <TableCell>
                  {_.isUndefined(customer.result.docData)
                    ? "loading..."
                    : _.isUndefined(
                        customer.result.docData.serviceRequest.serviceDetails
                          .rescheduledDate
                      )
                    ? moment
                        .unix(
                          customer.result.docData.serviceRequest.serviceDetails
                            .selectedDate.seconds
                        )
                        .format("DD/MM/YYYY")
                    : moment
                        .unix(
                          customer.result.docData.serviceRequest.serviceDetails
                            .rescheduledDate.seconds
                        )
                        .format("DD/MM/YYYY")}{" "}
                  {_.isUndefined(customer.result.docData)
                    ? "loading..."
                    : _.isUndefined(
                        customer.result.docData.serviceRequest.serviceDetails
                          .rescheduledTillTime
                      )
                    ? moment
                        .unix(
                          customer.result.docData.serviceRequest.serviceDetails
                            .serviceFromTime
                        )
                        .format("HH:mm A")
                    : moment
                        .unix(
                          customer.result.docData.serviceRequest.serviceDetails
                            .rescheduledFromTime
                        )
                        .format("HH:mm A")}{" "}
                  {_.isUndefined(customer.result.docData)
                    ? "loading..."
                    : _.isUndefined(
                        customer.result.docData.serviceRequest.serviceDetails
                          .rescheduledTillTime
                      )
                    ? moment
                        .unix(
                          customer.result.docData.serviceRequest.serviceDetails
                            .serviceTillTime
                        )
                        .format("HH:mm A")
                    : moment
                        .unix(
                          customer.result.docData.serviceRequest.serviceDetails
                            .rescheduledTillTime
                        )
                        .format("HH:mm A")}
                </TableCell>
                <TableCell>
                  <Chip
                    label={`${customer.result.serviceRequestStatus}`}
                    style={{
                      border:
                        customer.result.serviceRequestStatus ===
                          "Work Completed & Paid" ||
                        customer.result.serviceRequestStatus.includes("Paid")
                          ? "2px solid #8bc34a"
                          : customer.result.serviceRequestStatus ===
                              "Estimate to be sent" ||
                            customer.result.serviceRequestStatus ===
                              "Estimate is sent to user" ||
                            customer.result.serviceRequestStatus ===
                              "Accepted by user" ||
                            customer.result.serviceRequestStatus ===
                              "Engineer is not assigned"
                          ? "2px solid #1E90FF"
                          : customer.result.serviceRequestStatus.includes(
                              "Cancelled"
                            )
                          ? "2px solid #e53935"
                          : "2px solid #ffc400",
                      color:
                        customer.result.serviceRequestStatus ===
                          "Work Completed & Paid" ||
                        _.includes(
                          customer.result.serviceRequestStatus === "Paid"
                        )
                          ? "#8bc34a"
                          : customer.result.serviceRequestStatus ===
                              "Estimate to be sent" ||
                            customer.result.serviceRequestStatus ===
                              "Estimate is sent to user" ||
                            customer.result.serviceRequestStatus ===
                              "Accepted by user" ||
                            customer.result.serviceRequestStatus ===
                              "Engineer is not assigned"
                          ? "#1E90FF"
                          : customer.result.serviceRequestStatus.includes(
                              "Cancelled"
                            )
                          ? "#e53935"
                          : "#ffc400",
                      fontWeight: "bold",
                      width: "100%",
                      backgroundColor: "#fff",
                    }}
                    size="small"
                  ></Chip>
                </TableCell>
                <TableCell>
                  <Link
                    to={`sr-details/${customer.result.docData.docId}/${customer.result.docData.uid}`}
                    style={{
                      textDecoration: "none",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      style={{
                        width: "100%",
                      }}
                    >
                      VIEW
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgressBar display={true} />
        </Backdrop>
      </div>
    </Paper>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => state.srReducer;
export default connect(mapStateToProps)(Results);
