import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Typography,
} from "@material-ui/core";
import getProfile, {
  putName,
  putProfile,
} from "../../../services/account/ProfileService";
import store from "../../../redux/store/store";
import { fillProfile } from "../../../redux/actions/profile";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import app from "../../../services/firebase/FirebaseAppService";

const useStyles = makeStyles(() => ({
  root: {},
}));

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const states = [
    {
      label: "AN",
      value: "Andaman and Nicobar Islands",
    },
    {
      label: "AP",
      value: "Andhra Pradesh",
    },
    {
      label: "AR",
      value: "Arunachal Pradesh",
    },
    {
      label: "AS",
      value: "Assam",
    },
    {
      label: "BR",
      value: "Bihar",
    },
    {
      label: "CG",
      value: "Chandigarh",
    },
    {
      label: "CH",
      value: "Chhattisgarh",
    },
    {
      label: "DH",
      value: "Dadra and Nagar Haveli",
    },
    {
      label: "DD",
      value: "Daman and Diu",
    },
    {
      label: "DL",
      value: "Delhi",
    },
    {
      label: "GA",
      value: "Goa",
    },
    {
      label: "GJ",
      value: "Gujarat",
    },
    {
      label: "HR",
      value: "Haryana",
    },
    {
      label: "HP",
      value: "Himachal Pradesh",
    },
    {
      label: "JK",
      value: "Jammu and Kashmir",
    },
    {
      label: "JH",
      value: "Jharkhand",
    },
    {
      label: "KA",
      value: "Karnataka",
    },
    {
      label: "KL",
      value: "Kerala",
    },
    {
      label: "LD",
      value: "Lakshadweep",
    },
    {
      label: "MP",
      value: "Madhya Pradesh",
    },
    {
      label: "MH",
      value: "Maharashtra",
    },
    {
      label: "MN",
      value: "Manipur",
    },
    {
      label: "ML",
      value: "Meghalaya",
    },
    {
      label: "MZ",
      value: "Mizoram",
    },
    {
      label: "NL",
      value: "Nagaland",
    },
    {
      label: "OR",
      value: "Odisha",
    },
    {
      label: "PY",
      value: "Puducherry",
    },
    {
      label: "PB",
      value: "Punjab",
    },
    {
      label: "RJ",
      value: "Rajasthan",
    },
    {
      label: "SK",
      value: "Sikkim",
    },
    {
      label: "TN",
      value: "Tamil Nadu",
    },
    {
      label: "TS",
      value: "Telangana",
    },
    {
      label: "TR",
      value: "Tripura",
    },
    {
      label: "UP",
      value: "Uttar Pradesh",
    },
    {
      label: "UK",
      value: "Uttarakhand",
    },
    {
      label: "WB",
      value: "West Bengal",
    },
  ];

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("Maharashtra");
  const [pinCode, setPinCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");

  function handleFnameChange(e) {
    setFname(e.target.value);
    console.log(addressLine1);
  }

  function handleLnameChange(e) {
    setLname(e.target.value);
    console.log(addressLine1);
  }

  useEffect(() => {
    fetchProfile();
    console.log("111");
  }, []);

  const [emailVarified] = useState(
    app.auth().currentUser ? app.auth().currentUser.emailVerified : false
  );

  async function fetchProfile() {
    const result = await getProfile();
    if (result.status === "success") {
      console.log(result);
      const addressDetails = result.profile.docData.addressDetails;
      if (addressDetails) {
        setAddressLine1(addressDetails.addressLine1);
        setAddressLine2(addressDetails.addressLine2);
        setCity(addressDetails.city);
        setState(addressDetails.state);
        setPinCode(addressDetails.pinCode);
        setPhoneNumber(addressDetails.phoneNumber);
      }
      setFname(result.profile.docData.firstName);
      setLname(result.profile.docData.lastName);
      setEmail(result.profile.docData.email);
    }
  }

  function handleAdressLine1Change(e) {
    setAddressLine1(e.target.value);
    console.log(addressLine1);
  }

  function handleAdressLine2Change(e) {
    setAddressLine2(e.target.value);
    console.log(addressLine2);
  }

  function handleCityChange(e) {
    setCity(e.target.value);
  }

  function handleStateChange(e) {
    setState(e.target.value);
    console.log(state);
  }

  function handlePinCodeChange(e) {
    setPinCode(e.target.value);
  }

  function handlePhoneNumberChange(e) {
    setPhoneNumber(e.target.value);
  }

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("");

  const handleClickOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  async function handleClick(e) {
    e.preventDefault();
    const addressDetails = {
      addressLine1,
      addressLine2,
      city,
      state,
      pinCode,
      phoneNumber,
    };
    const profileDetailsAll = {
      fname,
      lname,
      addressLine1,
      addressLine2,
      city,
      state,
      pinCode,
      phoneNumber,
    };
    console.log(addressDetails);
    const result = await putProfile(addressDetails, fname, lname);
    console.log(result);
    if (result.status === "success") {
      setSnackBarMsg("Profile Updated successfully");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
      store.dispatch(fillProfile(profileDetailsAll));
    } else {
      setSnackBarMsg("Unable to Update Profile");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  }

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={emailVarified ? 4 : 6} xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                required
                value={email}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              style={{
                display: emailVarified ? "none" : "block",
              }}
            >
              <Typography
                color="textSecondary"
                style={{
                  width: "100%",
                  marginRight: "auto",
                  marginLeft: "auto",
                  marginTop: "auto",
                  marginBottom: "auto",
                  float: "right",
                  display: "flex",
                }}
              >
                {emailVarified ? (
                  <></>
                ) : (
                  <Link
                    variant="body2"
                    to="/verify"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      fullWidth
                    >
                      verify email
                    </Button>
                  </Link>
                )}
              </Typography>{" "}
            </Grid>
            <Grid item md={emailVarified ? 4 : 6} xs={12}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                onChange={handleFnameChange}
                required
                value={fname}
                variant="outlined"
              />
            </Grid>
            <Grid item md={emailVarified ? 4 : 6} xs={12}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                onChange={handleLnameChange}
                required
                value={lname}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address Line1"
                name="firstName"
                onChange={handleAdressLine1Change}
                required
                value={addressLine1}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address Line2"
                name="lastName"
                onChange={handleAdressLine2Change}
                required
                value={addressLine2}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="City"
                name="email"
                onChange={handleCityChange}
                required
                value={city}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Select State"
                name="state"
                onChange={handleStateChange}
                required
                select
                SelectProps={{ native: true }}
                value={state}
                variant="outlined"
              >
                {states.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.value}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Pin Code"
                name="country"
                onChange={handlePinCodeChange}
                required
                value={pinCode}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                onChange={handlePhoneNumberChange}
                type="number"
                value={phoneNumber}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" onClick={handleClick}>
            Save details
          </Button>
        </Box>
      </Card>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
