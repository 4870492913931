import app from "../firebase/FirebaseAppService";
const db = app.firestore();
const collectionName = "ServiceRequests";
const collectionGroupName = "requestsList"

export default async function RescheduleSr(date, fromTime, tillTime, docId, uid) {
    const docRef = await db.collection(collectionName).doc(uid).collection(collectionGroupName).doc(docId)
    try {
        await docRef.set({
            serviceRequest: { serviceDetails: { rescheduledDate: date, rescheduledFromTime: fromTime, rescheduledTillTime: tillTime, resheduledBy: "admin" } },
        }, { merge: true })
        return {
            status: "success",
            selectedData: date,
            fromTime: fromTime,
            tillTime: tillTime,
            docId,
            uid
        }
    } catch (error) {
        console.log(error)
        return {
            docId
        }
    }
}