import app from "../firebase/FirebaseAppService";

export default async function signInWithEmailPassword(email, password) {
    return app
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
            // Signed in
            var user = userCredential.user;
            console.log(user.email);
            return {
                "status": "success"
            }
        })
        .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode + " " + errorMessage);
            return {
                "status": "failure"
            }
        });
}