import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Fab,
  FormHelperText,
  Icon,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { postEstimate } from "../../../../../services/estimate/sendEstimate";
import _ from "lodash";
import { connect } from "react-redux";
import store from "../../../../../redux/store/store";
import {
  setWorkFlowActiveState,
  fetchEstimate,
  emptyEstimate,
} from "../../../../../redux/actions/servicerequest";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import convertNumberToWords from "../../../../../utils/numberToWordsConverter";
import { isMobile } from "react-device-detect";
import DeleteIcon from "@material-ui/icons/Delete";
const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: 20,
    marginLeft: 10,
  },
  table: {
    marginRight: 10,
  },
  cardActions: {
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  cell: {
    width: theme.spacing(18),
    margin: theme.spacing(1),
  },

  addbtn: {
    border: "none",
    fontWeight: "bold",
    "&:hover": {
      color: "Green",
    },
  },
  removebtn: {
    border: "none",
    fontWeight: "bold",
    "&:hover": {
      color: "Red",
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const EstimateDetails = (props) => {
  const classes = useStyles();

  const serviceRequest = props.serviceRequest;

  const [minCharges, setMinCharges] = useState(0);

  const [isMobileView, setIsMobileView] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const handleResize = () => {
    if (window.innerWidth < fullScreen) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  });

  const [partsTotal, setPartsTotal] = useState();
  const [serviceTotal, setServiceTotal] = useState(
    serviceRequest.serviceRequest
      ? serviceRequest.serviceRequest.productDetails.minimumCharges
      : ""
  );
  const [otherTotal, setOtherTotal] = useState();
  const [finalTotalWithTax, setFinalTotalWithTax] = useState();

  //Estimate State

  const [sendEstimateButtonText] = useState("UPDATE ESTIMATE");

  // Parts Charges
  const [inputPartFields, setInputPartFields] = useState([
    {
      NewPartSrNo: "",
      OldPartSrNo: "",
      HsnNumber: "",
      Description: "",
      Quantity: "",
      Tax: "",
      Charges: "",
      Total: "",
    },
  ]);

  const handleAddFields = () => {
    setInputPartFields([
      ...inputPartFields,
      {
        NewPartSrNo: "",
        OldPartSrNo: "",
        HsnNumber: "",
        Description: "",
        Quantity: "",
        Tax: "",
        Charges: "",
        Total: "",
      },
    ]);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputPartFields];
    values.splice(index, 1);
    setInputPartFields(values);
  };

  const handleChangeInput = (index, e) => {
    const values = [...inputPartFields];
    values[index][e.target.name] = e.target.value;
    setInputPartFields(values);
  };
  console.log(props.serviceRequest);
  // Service Charges
  const [inputServiceFields, setInputServiceFields] = useState([
    {
      Description: "service charge/visit charge/diagnosis charge",
      HsnNumber: "",
      Quantity: "1",
      Tax: "18",
      Charges: "",
      Total: "",
    },
  ]);

  const handleAddServiceFields = () => {
    setInputServiceFields([
      ...inputServiceFields,
      {
        Description: "",
        HsnNumber: "",
        Quantity: "",
        Tax: "",
        Charges: "",
        Total: "",
      },
    ]);
  };

  const handleRemoveServiceFields = (index) => {
    const Servicevalues = [...inputServiceFields];
    Servicevalues.splice(index, 1);
    setInputServiceFields(Servicevalues);
  };

  const handleChangeServiceInput = (index, e) => {
    const values = [...inputServiceFields];
    values[index][e.target.name] = e.target.value;
    setInputServiceFields(values);
  };
  // useEffect(() => {
  //   const handleChangeServiceInput = (index, e) => {
  //     const values = [...inputServiceFields];
  //     values[index][e.target.name] = e.target.value;
  //     setInputServiceFields(values);
  //   };
  // }, [serviceRequest.serviceRequest]);

  const handleChangeOtherInput = (index, e) => {
    const values = [...inputOtherFields];
    values[index][e.target.name] = e.target.value;
    setInputOtherFields(values);
  };

  // Other Charges
  const [inputOtherFields, setInputOtherFields] = useState([
    {
      Description: "",
      HsnNumber: "",
      Quantity: "",
      Tax: "",
      Charges: "",
      Total: "",
    },
  ]);

  const handleAddOtherFields = () => {
    setInputOtherFields([
      ...inputOtherFields,
      {
        Description: "",
        HsnNumber: "",
        Quantity: "",
        Tax: "",
        Charges: "",
        Total: "",
      },
    ]);
  };

  const handleRemoveOtherFields = (index) => {
    const Othervalues = [...inputOtherFields];
    Othervalues.splice(index, 1);
    setInputOtherFields(Othervalues);
  };

  console.log(inputPartFields);
  console.log(serviceTotal);
  console.log(inputServiceFields);

  // Estimate Specific Row Total

  useEffect(() => {
    inputPartFields.forEach((inputField) => {
      let quantity = _.isEmpty(inputField.Quantity)
        ? 0
        : parseInt(inputField.Quantity);
      let tax = _.isEmpty(inputField.Tax) ? 0 : parseInt(inputField.Tax);
      let charges = _.isEmpty(inputField.Charges)
        ? 0
        : parseInt(inputField.Charges);
      let totalWithoutTax = charges * quantity;
      let totalWithTax = totalWithoutTax + (totalWithoutTax * tax) / 100;
      inputField.Total = _.isNaN(totalWithTax) ? 0 : totalWithTax;
    });

    let partsChargesTotal = inputPartFields.reduce(function (tot, arr) {
      return tot + arr.Total;
    }, 0);

    setPartsTotal(partsChargesTotal);
    inputServiceFields.forEach((inputServiceField) => {
      console.log("chrg " + inputServiceField.Charges);

      let quantity = _.isEmpty(inputServiceField.Quantity)
        ? 0
        : parseInt(inputServiceField.Quantity);
      let tax = _.isEmpty(inputServiceField.Tax)
        ? 0
        : parseInt(inputServiceField.Tax);
      let charges = _.isNaN(inputServiceField.Charges)
        ? 0
        : parseInt(inputServiceField.Charges);
      let totalWithoutTax = charges * quantity;
      let totalWithTax = totalWithoutTax + (totalWithoutTax * tax) / 100;
      inputServiceField.Total = _.isNaN(totalWithTax) ? 0 : totalWithTax;
    });

    let serviceChargesTotal = inputServiceFields.reduce(function (tot, arr) {
      return tot + arr.Total;
    }, 0);
    console.log(serviceChargesTotal);
    setServiceTotal(serviceChargesTotal);

    inputOtherFields.forEach((inputOtherField) => {
      let quantity = _.isEmpty(inputOtherField.Quantity)
        ? 0
        : parseInt(inputOtherField.Quantity);
      let tax = _.isEmpty(inputOtherField.Tax)
        ? 0
        : parseInt(inputOtherField.Tax);
      let charges = _.isEmpty(inputOtherField.Charges)
        ? 0
        : parseInt(inputOtherField.Charges);
      let totalWithoutTax = charges * quantity;
      let totalWithTax = totalWithoutTax + (totalWithoutTax * tax) / 100;
      inputOtherField.Total = _.isNaN(totalWithTax) ? 0 : totalWithTax;
    });

    let otherChargesTotal = inputOtherFields.reduce(function (tot, arr) {
      return tot + arr.Total;
    }, 0);

    setOtherTotal(otherChargesTotal);

    // Estimate Total
    _.isNaN(partsTotal) && _.isNaN(serviceTotal) && _.isNaN(otherTotal)
      ? setFinalTotalWithTax(parseInt(0))
      : _.isNaN(partsTotal) && _.isNaN(serviceTotal)
      ? setFinalTotalWithTax(otherTotal)
      : _.isNaN(partsTotal) && _.isNaN(otherTotal)
      ? setFinalTotalWithTax(serviceTotal)
      : _.isNaN(otherTotal) && _.isNaN(serviceTotal)
      ? setFinalTotalWithTax(partsTotal)
      : _.isNaN(partsTotal)
      ? setFinalTotalWithTax(serviceTotal + otherTotal)
      : _.isNaN(serviceTotal)
      ? setFinalTotalWithTax(partsTotal + otherTotal)
      : _.isNaN(otherTotal)
      ? setFinalTotalWithTax(partsTotal + serviceTotal)
      : setFinalTotalWithTax(partsTotal + serviceTotal + otherTotal);
  }, [
    inputOtherFields,
    inputServiceFields,
    inputPartFields,
    partsTotal,
    serviceTotal,
    otherTotal,
    minCharges,
  ]);

  const [addTandCDisabled, setAddTandCDisabled] = useState(false);
  const [addTandCBtnDisabled, setAddTandCBtnDisabled] = useState(false);
  const [TandC, setTandC] = useState("");
  const handleTermsAndConditionsChange = (e) => {
    setTandC(e.target.value);
  };
  const [TermsAndConditions, setTermsAndConditions] = useState([]);
  const btnUp = () => {
    setTermsAndConditions([
      ...TermsAndConditions,
      { TermsAndCondition: TandC },
    ]);
    setTandC("");
  };
  const handleBack = () => {
    window.history.back();
  };

  const [estimate, setEstimate] = useState({});

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("");

  const handleClickOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(serviceRequest.docId);
    console.log(serviceRequest.uid);
    const result = await postEstimate(
      {
        TermsAndConditions: TermsAndConditions,
        PartsCharges: inputPartFields,
        PartsTotalCharges: partsTotal,
        ServiceCharges: inputServiceFields,
        ServiceTotalCharges: serviceTotal,
        OtherCharges: inputOtherFields,
        OtherTotalCharges: otherTotal,
        FinalTotalWithTax: finalTotalWithTax,
        MinCharges: minCharges,
      },
      serviceRequest.serviceRequestStatus,
      serviceRequest.uid,
      serviceRequest.docId
    );
    if (result.status === "success") {
      setAddTandCDisabled(true);
      setSnackBarMsg("Estimate sent Successfully");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
    } else {
      setSnackBarMsg("Unable to send Estimate");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  };

  useEffect(() => {
    function fetchEstimateDetails() {
      /**READ ESTIMATE FROM SERVICE REQUEST*/
      if (serviceRequest && serviceRequest.estimate) {
        /**SET ESTIMATE DATA*/
        setEstimate(serviceRequest.estimate);
        setInputPartFields(serviceRequest.estimate.PartsCharges);
        setInputServiceFields(serviceRequest.estimate.ServiceCharges);
        setInputOtherFields(serviceRequest.estimate.OtherCharges);
      } else if (
        serviceRequest &&
        serviceRequest.estimate &&
        serviceRequest.estimate.TermsAndConditions
      ) {
        setTermsAndConditions(serviceRequest.estimate.TermsAndConditions);
      }

      if (serviceRequest && serviceRequest.serviceRequest) {
        const fields = [...inputServiceFields];
        fields[0].Charges =
          serviceRequest.serviceRequest.productDetails.minimumCharges;
        setInputServiceFields(fields);
      }
    }
    _.isEmpty(TandC)
      ? setAddTandCBtnDisabled(true)
      : setAddTandCBtnDisabled(false);
    fetchEstimateDetails();
  }, [TandC, serviceRequest]);

  useEffect(() => {
    store.dispatch(
      fetchEstimate({
        TermsAndConditions: TermsAndConditions,
        PartsCharges: inputPartFields,
        PartsTotalCharges: partsTotal,
        ServiceCharges: inputServiceFields,
        ServiceTotalCharges: serviceTotal,
        OtherCharges: inputOtherFields,
        OtherTotalCharges: otherTotal,
        FinalTotalWithTax: finalTotalWithTax,
        MinCharges: minCharges,
      })
    );
    return function cleanup() {
      store.dispatch(emptyEstimate());
    };
  }, [
    TermsAndConditions,
    estimate,
    finalTotalWithTax,
    inputOtherFields,
    inputPartFields,
    inputServiceFields,
    minCharges,
    otherTotal,
    partsTotal,
    serviceTotal,
  ]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  return (
    <>
      {isMobile || isMobileView ? (
        <div maxWidth={"xs"}>
          <form className={classes.root}>
            <DialogContent>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
                style={{ border: "2.0px solid black" }}
              >
                {" "}
                <TableBody>
                  {/* Parts Charges */}

                  <TableRow style={{ border: "1px solid black" }}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.cell}
                      style={{
                        border: "1px solid black",
                        width: "10%",
                        fontSize: "120%",
                      }}
                    >
                      Parts Charges
                    </TableCell>
                  </TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ border: "2px solid black" }}
                    className={classes.cell}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      endIcon={<AddIcon />}
                      onClick={() => handleAddFields()}
                      size="small"
                    >
                      Add Part
                    </Button>
                    {inputPartFields.map((inputField, index) => (
                      <div>
                        <TableRow>
                          <TextField
                            name="Description"
                            label="Description"
                            variant="outlined"
                            size="small"
                            value={inputField.Description}
                            onChange={(e) => handleChangeInput(index, e)}
                            style={{ margin: "3% 0" }}
                            fullWidth
                          />
                          <TextField
                            name="OldPartSrNo"
                            label="Old Part Serial Number"
                            variant="outlined"
                            size="small"
                            value={inputField.OldPartSrNo}
                            onChange={(e) => handleChangeInput(index, e)}
                            style={{
                              margin: "3% 0",
                            }}
                            fullWidth
                          />

                          <TextField
                            name="NewPartSrNo"
                            label="New Part Serial Number"
                            variant="outlined"
                            size="small"
                            value={inputField.NewPartSrNo}
                            onChange={(e) => handleChangeInput(index, e)}
                            style={{ margin: "3% 0" }}
                            fullWidth
                          />
                          <TextField
                            name="HsnNumber"
                            label="HSN Number"
                            variant="outlined"
                            size="small"
                            value={inputField.HsnNumber}
                            onChange={(e) => handleChangeInput(index, e)}
                            style={{ margin: "3% 0" }}
                            fullWidth
                          />
                        </TableRow>

                        <TableRow>
                          <TextField
                            fullWidth
                            name="Quantity"
                            label="Quantity"
                            variant="outlined"
                            size="small"
                            value={inputField.Quantity}
                            onChange={(e) => handleChangeInput(index, e)}
                            style={{ margin: "3% 0" }}
                          />
                        </TableRow>
                        <TableRow>
                          <TextField
                            fullWidth
                            name="Charges"
                            label="Charges"
                            variant="outlined"
                            size="small"
                            value={inputField.Charges}
                            onChange={(e) => handleChangeInput(index, e)}
                            style={{ margin: "3% 0" }}
                          />
                        </TableRow>
                        <TableRow>
                          <TextField
                            fullWidth
                            name="Tax"
                            variant="outlined"
                            label="Tax"
                            size="small"
                            value={inputField.Tax}
                            onChange={(e) => handleChangeInput(index, e)}
                            style={{ margin: "3% 0" }}
                          />
                        </TableRow>

                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          onClick={() => handleRemoveFields(index)}
                          startIcon={<DeleteIcon />}
                          size="small"
                        >
                          Delete Part
                        </Button>
                      </div>
                    ))}{" "}
                  </TableCell>

                  {/* Service Charges */}
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.cell}
                      style={{
                        border: "1px solid black",
                        fontSize: "120%",
                      }}
                    >
                      Service Charges
                    </TableCell>
                  </TableRow>

                  <TableCell
                    style={{ border: "1px solid black" }}
                    component="th"
                    scope="row"
                    className={classes.cell}
                  >
                    {inputServiceFields.map((inputServiceField, index) => (
                      <div>
                        <TableRow>
                          <TextField
                            name="Description"
                            label="Description"
                            variant="outlined"
                            size="small"
                            value={inputServiceField.Description}
                            onChange={(e) => handleChangeServiceInput(index, e)}
                            style={{
                              margin: "3% 0",
                            }}
                            fullWidth
                          />
                          <TextField
                            name="HsnNumber"
                            label="HSN Number"
                            variant="outlined"
                            size="small"
                            value={inputServiceField.HsnNumber || ""}
                            onChange={(e) => handleChangeServiceInput(index, e)}
                            style={{
                              margin: "3% 0",
                            }}
                            fullWidth
                          />
                        </TableRow>

                        <TableRow>
                          <TextField
                            fullWidth
                            name="Quantity"
                            label="Quantity"
                            variant="outlined"
                            size="small"
                            value={inputServiceField.Quantity}
                            onChange={(e) => handleChangeServiceInput(index, e)}
                            style={{ margin: "3% 0" }}
                          />
                        </TableRow>
                        <TableRow>
                          <TextField
                            fullWidth
                            name="Charges"
                            label="Charges"
                            variant="outlined"
                            size="small"
                            value={inputServiceField.Charges}
                            onChange={(e) => handleChangeServiceInput(index, e)}
                            style={{ margin: "3% 0" }}
                          />
                        </TableRow>
                        <TableRow>
                          <TextField
                            fullWidth
                            name="Tax"
                            label="Tax"
                            variant="outlined"
                            size="small"
                            value={inputServiceField.Tax}
                            onChange={(e) => handleChangeServiceInput(index, e)}
                            style={{ margin: "3% 0" }}
                          />
                        </TableRow>
                        <Divider />
                      </div>
                    ))}
                  </TableCell>
                  {/* Other Charges */}
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        border: "1px solid black",
                        width: "10%",
                        fontSize: "120%",
                      }}
                      className={classes.cell}
                    >
                      Other Charges
                    </TableCell>
                  </TableRow>

                  <TableCell
                    style={{ border: "1px solid black" }}
                    component="th"
                    scope="row"
                    className={classes.cell}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      endIcon={<AddIcon />}
                      onClick={() => handleAddOtherFields()}
                      size="small"
                    >
                      Add Other Field
                    </Button>
                    {inputOtherFields.map((inputOtherField, index) => (
                      <div>
                        <TableRow>
                          <TextField
                            name="Description"
                            label="Description"
                            variant="outlined"
                            size="small"
                            value={inputOtherField.Description || ""}
                            onChange={(e) => handleChangeOtherInput(index, e)}
                            style={{
                              margin: "3% 0",
                            }}
                            fullWidth
                          />
                          <TextField
                            name="HsnNumber"
                            label="HSN Number"
                            variant="outlined"
                            size="small"
                            value={inputOtherField.HsnNumber || ""}
                            onChange={(e) => handleChangeOtherInput(index, e)}
                            style={{
                              margin: "3% 0",
                            }}
                            fullWidth
                          />
                        </TableRow>

                        <TableRow>
                          <TextField
                            fullWidth
                            name="Quantity"
                            label="Quantity"
                            variant="outlined"
                            size="small"
                            value={inputOtherField.Quantity}
                            onChange={(e) => handleChangeOtherInput(index, e)}
                            style={{ margin: "3% 0" }}
                          />
                        </TableRow>
                        <TableRow>
                          <TextField
                            fullWidth
                            name="Charges"
                            label="Charges"
                            variant="outlined"
                            size="small"
                            value={inputOtherField.Charges}
                            onChange={(e) => handleChangeOtherInput(index, e)}
                            style={{ margin: "3% 0" }}
                          />
                        </TableRow>
                        <TableRow>
                          <TextField
                            fullWidth
                            name="Tax"
                            label="Tax"
                            variant="outlined"
                            size="small"
                            value={inputOtherField.Tax}
                            onChange={(e) => handleChangeOtherInput(index, e)}
                            style={{ margin: "3% 0" }}
                          />
                        </TableRow>

                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          onClick={() => handleRemoveOtherFields(index)}
                          startIcon={<DeleteIcon />}
                          size="small"
                        >
                          Delete Other Field
                        </Button>
                        <Divider />
                      </div>
                    ))}
                  </TableCell>

                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        fontSize: "120%",
                        borderRight: "1px solid black",
                      }}
                    >
                      Total in words
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        fontSize: "120%",
                      }}
                    >
                      {_.isUndefined(finalTotalWithTax)
                        ? ""
                        : convertNumberToWords(finalTotalWithTax) +
                          " " +
                          "Rupees"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold", fontSize: "120%" }}>
                      Total
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold", fontSize: "120%" }}>
                      ₹ {finalTotalWithTax}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <p>Terms and Conditions</p>
              <FormHelperText>
                Terms and conditions are non editable and non deleteable once
                estimate is send
              </FormHelperText>
              {_.isUndefined(TermsAndConditions)
                ? ""
                : TermsAndConditions.map((TermAndCondition, index) => (
                    <p>• {TermAndCondition.TermsAndCondition}</p>
                  ))}

              <TextField
                style={{ textAlign: "left", marginTop: "2%" }}
                label="Terms and Conditions"
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                value={TandC}
                onChange={handleTermsAndConditionsChange}
                disabled={addTandCDisabled}
                InputProps={{
                  endAdornment: (
                    <Fab
                      color="primary"
                      aria-label="add"
                      size="small"
                      onMouseUp={btnUp}
                      disabled={addTandCBtnDisabled}
                    >
                      <AddIcon />
                    </Fab>
                  ),
                }}
              />
            </DialogContent>
          </form>
          <DialogActions
            id={isMobileView ? "actions" : "Actions"}
            style={{
              display: "flex",
              justifyContent: isMobileView ? "space-between" : "flex-end",
              flex: "wrap",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{
                display: isMobileView ? "block" : "none",
                margin: "2%",
                justifyContent: "flex-start",
              }}
              onClick={handleBack}
              className={isMobileView ? "btn" : "Btn"}
            >
              Back
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{
                margin: "2%",
                float: "right",
                width: isMobileView ? 170 : 150,
              }}
              onClick={handleSubmit}
              className={isMobileView ? "btn1" : "Btn"}
              disabled={
                serviceRequest.serviceRequestStatus === "Pending" ? true : false
              }
            >
              {sendEstimateButtonText}
            </Button>
          </DialogActions>
          <Snackbar
            open={openSnackBar}
            autoHideDuration={3000}
            onClose={handleCloseSnackBar}
          >
            <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
              {snackBarMsg}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <div>
          <form className={classes.root}>
            <DialogContent>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
                style={{ border: "2.0px solid black" }}
              >
                {" "}
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ border: "1px solid black", minWidth: "7%" }}
                    >
                      Item
                    </TableCell>
                    <TableCell style={{ border: "1px solid black" }}>
                      Description
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid black", width: "9%" }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      style={{ border: "1px solid black", width: "11%" }}
                    >
                      Charges (₹)
                    </TableCell>
                    <TableCell
                      style={{
                        border: "1px solid black",
                        width: isMobileView ? "15%" : "10%",
                      }}
                    >
                      Tax (%)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Parts Charges */}

                  <TableRow style={{ border: "1px solid black" }}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.cell}
                      style={{
                        border: "1px solid black",
                        width: "10%",
                        fontSize: "120%",
                      }}
                    >
                      Parts Charges
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      style={{ border: "1px solid black" }}
                      className={classes.cell}
                    >
                      <IconButton
                        onClick={() => handleAddFields()}
                        className={classes.addbtn}
                      >
                        <AddIcon />
                      </IconButton>
                      {inputPartFields.map((inputField, index) => (
                        <div>
                          <TextField
                            name="Description"
                            label="Description"
                            variant="outlined"
                            size="small"
                            value={inputField.Description}
                            onChange={(e) => handleChangeInput(index, e)}
                            style={{
                              width: isMobileView ? 310 : "92%",
                            }}
                          />

                          <IconButton
                            onClick={() => handleRemoveFields(index)}
                            className={classes.removebtn}
                          >
                            <RemoveIcon />
                          </IconButton>

                          <TableRow>
                            <TextField
                              name="OldPartSrNo"
                              label="Old Part Serial Number"
                              variant="outlined"
                              size="small"
                              value={inputField.OldPartSrNo}
                              onChange={(e) => handleChangeInput(index, e)}
                              style={{
                                width: isMobileView ? 310 : "31%",
                                margin: "3% 0",
                                marginRight: "2%",
                              }}
                            />

                            <TextField
                              name="NewPartSrNo"
                              label="New Part Serial Number"
                              variant="outlined"
                              size="small"
                              value={inputField.NewPartSrNo}
                              onChange={(e) => handleChangeInput(index, e)}
                              style={{
                                width: isMobileView ? 310 : "31%",
                                margin: "3% 0",
                                marginRight: "2%",
                              }}
                            />
                            <TextField
                              name="HsnNumber"
                              label="HSN Number"
                              variant="outlined"
                              size="small"
                              value={inputField.HsnNumber}
                              onChange={(e) => handleChangeInput(index, e)}
                              style={{
                                width: isMobileView ? 310 : "31%",
                                margin: "3% 0",
                              }}
                            />
                          </TableRow>
                        </div>
                      ))}{" "}
                    </TableCell>

                    <TableCell style={{ border: "1px solid black" }}>
                      {inputPartFields.map((inputField, index) => (
                        <TextField
                          fullWidth
                          name="Quantity"
                          variant="outlined"
                          size="small"
                          value={inputField.Quantity}
                          onChange={(e) => handleChangeInput(index, e)}
                          style={{ margin: isMobileView ? "150% 0" : "63% 0" }}
                        />
                      ))}
                    </TableCell>
                    <TableCell style={{ border: "1px solid black" }}>
                      {inputPartFields.map((inputField, index) => (
                        <TextField
                          fullWidth
                          name="Charges"
                          variant="outlined"
                          size="small"
                          value={inputField.Charges}
                          onChange={(e) => handleChangeInput(index, e)}
                          style={{ margin: isMobileView ? "150% 0" : "48% 0" }}
                        />
                      ))}
                    </TableCell>
                    <TableCell
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      {inputPartFields.map((inputField, index) => (
                        <TextField
                          fullWidth
                          name="Tax"
                          variant="outlined"
                          size="small"
                          value={inputField.Tax}
                          onChange={(e) => handleChangeInput(index, e)}
                          style={{
                            margin: isMobileView ? "135% 0" : "55% 0",
                          }}
                        />
                      ))}
                    </TableCell>
                  </TableRow>
                  {/* Service Charges */}
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.cell}
                      style={{
                        border: "1px solid black",
                        width: "10%",
                        fontSize: "120%",
                      }}
                    >
                      Service Charges
                    </TableCell>

                    <TableCell
                      style={{ border: "1px solid black" }}
                      component="th"
                      scope="row"
                      className={classes.cell}
                    >
                      {inputServiceFields.map((inputChargesField, index) => (
                        <div>
                          <TextField
                            name="Description"
                            label="Description"
                            variant="outlined"
                            size="small"
                            value={inputChargesField.Description}
                            onChange={(e) => handleChangeServiceInput(index, e)}
                            style={{
                              width: isMobileView ? 310 : "60%",
                              margin: "3% 0",
                              marginRight: "2%",
                            }}
                          />
                          <TextField
                            name="HsnNumber"
                            label="HSN Number"
                            variant="outlined"
                            size="small"
                            value={inputChargesField.HsnNumber || ""}
                            onChange={(e) => handleChangeServiceInput(index, e)}
                            style={{
                              width: isMobileView ? 310 : "30%",
                              margin: "3% 0",
                            }}
                          />

                          <Divider />
                        </div>
                      ))}
                    </TableCell>

                    <TableCell style={{ border: "1px solid black" }}>
                      {inputServiceFields.map((inputServiceField, index) => (
                        <TextField
                          fullWidth
                          name="Quantity"
                          variant="outlined"
                          size="small"
                          value={inputServiceField.Quantity}
                          onChange={(e) => handleChangeServiceInput(index, e)}
                          style={{ margin: isMobileView ? "100% 0" : "32% 0" }}
                        />
                      ))}
                    </TableCell>
                    <TableCell style={{ border: "1px solid black" }}>
                      {inputServiceFields.map((inputServiceField, index) => (
                        <TextField
                          fullWidth
                          name="Charges"
                          variant="outlined"
                          size="small"
                          value={inputServiceField.Charges}
                          onChange={(e) => handleChangeServiceInput(index, e)}
                          style={{ margin: isMobileView ? "100% 0" : "24% 0" }}
                        />
                      ))}
                    </TableCell>
                    <TableCell style={{ border: "1px solid black" }}>
                      {inputServiceFields.map((inputServiceField, index) => (
                        <TextField
                          fullWidth
                          name="Tax"
                          variant="outlined"
                          size="small"
                          value={inputServiceField.Tax}
                          onChange={(e) => handleChangeServiceInput(index, e)}
                          style={{ margin: isMobileView ? "90% 0" : "28% 0" }}
                        />
                      ))}
                    </TableCell>
                  </TableRow>
                  {/* Other Charges */}
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        border: "1px solid black",
                        width: "10%",
                        fontSize: "120%",
                      }}
                      className={classes.cell}
                    >
                      Other Charges
                    </TableCell>

                    <TableCell
                      style={{ border: "1px solid black" }}
                      component="th"
                      scope="row"
                      className={classes.cell}
                    >
                      <IconButton
                        onClick={() => handleAddOtherFields()}
                        className={classes.addbtn}
                      >
                        <AddIcon />
                      </IconButton>
                      {inputOtherFields.map((inputOtherField, index) => (
                        <div>
                          <TextField
                            name="Description"
                            label="Description"
                            variant="outlined"
                            size="small"
                            value={inputOtherField.Description || ""}
                            onChange={(e) => handleChangeOtherInput(index, e)}
                            style={{
                              width: isMobileView ? 310 : "60%",
                              margin: "3% 0",
                              marginRight: "2%",
                            }}
                          />
                          <TextField
                            name="HsnNumber"
                            label="HSN Number"
                            variant="outlined"
                            size="small"
                            value={inputOtherField.HsnNumber || ""}
                            onChange={(e) => handleChangeOtherInput(index, e)}
                            style={{
                              width: isMobileView ? 310 : "30%",
                              margin: "3% 0",
                            }}
                          />

                          <IconButton
                            onClick={() => handleRemoveOtherFields(index)}
                            style={{ margin: "2% 0" }}
                            className={classes.removebtn}
                          >
                            <RemoveIcon />
                          </IconButton>

                          <Divider />
                        </div>
                      ))}
                    </TableCell>

                    <TableCell style={{ border: "1px solid black" }}>
                      {inputOtherFields.map((inputOtherField, index) => (
                        <TextField
                          fullWidth
                          name="Quantity"
                          variant="outlined"
                          size="small"
                          value={inputOtherField.Quantity}
                          onChange={(e) => handleChangeOtherInput(index, e)}
                          style={{ margin: isMobileView ? "100% 0" : "32% 0" }}
                        />
                      ))}
                    </TableCell>
                    <TableCell style={{ border: "1px solid black" }}>
                      {inputOtherFields.map((inputOtherField, index) => (
                        <TextField
                          fullWidth
                          name="Charges"
                          variant="outlined"
                          size="small"
                          value={inputOtherField.Charges}
                          onChange={(e) => handleChangeOtherInput(index, e)}
                          style={{ margin: isMobileView ? "100% 0" : "24% 0" }}
                        />
                      ))}
                    </TableCell>
                    <TableCell style={{ border: "1px solid black" }}>
                      {inputOtherFields.map((inputOtherField, index) => (
                        <TextField
                          fullWidth
                          name="Tax"
                          variant="outlined"
                          size="small"
                          value={inputOtherField.Tax}
                          onChange={(e) => handleChangeOtherInput(index, e)}
                          style={{ margin: isMobileView ? "90% 0" : "28% 0" }}
                        />
                      ))}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: "120%",
                        borderRight: "1px solid black",
                      }}
                    >
                      Amount in words
                    </TableCell>

                    <TableCell
                      style={{
                        fontSize: "120%",
                        textTransform: "capitalize",
                      }}
                    >
                      {_.isUndefined(finalTotalWithTax)
                        ? ""
                        : convertNumberToWords(finalTotalWithTax) +
                          " " +
                          "Rupees"}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold", fontSize: "120%" }}>
                      Total
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell style={{ fontWeight: "bold", fontSize: "120%" }}>
                      ₹ {finalTotalWithTax}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <p>Terms and Conditions</p>
              <FormHelperText>
                Terms and conditions are non editable and non deleteable once
                estimate is send
              </FormHelperText>
              {_.isUndefined(TermsAndConditions)
                ? ""
                : TermsAndConditions.map((TermAndCondition, index) => (
                    <p>• {TermAndCondition.TermsAndCondition}</p>
                  ))}

              <TextField
                style={{ textAlign: "left", marginTop: "2%" }}
                label="Terms and Conditions"
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                value={TandC}
                onChange={handleTermsAndConditionsChange}
                disabled={addTandCDisabled}
                InputProps={{
                  endAdornment: (
                    <Fab
                      color="primary"
                      aria-label="add"
                      size="small"
                      onMouseUp={btnUp}
                      disabled={addTandCBtnDisabled}
                    >
                      <AddIcon />
                    </Fab>
                  ),
                }}
              />
            </DialogContent>
          </form>
          <DialogActions
            id={isMobileView ? "actions" : "Actions"}
            style={{
              display: "flex",
              justifyContent: isMobileView ? "space-between" : "flex-end",
              flex: "wrap",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{
                display: isMobileView ? "block" : "none",
                margin: "2%",
                justifyContent: "flex-start",
              }}
              onClick={handleBack}
              className={isMobileView ? "btn" : "Btn"}
            >
              Back
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{
                margin: "2%",
                float: "right",
                width: isMobileView ? 170 : 150,
              }}
              onClick={handleSubmit}
              className={isMobileView ? "btn1" : "Btn"}
              disabled={
                serviceRequest.serviceRequestStatus === "Pending" ? true : false
              }
            >
              {sendEstimateButtonText}
            </Button>
          </DialogActions>
          <Snackbar
            open={openSnackBar}
            autoHideDuration={3000}
            onClose={handleCloseSnackBar}
          >
            <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
              {snackBarMsg}
            </Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(EstimateDetails);
