import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Chip,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  Paper,
  Grid,
  Typography,
  Backdrop,
  Button,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { postEnquiryResponse } from "../../../../services/enquiry/index";
import _ from "lodash";
import { fetchEnquiries } from "../../../../redux/actions/enquiry";
import store from "../../../../redux/store/store";
import { connect } from "react-redux";
import AsynchronousSearch from "../../search/AsyncSearch.jsx";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgressBar from "../../../support/progress/CircularProgressBar";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  toolbar: {
    marginLeft: theme.spacing(-2),
  },
  header: {
    margin: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Results = ({ className, ...rest }) => {
  const classes = useStyles();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [isMobileView, setIsMobileView] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 767) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  });

  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [response, setResponse] = useState("");
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const props = { ...rest };
    setCustomers(props.newEnquiriesList);
  }, [rest]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [open, setOpen] = React.useState(false);

  function handleResponse(e) {
    e.preventDefault();
    console.log("cell on change called");
    setResponse(e.currentTarget.textContent);
  }

  function handleResponse1(e) {
    e.preventDefault();
    setResponse(e.target.value);
  }

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("");

  const handleClickOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleSubmit = async (e, uid, docId) => {
    e.preventDefault();
    const result = await postEnquiryResponse(response, uid, docId);
    if (result.status === "success") {
      console.log(result);
      setOpen(false);
      setSnackBarMsg("Response sent Successfully");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
    } else {
      setOpen(false);
      setSnackBarMsg("Unable to send Response");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  };

  const [sortValue, setSortValue] = useState("Latest");
  const handleChange = (event) => {
    handleBackDropOpen();
    setSortValue(event.target.value);
    if (event.target.value === "Oldest") {
      console.log([...customers]);
      const sortedSrList = _.sortBy(
        [...customers],
        [
          function (o) {
            return o.docData.referenceId;
          },
        ]
      );
      store.dispatch(fetchEnquiries([...sortedSrList]));
      handleBackDropClose();
    } else {
      console.log([...customers]);
      const sortedSrList = _.sortBy(
        [...customers],
        [
          function (o) {
            return o.docData.referenceId;
          },
        ]
      ).reverse();
      store.dispatch(fetchEnquiries([...sortedSrList]));
      handleBackDropClose();
    }
  };

  const [searchByValue, setSearchByValue] = useState("RequestId");

  const searchByValueChange = (event) => {
    setSearchByValue(event.target.value);
  };

  const [openBackdrop, setOpenBackdrop] = useState(false);

  function handleBackDropClose() {
    setOpenBackdrop(false);
  }

  function handleBackDropOpen() {
    setOpenBackdrop(true);
  }
  return (
    <>
      <Paper className={classes.paper}>
       
        <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
        <Typography variant="h4" gutterBottom className={classes.header}>
          Enquiries
        </Typography>
        </Grid>
          <Grid item xs={12} sm={4}>
            <Toolbar className={classes.toolbar}>
              <AsynchronousSearch
                searchType="Enquiry"
                searchByValue={searchByValue}
              />
            </Toolbar>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Toolbar className={classes.toolbar}>
              <Box display="flex" flexGrow={1}>
                {/* whatever is on the left side */}
              </Box>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                size="small"
              >
                <InputLabel htmlFor="age-native-simple">Sort</InputLabel>
                <Select
                  label="Sort"
                  native
                  value={sortValue}
                  onChange={handleChange}
                >
                  <option value={"Latest"}>Latest First</option>
                  <option value={"Oldest"}>Oldest First</option>
                </Select>
              </FormControl>
            </Toolbar>
          </Grid>
        </Grid>
        <TablePagination
          component="div"
          count={customers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 200]}
          nextIconButtonText={"next page"}
        />
        <div
          style={{
            overflowX: "auto",
            display: isMobileView === true ? "block" : "none",
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Enquiries</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(limit > 0
                ? customers.slice(page * limit, page * limit + limit)
                : customers
              ).map((customer) => (
                <TableRow hover key={customer.docId}>
                  <TableCell>
                    <div className={classes.root}>
                      <Paper className={classes.paper}>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{ textAlign: "left" }}
                          >
                            <b> Enq Id:</b> {`${customer.docData.referenceId}`}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{ textAlign: "left" }}
                          >
                            <b> Contact: </b>{" "}
                            {`${
                              _.isUndefined(customer.userDetails)
                                ? ""
                                : customer.userDetails.email
                            }`}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{ textAlign: "left" }}
                          >
                            <b>Enq Type:</b>{" "}
                            {`${customer.docData.enquiryDetails.type}`}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{ textAlign: "left" }}
                          >
                            <b>Enq Desc:</b>{" "}
                            {`${customer.docData.enquiryDetails.description}`}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{ textAlign: "left" }}
                          >
                            <b>Enq Date:</b>{" "}
                            {moment
                              .unix(
                                customer.docData.enquiryDetails.date.seconds
                              )
                              .format("DD/MM/YYYY HH:mm A")}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{ textAlign: "left" }}
                          >
                            <TextField
                              onChange={handleResponse1}
                              style={{ width: "100%" }}
                              value={response}
                            >
                              {_.isUndefined(customer.responseDetails)
                                ? ""
                                : customer.responseDetails.response}
                            </TextField>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{ textAlign: "center" }}
                          >
                             <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              style={{
                                width: "100%",
                              }}
                              onClick={(e) =>
                                handleSubmit(e, customer.docData.uid, customer.docId)
                              }
                            >Send</Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div
          style={{
            overflowX: "auto",
            display: isMobileView === true ? "none" : "block",
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Enquiry ID</TableCell>
                <TableCell>User Email</TableCell>
                <TableCell>Enquiry Type</TableCell>
                <TableCell>Enquiry Description</TableCell>
                <TableCell>Date & Time</TableCell>
                <TableCell> Enquiry Response</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(limit > 0
                ? customers.slice(page * limit, page * limit + limit)
                : customers
              ).map((customer) => (
                <TableRow hover key={customer.docId}>
                  <TableCell>{`${customer.docData.referenceId}`}</TableCell>
                  <TableCell>{`${
                    _.isUndefined(customer.userDetails)
                      ? ""
                      : customer.userDetails.email
                  }`}</TableCell>
                  <TableCell>{`${customer.docData.enquiryDetails.type}`}</TableCell>
                  <TableCell>
                    {`${customer.docData.enquiryDetails.description}`}
                  </TableCell>
                  <TableCell>
                    {moment
                      .unix(customer.docData.enquiryDetails.date.seconds)
                      .format("DD/MM/YYYY HH:mm A")}
                  </TableCell>
                  <TableCell
                    contentEditable={true}
                    onInput={handleResponse}
                    suppressContentEditableWarning={true}
                  >
                    {_.isUndefined(customer.responseDetails)
                      ? ""
                      : customer.responseDetails.response}
                  </TableCell>
                  <TableCell>
                      <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      style={{
                        width: "100%",
                      }}
                      onClick={(e) =>
                        handleSubmit(e, customer.docData.uid, customer.docId)
                      }
                    >Send</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Backdrop className={classes.backdrop} open={openBackdrop}>
            <CircularProgressBar display={true} />
          </Backdrop>
        </div>
      </Paper>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => state.enquiryReducer;
export default connect(mapStateToProps)(Results);
