import app from "../firebase/FirebaseAppService";
import moment from "moment";

const db = app.firestore();
const collectionName = "ServiceRequests";
const collectionGroupName = "requestsList";

export async function postEstimate(estimate,serviceRequestStatus, uid, docId) {
    const docRef = db.collection(collectionName).doc(uid).collection(collectionGroupName).doc(docId);
    try {
        await docRef.set({
            estimate: {
                TermsAndConditions: estimate.TermsAndConditions,
                PartsCharges: estimate.PartsCharges,
                PartsTotalCharges: estimate.PartsTotalCharges,
                ServiceCharges: estimate.ServiceCharges,
                ServiceTotalCharges: estimate.ServiceTotalCharges,
                OtherCharges: estimate.OtherCharges,
                OtherTotalCharges: estimate.OtherTotalCharges,
                FinalTotalWithTax: estimate.FinalTotalWithTax,
                MinCharges: estimate.MinCharges,
                date: moment().format()
            },
            serviceRequestStatus : serviceRequestStatus.includes("Work Completed") ? serviceRequestStatus : "Estimate Updated"
        }, { merge: true });
        return {
            status: "success",
            docId: docRef.id,
            docData: estimate
        };
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            docId: docRef.id,
            docData: {

            }
        };
    }
}