import app from "../firebase/FirebaseAppService";
import _ from "lodash"
const db = app.firestore();
const collectionName = "Users";

const storage = app.storage();
const storageRef = storage.ref();

export async function getDocuments(uid) {
    try {

        let BusinessProofFileName = "BussinessProof.pdf";
        let BankStatementFileName = "BankStatementProof.pdf";
        let AddressProofFileName = "CompanyAddressProof.pdf";

        let IndIdentityProofFileName = "IndividualIdentityProof.pdf";
        let IndAddressProofFileName = "IndividualAddressProof.pdf";



        const BusinessProofRef = storageRef.child('images/' + uid + "/BusinessProof/" + BusinessProofFileName)
        const BankStatementRef = storageRef.child('images/' + uid + "/BankStatement/" + BankStatementFileName)
        const AddressProofRef = storageRef.child('images/' + uid + "/AddressProof/" + AddressProofFileName)

        var businessProofUrl;
        var bankStatementUrl;
        var addressProofUrl;
        console.log("here")

        const exists2 = await BusinessProofRef.getMetadata().then(() => true).catch(() => false);
        businessProofUrl = exists2 ? await BusinessProofRef.getDownloadURL() : ""
        console.log("there")

        const exists3 = await BankStatementRef.getMetadata().then(() => true).catch(() => false);
        bankStatementUrl = exists3 ? await BankStatementRef.getDownloadURL() : ""

        const exists4 = await AddressProofRef.getMetadata().then(() => true).catch(() => false);
        addressProofUrl = exists4 ? await AddressProofRef.getDownloadURL() : ""


        const IndIdentityProofRef = storageRef.child('images/' + uid + "/IndIdentityProof/" + IndIdentityProofFileName)
        const IndAddressProofRef = storageRef.child('images/' + uid + "/IndAddressProof/" + IndAddressProofFileName)

        var indIdentityProofUrl;
        var indAddressProofUrl;

        const exists = await IndIdentityProofRef.getMetadata().then(() => true).catch(() => false);
        indIdentityProofUrl = exists ? await IndIdentityProofRef.getDownloadURL() : ""

        const exists1 = await IndAddressProofRef.getMetadata().then(() => true).catch(() => false);
        indAddressProofUrl = exists1 ? await IndAddressProofRef.getDownloadURL() : ""

        if (!_.isEmpty(businessProofUrl) && !_.isEmpty(indIdentityProofUrl)) {
            console.log("this3")
            return {
                status: "success",
                BusinessProofUrl: businessProofUrl,
                BankStatementUrl: bankStatementUrl,
                AddressProofUrl: addressProofUrl,
                BusinessProofName: BusinessProofFileName,
                BankStatementName: BankStatementFileName,
                AddressProofName: AddressProofFileName,
                IndIdentityProof: indIdentityProofUrl,
                IndAddressProof: indAddressProofUrl,
                IndIdentityProofName: IndIdentityProofFileName,
                IndAddressProofName: IndAddressProofFileName,
            }
        }

        if (_.isEmpty(businessProofUrl) && _.isEmpty(indIdentityProofUrl)) {
            console.log("this2")
            return {
                status: "success",
            }
        }

        if (!_.isEmpty(indIdentityProofUrl)) {
            console.log("this")
            return {
                status: "success",
                IndIdentityProof: indIdentityProofUrl,
                IndAddressProof: indAddressProofUrl,
                IndIdentityProofName: IndIdentityProofFileName,
                IndAddressProofName: IndAddressProofFileName,
            }
        }
        if (!_.isEmpty(businessProofUrl)) {
            console.log("this1")
            return {
                status: "success",
                BusinessProofUrl: businessProofUrl,
                BankStatementUrl: bankStatementUrl,
                AddressProofUrl: addressProofUrl,
                BusinessProofName: BusinessProofFileName,
                BankStatementName: BankStatementFileName,
                AddressProofName: AddressProofFileName,
            }
        }
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            profile: {}
        };
    }
}

export default async function getProfile() {
    try {
        const doc = await db.collection(collectionName).doc(app.auth().currentUser.uid).get();
        const document = {
            docData: doc.data(),
            docId: doc.id
        }
        if (doc.exists) {
            console.log('there')
            return {
                status: "success",
                profile: document
            };
        }
        console.log('here')
        return {
            status: "failure",
            profile: {}
        };
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            profile: {}
        };
    }
}

export async function getUserProfile(uid) {
    try {
        const doc = await db.collection(collectionName).doc(uid).get();
        const document = {
            docData: doc.data(),
            docId: doc.id
        }
        if (doc.exists) {
            console.log('there')
            console.log(document.docData.firstName)
            return {
                status: "success",
                profile: document
            };
        }
        console.log('here')
        return {
            status: "failure",
            profile: {}
        };
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            profile: {}
        };
    }
}

export async function putProfile(addressDetails, fname, lname) {
    const docRef = db.collection(collectionName).doc(app.auth().currentUser.uid);
    const addRef = db.collection("App").doc("adminAddress");
    try {
        const batch = db.batch();
        batch.set(docRef, { addressDetails, firstName: fname, lastName: lname }, {
            merge: true
        });
        batch.set(addRef, { addressDetails }, {
            merge: true
        });
        return batch.commit().then(() => {
            return {
                status: "success",
                docId: docRef.id,
                docData: addressDetails
            };
        });
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            docId: docRef.id,
            docData: {}
        };
    }
}

export async function putUserProfile(addressDetails, uid, fname, lname, category, phone, documentsVerification) {
    const docRef = db.collection(collectionName).doc(uid);
    try {
        await docRef.set({ addressDetails, firstName: fname, lastName: lname, category: category, phoneNumber: phone, documentsVerification: documentsVerification }, {
            merge: true
        });
        return {
            status: "success",
            docId: docRef.id,
            docData: addressDetails
        };
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            docId: docRef.id,
            docData: {}
        };
    }
}