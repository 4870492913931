import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ServiceDetails from "./ServiceDetails";
import AddressDetails from "./AddressDetails";
import ProductDetails from "./ProductDetails";
import { connect } from "react-redux";
import { postServiceRequest } from "../../../../../services/service-request/index";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  main: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ["Product Details", "Service details", "Address Details"];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <ProductDetails />;
    case 1:
      return <ServiceDetails />;
    case 2:
      return <AddressDetails />;
    default:
      throw new Error("Unknown step");
  }
}

function ServiceRequestCheckout(props) {
  const classes = useStyles();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [activeStep, setActiveStep] = useState(0);
  const [sr, setSr] = useState({
    productDetails: props.productDetails,
    serviceDetails: props.serviceDetails,
    addressDetails: props.addressDetails,
  });

  useEffect(() => {
    setSr({
      productDetails: props.productDetails,
      serviceDetails: props.serviceDetails,
      addressDetails: props.addressDetails,
    });
  }, [props.productDetails, props.serviceDetails, props.addressDetails]);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("");

  const handleClickOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleNext = async (e) => {
    try {
      e.preventDefault();
      setActiveStep(activeStep + 1);
      if (activeStep === steps.length - 1) {
        const result = await postServiceRequest(sr);
        if (result.status === "success") {
          setSnackBarMsg("Service Request sent Successfully");
          setSnackBarSeverity("success");
          handleClickOpenSnackBar();
        } else {
          setSnackBarMsg("unable to send service request");
          setSnackBarSeverity("error");
          handleClickOpenSnackBar();
        }
      }
    } catch (error) {
      setSnackBarMsg("unable to send service request");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        className={classes.appBar}
      ></AppBar>
      <Container component="main" className={classes.main} maxWidth="sm">
        <Paper className={classes.paper} variant="outlined">
          <Typography component="h1" variant="h4" align="center">
            Service Request
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for reaching us out
                </Typography>
                <Typography variant="subtitle1">
                  We have received your request and will send you an estimate
                  soon.
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Place order" : "Next"}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </Container>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => state.srReducer;
export default connect(mapStateToProps)(ServiceRequestCheckout);
