import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Chip,
  Button,
  Grid,
} from "@material-ui/core";
import getProfile from "../../../services/account/ProfileService";
import { Link } from "react-router-dom";
import app from "../../../services/firebase/FirebaseAppService";
import { connect } from "react-redux";

import _ from "lodash";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 150,
    width: 150,
    marginRight: "auto",
    marginLeft: "auto"
  },
}));

const Profile = ({ className, ...rest }) => {
  const classes = useStyles();

  const [user, setUser] = useState({
    avatar: "/static/images/avatars/avatar_6.png",
    city: "Los Angeles",
    country: "USA",
    jobTitle: "Senior Developer",
    email: "example@email.com",
    fname: "Katarina",
    lname: "Smith",
    timezone: "GTM-7",
  });

  const [emailVarified] = useState(
    app.auth().currentUser ? app.auth().currentUser.emailVerified : false
  );

  useEffect(() => {
    async function fetchProfile() {
      const result = await getProfile();
      console.log("doc data" + result.profile.docData);
      setUser({
        avatar: "/static/images/avatars/avatar_6.png",
        city: "Los Angeles",
        country: "USA",
        jobTitle: "Senior Developer",
        email: result.profile.docData
          ? result.profile.docData.email
          : user.email,
        fname: _.isUndefined(rest.profileDetails.fname)
          ? result.profile.docData.firstName + " "
          : rest.profileDetails.fname,
        lname: _.isUndefined(rest.profileDetails.lname)
          ? result.profile.docData.lastName + " "
          : rest.profileDetails.lname,
        timezone: "GTM-7",
      });
    }
    fetchProfile();
    console.log("test");
  }, [
    user.email,
    rest.profileDetails.fname,
    rest.profileDetails.lname,
    user.lname,
    user.fname,
  ]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Grid container>
            <Grid item sm={6} xs={12}>
            <Avatar className={classes.avatar} src={user.avatar} />{" "}
            </Grid>
            <Grid item sm={6} xs={12} style={{textAlign:"center"}}>
            <Typography color="textPrimary" gutterBottom variant="h4">
            {`${user.fname} ${user.lname}`}
          </Typography>{" "}
          <Typography color="textSecondary" variant="body1">
            {user.email}{" "}
          </Typography>{" "}
          <Typography color="textSecondary" variant="body1" style={{marginTop:"1%"}}>
            {emailVarified ? (
              <Chip
                label="Email Varified"
                clickable
                color="primary"
                variant="outlined"
                size="small"
              />
            ) : (
              <Chip
                label="Email Not Varified"
                clickable
                color="secondary"
                variant="outlined"
                size="small"
              />
            )}
          </Typography>{" "}
          <Typography color="textSecondary" variant="body1" style={{marginTop:"1%",width:"50%",marginRight:"auto",marginLeft:"auto"}}>
            {emailVarified ? (
              <></>
            ) : (
              <Link variant="body2" to="/verify" style={{textDecoration:"none"}}>
                <Button
                 variant="outlined"
                 color="primary"
                 size="small"
                 style={{
                 width: "100%",
                }}
                >
                verify email
                </Button>
              </Link>
            )}
          </Typography>{" "}
            </Grid>
          </Grid>
        </Box>{" "}
      </CardContent>{" "}
      <Divider />
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

// export default Profile;
const mapStateToProps = (state) => state.profileReducer;
export default connect(mapStateToProps)(Profile);
