import { useEffect } from "react";
import { connect } from "react-redux";
import store from "../../../../../redux/store/store";
import { setWorkFlowActiveState } from "../../../../../redux/actions/servicerequest";

function SrProgressStepper(props) {
  useEffect(() => {
    store.dispatch(setWorkFlowActiveState({ step: 0, status: props.status }));
    return function cleanup() {
      store.dispatch(setWorkFlowActiveState({ step: 0, status: "" }));
    };
  }, [props.status]);

  return <></>;
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(SrProgressStepper);
