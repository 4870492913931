import app from "../firebase/FirebaseAppService";
import moment from "moment";
import { id } from "date-fns/locale";
const db = app.firestore();
const collection = "ServiceRequests";
const collectionGroup = "requestsList"

export default async function savePaymentMethod(paymentMethod, uid, docId) {
    const docRef = db.collection(collection).doc(uid).collection(collectionGroup).doc(docId);
    try {
        if (paymentMethod === "Cash") {
            await docRef.set({
                paymentInfo: {
                    paymentMethod: paymentMethod,
                    paymentDate: moment().format()
                },
                paymentStatus: "Paid"
            }, { merge: true });
        } else if (paymentMethod === "Online") {
            await docRef.set({
                paymentInfo: {
                    paymentMethod: paymentMethod,
                    paymentDate: moment().format()
                }
            }, { merge: true });
        }
        return {
            status: "success",
            docId: docRef.id,
            docData: paymentMethod
        };
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            docData: {

            }
        }
    }
}


