import app from "../firebase/FirebaseAppService";

const db = app.firestore();

export async function sendNotificationEmail(email, subject, text) {
    try {
        await db.collection("Emails").add({
            to: email,
            message: {
                subject,
                text,
                html: ""
            },
        });
        return {
            status: "success"
        };
    } catch (error) {
        console.log(error);
        return {
            status: "failure"
        };
    }
}