import { useEffect, useState } from "react";
import { Box, Backdrop, Container, makeStyles } from "@material-ui/core";
import Page from "../../../support/page/Page";
import Results from "./Results";
import getAllServiceRequests from "../../../../services/service-request/index";
import _ from "lodash";
import store from "../../../../redux/store/store";
import {
  emptyNewServiceRequests,
  fetchNewServiceRequests,
} from "../../../../redux/actions/servicerequest";
import CircularProgressBar from "../../../support/progress/CircularProgressBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "85%",
    paddingBottom: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const ServiceRequestListView = () => {
  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      handleBackDropOpen();
      const result = await getAllServiceRequests("All requests");
      if (result.status === "success") {
        const sortedSrList = _.sortBy(
          [...result.docs],
          [
            function (o) {
              return o.result.docData.referenceId;
            },
          ]
        ).reverse();
        store.dispatch(fetchNewServiceRequests([...sortedSrList]));
        handleBackDropClose();
      } else {
        handleBackDropClose();
      }
    }
    fetchData();
    return function cleanup() {
      store.dispatch(emptyNewServiceRequests());
    };
  }, []);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  function handleBackDropClose() {
    setOpenBackdrop(false);
  }

  function handleBackDropOpen() {
    setOpenBackdrop(true);
  }
  return (
    <>
      <Page className={classes.root}>
        <Container maxWidth={false}>
          <Box mt={1}>{<Results />}</Box>{" "}
        </Container>{" "}
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgressBar display={true} />
        </Backdrop>
      </Page>
    </>
  );
};

export default ServiceRequestListView;
