import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Button,
  Grid,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useState } from "react";
import SubmitTermsConditions, {
  getAllDetails,
  SubmitPaymentGatewayDetails,
  SubmitPolicy,
} from "../../../services/settings/Settings";
import CircularProgressBar from "../../support/progress/CircularProgressBar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useEffect } from "react";
import app from "../../../services/firebase/FirebaseAppService";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    margin: theme.spacing(1, 3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Settings = () => {
  const classes = useStyles();

  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [policy, setPolicy] = useState("");
  const [keyId, setKeyId] = useState("");
  const [keySecret, setKeySecret] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleTermsAndConditionChange = (e) => {
    setTermsAndConditions(e.target.value);
  };

  const handlePolicyChange = (e) => {
    setPolicy(e.target.value);
  };

  const handleKeyIdChange = (e) => {
    setKeyId(e.target.value);
  };

  const handleKeySecretChange = (e) => {
    setKeySecret(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handlePasswordUpdate = (e) => {
    if (password === confirmPassword) {
      e.preventDefault();
      handleBackDropOpen();
      const user = app.auth().currentUser;
      user
        .updatePassword(password)
        .then(() => {
          handleBackDropClose();
          setSnackBarMsg("Password Updated Successfully");
          setSnackBarSeverity("success");
          handleClickOpenSnackBar();
        })
        .catch((error) => {
          console.log(error);
          if (error.code === "auth/requires-recent-login") {
            handleBackDropClose();
            setSnackBarMsg(
              "Sign in again & try later. Requires recent sign In"
            );
            setSnackBarSeverity("error");
            handleClickOpenSnackBar();
          } else {
            handleBackDropClose();
            setSnackBarMsg(error.message);
            setSnackBarSeverity("error");
            handleClickOpenSnackBar();
          }
        });
    } else {
      handleBackDropClose();
      setSnackBarMsg("Passwords not matching");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  };

  const [openBackdrop, setOpenBackdrop] = useState(false);

  function handleBackDropClose() {
    setOpenBackdrop(false);
  }

  function handleBackDropOpen() {
    setOpenBackdrop(true);
  }

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("");

  const handleClickOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleSubmitTermsAndConditions = async (e) => {
    e.preventDefault();
    handleBackDropOpen();
    const submit = await SubmitTermsConditions(termsAndConditions);
    if (submit.status === "success") {
      handleBackDropClose();
      setSnackBarMsg("Response sent Successfully");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
    } else {
      handleBackDropClose();
      setSnackBarMsg("Unable to send Response");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  };

  const handleSubmitPolicy = async (e) => {
    e.preventDefault();
    handleBackDropOpen();
    const submit = await SubmitPolicy(policy);
    if (submit.status === "success") {
      handleBackDropClose();
      setSnackBarMsg("Response sent Successfully");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
    } else {
      handleBackDropClose();
      setSnackBarMsg("Unable to send Response");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  };

  const handlePaymentGatewayDetailsSubmit = async (e) => {
    e.preventDefault();
    handleBackDropOpen();
    const submitDetails = await SubmitPaymentGatewayDetails(keyId, keySecret);
    if (submitDetails.status === "success") {
      handleBackDropClose();
      setSnackBarMsg("Response sent Successfully");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
    } else {
      handleBackDropClose();
      setSnackBarMsg("Unable to send Response");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  };

  useEffect(() => {
    async function fetch() {
      const allDetails = await getAllDetails();
      if (allDetails.status === "success") {
        console.log(allDetails);
        if (allDetails.docData.keyId.keyId) {
          setKeyId(allDetails.docData.keyId.keyId);
        }

        if (allDetails.docData.keySecret.keySecret) {
          setKeySecret(allDetails.docData.keySecret.keySecret);
        }

        if (allDetails.docData.metaData && allDetails.docData.metaData.policy) {
          setPolicy(allDetails.docData.metaData.policy);
        }

        if (
          allDetails.docData.metaData &&
          allDetails.docData.metaData.termsAndConditions
        ) {
          setTermsAndConditions(allDetails.docData.metaData.termsAndConditions);
        }
      }
    }
    fetch();
  }, []);

  return (
    <Paper className={classes.paper}>
      <Typography variant="h4" gutterBottom className={classes.header}>
        Settings
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Terms and Conditions
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                id="termsandconditions"
                name="termsandconditions"
                label="Terms and Conditions"
                fullWidth
                value={termsAndConditions}
                onChange={handleTermsAndConditionChange}
                variant="outlined"
                size="small"
                multiline={true}
                rows={22}
              />
            </AccordionDetails>
            <Button
              variant="contained"
              color="primary"
              style={{ float: "right", margin: "2%" }}
              onClick={handleSubmitTermsAndConditions}
            >
              Submit T&C
            </Button>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Privacy Policy
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                id="policy"
                name="policy"
                label="Policy"
                fullWidth
                value={policy}
                onChange={handlePolicyChange}
                variant="outlined"
                size="small"
                multiline={true}
                rows={22}
              />
            </AccordionDetails>
            <Button
              variant="contained"
              color="primary"
              style={{ float: "right", margin: "2%" }}
              onClick={handleSubmitPolicy}
            >
              Submit Policy
            </Button>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Payment Gateway Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="policy"
                    name="policy"
                    label="Key Id"
                    fullWidth
                    value={keyId}
                    onChange={handleKeyIdChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="policy"
                    name="policy"
                    label="Key Secret"
                    fullWidth
                    value={keySecret}
                    onChange={handleKeySecretChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
            <Button
              variant="contained"
              color="primary"
              style={{ float: "right", margin: "2%" }}
              onClick={handlePaymentGatewayDetailsSubmit}
            >
              Submit Details
            </Button>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Update Password
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="password"
                    name="password"
                    label="Password"
                    fullWidth
                    value={password}
                    onChange={handlePasswordChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirm Password"
                    fullWidth
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
            <Button
              variant="contained"
              color="primary"
              style={{ float: "right", margin: "2%" }}
              onClick={handlePasswordUpdate}
            >
              Update Password
            </Button>
          </Accordion>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgressBar display={true} />
      </Backdrop>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default Settings;
