import { Box, Grid, makeStyles, TextField } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
    display: "flex",
    alignItems: "center",
    margin: "2% 0",
    flexGrow: 1,
    justifyContent: "center",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },
}));

function Feedback(props) {
  const classes = useStyles();

  const [value, setValue] = useState(-1);
  const [feedbackDescription, setFeedbackDescription] = useState("");

  const [hover, setHover] = useState(-1);
  const labels = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };

  useEffect(() => {
    if (props.serviceRequest && props.serviceRequest.userFeedback) {
      setValue(props.serviceRequest.userFeedback.rating);
      setFeedbackDescription(props.serviceRequest.userFeedback.description);
    }
  }, [props.serviceRequest]);
  console.log(value);
  return (
    <Grid
      item
      xs={12}
      style={{ display: props.serviceRequest.userFeedback ? "block" : "none" }}
    >
      <div className={classes.root}>
        <Rating
          name="hover-feedback"
          value={value}
          precision={0.5}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          size="large"
          readOnly
        />
        {value !== null && (
          <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>
        )}
      </div>
      <form noValidate>
        <TextField
          id="outlined-multiline-static"
          label="Feedback Description"
          multiline
          rows={3}
          value={feedbackDescription}
          variant="outlined"
          size="medium"
          fullWidth
          marginLeft="normal"
          disabled
        />
      </form>{" "}
    </Grid>
  );
}

export default Feedback;
