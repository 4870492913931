import app from "../firebase/FirebaseAppService";
import store from "../../redux/store/store";
import { logOutFailure, logOutSuccess } from "../../redux/actions/logout";

export default function signOut() {
    app.auth().signOut().then(() => {
        store.dispatch(logOutSuccess())
    }).catch((err) => {
        store.dispatch(logOutFailure(app.auth().currentUser))
    })
}