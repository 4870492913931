import firebase from "firebase";
import dotenv from "dotenv";
import store from "../../redux/store/store";
import { userSignedOut, userSignedIn, authCheckDone } from "../../redux/actions/auth";
dotenv.config()

// Initialize Firebase App
const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STRORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

// const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// appCheck.activate(process.env.REACT_APP_APP_CHECK_PUBLIC_KEY);

firebase.auth().onAuthStateChanged(function(user) {
    console.log("auth sys changed");
    if (user) {
        firebase.auth().currentUser.getIdTokenResult()
            .then((idTokenResult) => {
                // Confirm the user is an Admin.
                if (!!idTokenResult.claims.admin ||
                    !!idTokenResult.claims.adminUser ||
                    !!idTokenResult.claims.serviceEngineer) {
                    if (!!idTokenResult.claims.admin) {
                        dispatchSuccessActions(user, "admin");
                    } else if (!!idTokenResult.claims.adminUser) {
                        dispatchSuccessActions(user, "adminUser");
                    } else if (!!idTokenResult.claims.serviceEngineer) {
                        dispatchSuccessActions(user, "serviceEngineer");
                    }
                    console.log(idTokenResult.claims);
                } else {
                    // Show regular user UI.
                    console.log('is not admin');
                    dispatchErrorActions();
                }
            })
            .catch((error) => {
                console.log(error);
                dispatchErrorActions();
                alert('error logging in, access denied');
            });
    } else {
        dispatchErrorActions();
    }
});

function dispatchSuccessActions(user, claims) {
    store.dispatch(userSignedIn(user));
    store.dispatch(authCheckDone(claims));
}

function dispatchErrorActions() {
    store.dispatch(userSignedOut())
    store.dispatch(authCheckDone(""))
}
export default app;