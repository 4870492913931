import app from "../firebase/FirebaseAppService";

const db = app.firestore();
const collectionName = "Users";

export default async function getAllUsers() {
    try {
        const snapshot = await db.collection(collectionName).where("enabled", "==", true).limit(200).get();
        if (snapshot.empty) {
            return {
                status: "success",
                docs: []
            };
        }
        var docs = snapshot.docs.map(function(doc) {
            return {
                docData: doc.data(),
                docId: doc.id
            }
        })
        return {
            status: "success",
            docs
        };
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            docs: []
        };
    }
}

export async function getUserDetailsByUid(docId) {
    const doc = await db.collection("Users").doc(docId).get();
    console.log(docId);
    try {
        if (doc.exists) {
            return {
                status: "success",
                docId: doc.id,
                docData: doc.data()
            };
        } else {
            return {
                status: "failure",
                docId: doc.id,
                docData: {

                }
            };
        }
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            docId: doc.id,
            docData: {

            }
        };
    }
}

export async function deleteUserByUid(uid) {
    try {
        const idToken = await app.auth().currentUser.getIdToken();
        var deleteUser = app.functions().httpsCallable('deleteUser');
        const result = await deleteUser({
            uid,
            idToken
        });
        console.log(result);
        if (result.data.status === "success") {
            return {
                status: "success"
            };
        } else {
            return {
                status: "failure",
            };
        }
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
        };
    }
}