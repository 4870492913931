import app from "../firebase/FirebaseAppService"
const db = app.firestore();

export default async function SubmitTermsConditions(termsAndConditions) {
    const docRef = db.collection("App").doc("metadata");
    try {
        return await docRef.set({
            termsAndConditions: termsAndConditions,
        }, {
            merge: true
        }).then(() => {
            return {
                status: "success"
            }
        });
    } catch (error) {
        console.log(error)
        return {
            status: "failure"
        }
    }
}

export async function SubmitPolicy(policy) {
    const docRef = db.collection("App").doc("metadata");
    try {
        return await docRef.set({
            policy: policy
        }, {
            merge: true
        }).then(() => {
            return {
                status: "success"
            }
        });
    } catch (error) {
        console.log(error)
        return {
            status: "failure"
        }
    }
}

export async function SubmitPaymentGatewayDetails(keyId, keySecret) {
    const keyRef = db.collection("App").doc("razorpayKeyId");
    const secretRef = db.collection("App").doc("razorpayKeySecret");
    try {
        const batch = db.batch();
        batch.set(keyRef, {
            keyId
        });
        batch.set(secretRef, {
            keySecret
        });
        return batch.commit().then(() => {
            return {
                status: "success"
            }
        });
    } catch (error) {
        console.log(error)
        return {
            status: "failure"
        }
    }
}

export async function getAllDetails(){
    const metaData = await db.collection("App").doc("metadata").get()

    const keyId = await db.collection("App").doc("razorpayKeyId").get()
    const keySecret = await db.collection("App").doc("razorpayKeySecret").get()
    try {
        if (metaData.exists || keyId.exists || keySecret.exists) {
            const docData = {
                metaData:metaData.data(),
                keyId:keyId.data(),
                keySecret:keySecret.data()
            }
            return {
                status: "success",
                docData
            };
        } else {
            return {
                status: "failure",
                docData: {

                }
            };
        }
    } catch (error) {
        console.log(error)
        return {
            status: "failure",
            docs: []
        };
    }
}