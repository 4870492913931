import { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import signInWithEmailPassword from "../../services/auth/SignInService";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import GlassCard from "../../utils/glassCard";
import validator from "validator";
import _ from "lodash";
import { FormHelperText } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${process.env.REACT_APP_MAIN_IMG_URL})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#1976d2",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  email: {
    "& p": {
      color: "crimson",
    },
    floatingLabelFocusStyle: {
      color: "somecolor",
    },
  },
}));

function SignIn(props) {
  const classes = useStyles();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [appUser, setAppUser] = useState(props.user);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setAppUser(props.user);
    if (props.user.uid) {
      props.history.push("/");
    }
  }, [props.history, props.user]);

  function handleEmailChange(e) {
    e.preventDefault();
    setEmail(e.target.value);
    validateEmail(e);
  }

  function handlePasswordChange(e) {
    e.preventDefault();
    setPassword(e.target.value);
    validatePassword(e);
  }

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("");

  const handleClickOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  function handleClick(e) {
    e.preventDefault();
    signInWithEmailPassword(email, password).then((result) => {
      if (result.status === "success") {
        setSnackBarMsg("SignIn successfully");
        setSnackBarSeverity("success");
        handleClickOpenSnackBar();
      } else {
        setSnackBarMsg("Unable to SignIn");
        setSnackBarSeverity("error");
        handleClickOpenSnackBar();
      }
    });
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [btndisabled, setBtndisabled] = useState(true);
  const [checked, setChecked] = useState(false);

  const validateEmail = (e) => {
    var email = e.target.value;
    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Enter valid Email!");
      setBtndisabled(true);
    }
  };

  const validatePassword = (e) => {
    var password = e.target.value;
    if (
      password.match(/[!#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) ||
      _.isEmpty(password)
    ) {
      setPasswordError("Enter valid Password !");
      setBtndisabled(true);
    } else {
      setPasswordError("");
    }
  };

  useEffect(() => {
    if (
      _.isEmpty(passwordError) &&
      validator.isEmail(email) &&
      !_.isEmpty(password)
    ) {
      setBtndisabled(false);
    } else {
      setBtndisabled(true);
    }
  }, [checked, email, password, passwordError]);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {`${process.env.REACT_APP_NAME} (MANAGEMENT)`}
          </Typography>
          <Typography component="h2" variant="h6">
            Sign In
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              className={classes.email}
              fullWidth
              id="email"
              label="Email Address"
              value={email}
              name="email"
              autoComplete="email"
              type="email"
              onChange={handleEmailChange}
              helperText={emailError}
              error={emailError}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              size="small"
            />{" "}
            <FormControl
              variant="outlined"
              fullWidth
              helperText={passwordError}
              size="small"
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                error={passwordError}
              >
                Password
              </InputLabel>
              <OutlinedInput
                required
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                error={passwordError}
                helperText={emailError}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
              <FormHelperText error id="accountId-error">
                {passwordError}
              </FormHelperText>
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleClick}
              disabled={btndisabled}
            >
              Sign In{" "}
            </Button>{" "}
            <Grid container>
              <Grid item xs>
                <Link
                  variant="body2"
                  to="/forgot"
                  style={{ textDecoration: "none", color: "#1976d2" }}
                >
                  Forgot password ?
                </Link>{" "}
              </Grid>{" "}
            </Grid>{" "}
          </form>
        </div>
      </Grid>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

const mapStateToProps = (state) => state.authReducer;
export default connect(mapStateToProps)(SignIn);
