import fetch from "cross-fetch";
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import store from "../../../redux/store/store";
import { connect } from "react-redux";
import _ from "lodash";
import { fetchNewServiceRequests } from "../../../redux/actions/servicerequest";
import { IconButton, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import getAllServiceRequests from "../../../services/service-request";
import getAllEnquiries from "../../../services/enquiry";
import { fetchEnquiries } from "../../../redux/actions/enquiry";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  toolbar: {
    marginLeft: theme.spacing(-1.5),
  },
}));

function AsynchronousSearch(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        setOptions(
          props.srReducer.newSrList.map((sr) => sr.result.docData.docId)
        );
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const [mSearchValue, setMsearchValue] = useState("");

  async function handleOnChange(e) {
    e.preventDefault();
    setMsearchValue(e.target.value);
    if (
      props.searchType === "serviceRequest" &&
      props.searchByValue === "RequestId" &&
      e.target.value === ""
    ) {
      fetchServiceRequests();
    }
    if (
      props.searchType === "Enquiry" &&
      props.searchByValue === "RequestId" &&
      e.target.value === ""
    ) {
      console.log("Enquiry");
      fetchAllEnquiries();
    }
  }

  async function handleOnClick(e) {
    e.preventDefault();
    const searchvalue = mSearchValue;

    //Search on service request
    /**by request id */
    if (
      props.searchType === "serviceRequest" &&
      props.searchByValue === "RequestId" &&
      searchvalue !== ""
    ) {
      serviceRequestSearchByRequestId(searchvalue);
    }
    //Search on enquiries
    if (
      props.searchType === "Enquiry" &&
      props.searchByValue === "RequestId" &&
      searchvalue !== ""
    ) {
      console.log("Enquiry");
      enquirySearchByRequestId(searchvalue);
    }
    //Search on paymnets
  }

  function serviceRequestSearchByRequestId(searchvalue) {
    const paddedSearchvalue = searchvalue.toString().padStart(10, "0");
    console.log(paddedSearchvalue);
    const sortedSrListByDocId = _.filter(
      [...props.srReducer.newSrList],
      function (o) {
        return o.result.docData.referenceId === paddedSearchvalue;
      }
    );
    store.dispatch(fetchNewServiceRequests([...sortedSrListByDocId]));
  }

  async function fetchServiceRequests() {
    const result = await getAllServiceRequests("All requests");
    if (result.status === "success") {
      const sortedSrList = _.sortBy(
        [...result.docs],
        [
          function (o) {
            return o.result.docData.referenceId;
          },
        ]
      ).reverse();
      store.dispatch(fetchNewServiceRequests([...sortedSrList]));
    }
  }

  async function fetchAllEnquiries() {
    const result = await getAllEnquiries();
    if (result.status === "success") {
      const sortedEnqList = _.sortBy(
        [...result.docs],
        [
          function (o) {
            return o.docData.referenceId;
          },
        ]
      ).reverse();
      store.dispatch(fetchEnquiries([...sortedEnqList]));
    }
  }

  function enquirySearchByRequestId(searchvalue) {
    const paddedSearchvalue = searchvalue.toString().padStart(10, "0");
    console.log(paddedSearchvalue);
    const sortedEnqListByDocId = _.filter(
      [...props.enquiryReducer.newEnquiriesList],
      function (o) {
        return o.docData.referenceId === paddedSearchvalue;
      }
    );
    console.log(sortedEnqListByDocId);
    store.dispatch(fetchEnquiries([...sortedEnqListByDocId]));
  }

  return (
    <>
      <TextField
        label="Search by request Id"
        margin="normal"
        variant="outlined"
        size={"small"}
        onChange={handleOnChange}
        className={classes.formControl}
        InputLabelProps={{
          style: {
            width: "-webkit-fill-available",
          },
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={handleOnClick}
              className={classes.searchBtn}
              size="small"
            >
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
      {/* <IconButton onClick={handleOnClick} className={classes.searchBtn}>
        <SearchIcon />
      </IconButton> */}
    </>
  );
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(AsynchronousSearch);
