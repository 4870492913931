import app from "../firebase/FirebaseAppService";
const db = app.firestore();

const collectionName = "ServiceRequests";
const collectionGroupName = "requestsList";

export async function assignEngineer(engineer, docId, uid) {
    const docRef = db.collection(collectionName).doc(uid).collection(collectionGroupName).doc(docId);
    console.log(engineer);
    console.log(docId);
    console.log(uid);
    try {
        await docRef.set({
            workAssignment: {
                engineer
            },
            serviceRequestStatus: "Engineer Assigned"
        }, {
            merge: true
        });
        return {
            status: "success",
            docId: docRef.id,
            docData: engineer
        };
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            docId: docRef.id,
            docData: {

            }
        };
    }
}

/*deprecated*/
export async function getAssignedEngineer(docId, uid, src) {
    console.log(src, " ", docId, " ", uid);
    const doc = await db.collection(collectionName).doc(uid).collection(collectionGroupName).doc(docId).get();
    try {
        if (doc.exists) {
            return {
                status: "success",
                docId: doc.id,
                docData: doc.data()
            };
        } else {
            return {
                status: "failure",
                docId: doc.id,
                docData: {

                }
            };
        }
    } catch (error) {
        return {
            status: "failure",
            docId: doc.id,
            docData: {

            }
        };
    }
}

export async function markWorkCompletion(docId, uid) {
    const docRef = db.collection(collectionName).doc(uid).collection(collectionGroupName).doc(docId);
    try {
        return await docRef.set({
            serviceRequestStatus: "Work Completed"
        }, {
            merge: true
        }).then(() => {
            return {
                status: "success",
                docId: docRef.id,
            };
        })
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            docId: docRef.id,
        };
    }
}

export async function getWorkCompletionStatus(docId, uid) {
    const doc = await db.collection(collectionName).doc(uid).collection("collectionGroupName1").doc(docId).get();
    try {
        if (doc.exists) {
            return {
                status: "success",
                docId: doc.id,
                docData: doc.data()
            };
        } else {
            return {
                status: "failure",
                docId: doc.id,
                docData: {

                }
            };
        }
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            docId: doc.id,
            docData: {

            }
        };
    }
}