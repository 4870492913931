import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { postEnquiryResponse } from "../../../../services/enquiry";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const ResponseDialog = (props) => {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [response, setResponse] = useState("");
  const [open, setOpen] = React.useState(true);
  const [redirect, setRedirect] = useState(false);

  console.log(props);

  const handleClickOpen = () => {
    setOpen(true);
    setRedirect(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRedirect(true);
  };

  const handleResponseChange = (e) => {
    setResponse(e.target.value);
  };

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("");

  const handleClickOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleSubmit = async (e, uid, docId) => {
    e.preventDefault();
    console.log(uid + " " + docId);
    const result = await postEnquiryResponse(response, uid, docId);
    setOpen(false);
    if (result.status === "success") {
      setSnackBarMsg("Response sent Successfully");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
    } else {
      setSnackBarMsg("Unable to send Response");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Enquiry Response</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            id="outlined-multiline-static"
            label="Describe your Response"
            multiline
            rows={4}
            variant="outlined"
            value={response}
            onChange={handleResponseChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={(e) =>
              handleSubmit(e, props.customer.docData.uid, props.customer.docId)
            }
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ResponseDialog;
