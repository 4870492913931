export const setWorkFlowActiveStep = (activeStep) => {
    return {
        type: "activestep/enquiry",
        payload: activeStep,
    };
};

export const fetchEnquiries = (enquiries) => {
    return {
        type: "fetch/newEnquiryList",
        payload: enquiries,
    };
};

export const emptyEnquiries = () => {
    return {
        type: "empty/newEnquiryList",
    };
};