import {
  Backdrop,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  ListSubheader,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import moment from "moment";
import _ from "lodash";
import CircularProgressBar from "../../support/progress/CircularProgressBar";
import Page from "../../support/page/Page";
import { useEffect, useState } from "react";
import getAllProductCategories from "../../../services/product";
import { isMobile } from "react-device-detect";
import AddCorporateProducts, {
  DeleteCorporateProduct,
  getCorporateProducts,
} from "../.././../services/corporate/corporate";
import { Alert } from "@material-ui/lab";
import { Edit } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  header: {
    margin: theme.spacing(2),
    color: theme.palette.text.secondary,
    float: "left",
  },
}));

export default function Corporate(props) {
  const classes = useStyles();

  const [productCategories, setProductCategories] = useState([]);

  const [productName, setProductName] = useState("");
  const [productModel, setProductModel] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productSerialNumber, setProductSerialNumber] = useState("");
  const [lProductCategories, setLProductCategories] = useState([]);

  function handleCategoryChange(e) {
    setProductCategory(_.capitalize(e.target.value));
    setProductCategory(e.target.value);
    const dProductCategories = [];
    productCategories.forEach((mainCategory) => {
      mainCategory.subCategories.forEach((category) => {
        dProductCategories.push(category);
      });
    });
    console.log(dProductCategories);
    setLProductCategories(dProductCategories);

    console.log(productCategories);
    console.log(dProductCategories);
  }

  function productNameChange(e) {
    e.preventDefault();
    setProductName(e.target.value);
  }

  function handleProductModelChange(e) {
    e.preventDefault();
    setProductModel(e.target.value);
  }
  function handleProductBrandChange(e) {
    e.preventDefault();
    setProductBrand(e.target.value);
  }
  function handleProductSerialNumberChange(e) {
    e.preventDefault();
    setProductSerialNumber(e.target.value);
  }

  const [openAddProduct, setOpenAddProduct] = useState(false);

  const handleClickOpenAddProduct = () => {
    setOpenAddProduct(true);
    setProductName();
    setProductCategory();
    setProductModel();
    setProductBrand();
    setProductSerialNumber();
  };

  const handleCloseAddProduct = () => {
    setOpenAddProduct(false);
  };

  const [CorporateProducts, setCorporateProducts] = useState([]);

  const handelUpdateCorporateProduct = (e, index) => {
    setOpenAddProduct(true);
    e.preventDefault();
    setProductCategory(
      CorporateProducts[index].docData.corporateProducts.productCategory
    );
    setProductName(
      CorporateProducts[index].docData.corporateProducts.productName
    );
    setProductModel(
      CorporateProducts[index].docData.corporateProducts.productModel
    );
    setProductBrand(
      CorporateProducts[index].docData.corporateProducts.productBrand
    );
    setProductSerialNumber(
      CorporateProducts[index].docData.corporateProducts.productSerialNumber
    );
  };

  const handelDeleteCorporateProduct = async (e, index) => {
    e.preventDefault();
    const fields = [...CorporateProducts];
    fields[index].docData.status = "Disabled";
    setCorporateProducts(fields);
    const deleteProduct = await DeleteCorporateProduct(
      CorporateProducts[index].docId,
      "Disabled"
    );
    console.log(CorporateProducts[index].docData.status);
  };

  const [isMobileView, setIsMobileView] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const handleResize = () => {
    if (window.innerWidth <= fullScreen) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  });

  const [submitProductDisable, setSubmitProductDisable] = useState(false);
  useEffect(() => {
    _.isEmpty(productCategory) ||
    _.isEmpty(productName) ||
    _.isEmpty(productModel) ||
    _.isEmpty(productBrand) ||
    _.isEmpty(productSerialNumber)
      ? setSubmitProductDisable(true)
      : setSubmitProductDisable(false);
  }, [
    productBrand,
    productCategory,
    productModel,
    productName,
    productSerialNumber,
  ]);

  useEffect(() => {
    async function getCategoriesData() {
      const result = await getAllProductCategories();
      if (result.status === "success") {
        console.log(result.docs);
        const temp = _([...result.docs])
          .groupBy((x) => x.docData.parentCategoryId)
          .map((value, key) => ({ mainCategoryId: key, subCategories: value }))
          .value();
        const temp1 = _.filter(temp, (o) => {
          return o.mainCategoryId !== "null";
        });

        setProductCategories(temp1);
      }
    }
    getCategoriesData();
  }, []);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("");

  const handleClickOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };
  console.log(CorporateProducts);
  const [status, setStatus] = useState("Enable");
  const handleSubmitProduct = async () => {
    const productCategoryO = _.filter([...lProductCategories], (o) => {
      return o.docData.categoryName === productCategory;
    });
    const Product = {
      productCategory,
      productName,
      productModel,
      productBrand,
      productSerialNumber,
      minimumHandelingCharges: _.isUndefined(productCategoryO[0])
        ? 0
        : productCategoryO[0].docData.minimumCharges,
    };

    const corporateProducts = await AddCorporateProducts(
      Product,
      status,
      props.uid
    );
    if (corporateProducts.status === "success") {
      setCorporateProducts([
        ...CorporateProducts,
        {
          docData: {
            corporateProducts: Product,
            status: "Enable",
            uid: props.uid,
          },
          docId: "7BzEEP58KONHJ2y6KL0j",
        },
      ]);

      setOpenAddProduct(false);
      setSnackBarMsg("Response saved Successfully");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
    } else {
      alert("error");
      setOpenAddProduct(true);
      setSnackBarMsg("Unable to save Response");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const products = await getCorporateProducts(props.uid);
      console.log(products);

      if (products.status === "success" && products.docs) {
        setCorporateProducts(products.docs);
      } else {
        setCorporateProducts([]);
      }
    };
    fetchProducts();
  }, [props.uid]);

  return (
    <>
      <div>
        <Typography variant="h4" gutterBottom className={classes.header}>
          Products
        </Typography>
        <Dialog
          open={openAddProduct}
          onClose={handleCloseAddProduct}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Add Product</DialogTitle>
          <DialogContent>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel htmlFor="demo-simple-select-label" required>
                Product Categories
              </InputLabel>
              <Select
                fullWidth
                id="demo-simple-select-label"
                label="Product Categories"
                onChange={handleCategoryChange}
                variant="outlined"
                size="small"
              >
                {productCategories.map((productCategory, index) => {
                  let children = [];
                  children.push(
                    <ListSubheader>
                      {
                        productCategories[index].subCategories[0].docData
                          .parentCategoryName
                      }
                    </ListSubheader>
                  );
                  productCategories[index].subCategories.forEach(
                    (subcategory) => {
                      children.push(
                        <MenuItem
                          value={subcategory.docData.categoryName}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {subcategory.docData.categoryName}
                          <div
                            style={{
                              float: "right",
                              alignContent: "right",
                            }}
                          >
                            Minimum Charges:{" "}
                            {subcategory.docData.minimumCharges}
                          </div>
                        </MenuItem>
                      );
                    }
                  );
                  return children;
                })}
              </Select>
            </FormControl>

            <TextField
              required
              id="productname"
              name="productname"
              label="Product Name"
              fullWidth
              autoComplete="productname"
              value={productName}
              onChange={productNameChange}
              variant="outlined"
              size="small"
              style={{ marginTop: "2%" }}
            />

            <TextField
              required
              id="productmodel"
              name="productbmodel"
              label="Product Model"
              fullWidth
              autoComplete="product-model"
              value={productModel}
              onChange={handleProductModelChange}
              variant="outlined"
              size="small"
              style={{ marginTop: "2%" }}
            />

            <TextField
              required
              id="productbrand"
              name="productbrand"
              label="Product Brand"
              fullWidth
              autoComplete="product-brand"
              value={productBrand}
              onChange={handleProductBrandChange}
              variant="outlined"
              size="small"
              style={{ marginTop: "2%" }}
            />

            <TextField
              required
              id="productserialNo"
              name="productserialNo"
              label="Product Serial Number"
              fullWidth
              autoComplete="product serial-number"
              value={productSerialNumber}
              onChange={handleProductSerialNumberChange}
              variant="outlined"
              size="small"
              style={{ marginTop: "2%" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddProduct} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmitProduct}
              color="primary"
              disabled={submitProductDisable}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Page>
        <div
          style={{
            display: isMobileView || isMobile === true ? "none" : "block",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disableElevation
            style={{ float: "right", margin: "1% 1% 0 0" }}
            onClick={handleClickOpenAddProduct}
          >
            Add Product
          </Button>{" "}
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Product Category</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Product Model</TableCell>
                <TableCell>Product Brand</TableCell>
                <TableCell>Product Serial Number</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {CorporateProducts.map((CorporateProduct, index) => (
                <TableRow hover key={CorporateProduct.docId}>
                  <TableCell>{`${CorporateProduct.docData.corporateProducts.productCategory}`}</TableCell>
                  <TableCell>{`${CorporateProduct.docData.corporateProducts.productName}`}</TableCell>
                  <TableCell>{`${CorporateProduct.docData.corporateProducts.productModel}`}</TableCell>
                  <TableCell>{`${CorporateProduct.docData.corporateProducts.productBrand}`}</TableCell>
                  <TableCell>
                    {`${CorporateProduct.docData.corporateProducts.productSerialNumber}`}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={CorporateProduct.docData.status}
                      style={{
                        border:
                          CorporateProduct.docData.status === "Disabled"
                            ? "2px solid #e53935"
                            : "2px solid #ffc400",
                        color:
                          CorporateProduct.docData.status === "Disabled"
                            ? "#e53935"
                            : "#ffc400",
                        fontWeight: "bold",
                        width: "100%",
                        backgroundColor: "#fff",
                      }}
                      size="small"
                    ></Chip>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={(e) => handelUpdateCorporateProduct(e, index)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      onClick={(e) => handelDeleteCorporateProduct(e, index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div
          style={{
            display: isMobileView || isMobile === true ? "block" : "none",
          }}
        >
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{
              margin: "1% 5% 0 5%",
              width: "90%",
            }}
            onClick={handleClickOpenAddProduct}
          >
            Add Product
          </Button>{" "}
          <Table size="small" style={{ marginTop: "1%" }}>
            <TableBody>
              {CorporateProducts.map((CorporateProduct, index) => (
                <TableRow hover key={CorporateProduct.docId}>
                  <TableCell>
                    <div className={classes.root}>
                      <Paper className={classes.paper}>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{ textAlign: "left" }}
                          >
                            <b>Product Category: </b>{" "}
                            {`${CorporateProduct.docData.corporateProducts.productCategory}`}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{ textAlign: "left" }}
                          >
                            <b> Product Name: </b>{" "}
                            {`${CorporateProduct.docData.corporateProducts.productName}`}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{ textAlign: "left" }}
                          >
                            <b> Product Model: </b>{" "}
                            {`${CorporateProduct.docData.corporateProducts.productModel}`}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{ textAlign: "left" }}
                          >
                            <b> Product Brand: </b>{" "}
                            {`${CorporateProduct.docData.corporateProducts.productBrand}`}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{ textAlign: "left" }}
                          >
                            <b> Product Serial Number: </b>{" "}
                            {`${CorporateProduct.docData.corporateProducts.productSerialNumber}`}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{ textAlign: "left" }}
                          >
                            <Chip
                              label={CorporateProduct.docData.status}
                              style={{
                                border:
                                  CorporateProduct.docData.status === "Disabled"
                                    ? "2px solid #e53935"
                                    : "2px solid #ffc400",
                                color:
                                  CorporateProduct.docData.status === "Disabled"
                                    ? "#e53935"
                                    : "#ffc400",
                                fontWeight: "bold",
                                width: "100%",
                                backgroundColor: "#fff",
                              }}
                              size="small"
                            ></Chip>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{ textAlign: "left" }}
                          >
                            {" "}
                            <IconButton
                              onClick={(e) =>
                                handelUpdateCorporateProduct(e, index)
                              }
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              onClick={(e) =>
                                handelDeleteCorporateProduct(e, index)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Paper>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <Backdrop className={classes.backdrop} /*open={openBackdrop}*/>
          <CircularProgressBar display={true} />
        </Backdrop>
        <Snackbar
          open={openSnackBar}
          autoHideDuration={3000}
          onClose={handleCloseSnackBar}
        >
          <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
            {snackBarMsg}
          </Alert>
        </Snackbar>
      </Page>
    </>
  );
}
