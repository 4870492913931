const initialState = {
    user: {},
    authCheck: "pending",
    claims: ""
}

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case 'login/sucess':
            return {
                ...state,
                user: action.payload,
            }
        case 'login/failure':
            return {
                ...state,
                user: {},
            }
        case 'signup/sucess':
            return {
                ...state,
                user: action.payload,
            }
        case 'signup/failure':
            return {
                ...state,
                user: {},
            }
        case 'user/signedin':
            return {
                ...state,
                user: action.payload
            }
        case 'user/signedout':
            return {
                ...state,
                user: {},
            }
        case 'logout/sucess':
            return {
                ...state,
                user: {},
            }
        case 'logout/failure':
            return {
                ...state,
                user: action.payload
            }
        case 'auth/check/done':
            return {
                ...state,
                authCheck: "done",
                claims: action.payload
            }
        default:
            return state
    }
}