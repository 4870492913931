import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  Paper,
  Grid,
  Container,
  Typography,
  Button,
  Backdrop,
} from "@material-ui/core";
import { connect } from "react-redux";
import store from "../../../../redux/store/store";
import _ from "lodash";
import AsynchronousSearch from "../../search/AsyncSearch";
import Page from "../../../support/page/Page";
import { getAllPayments } from "../../../../services/payment";
import { fillPaymentDetails } from "../../../../redux/actions/payment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CircularProgressBar from "../../../support/progress/CircularProgressBar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.dark,
    minHeight: "85%",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  paper1: {
    padding: theme.spacing(1),
    textAlign: "right",
    color: theme.palette.text.secondary,
  },
  toolbar: {
    marginLeft: theme.spacing(-2),
  },
  header: {
    margin: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Payments = ({ className, ...rest }) => {
  const props = { ...rest };
  const classes = useStyles();
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [isMobileView, setIsMobileView] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 767) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  });

  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const props = { ...rest };
    //setCustomers(props.newSrList);
  }, [rest]);

  const [sortValue, setSortValue] = useState("Latest");
  const handleChange = (event) => {
    setSortValue(event.target.value);
    /*if (event.target.value === "Oldest") {
      const sortedSrList = _.sortBy(
        [...customers],
        [
          function (o) {
            return o.docData.srDetails.serviceDetails.selectedDate;
          },
        ]
      );
      store.dispatch(fetchNewServiceRequests([...sortedSrList]));
    } else {
      const sortedSrList = _.sortBy(
        [...customers],
        [
          function (o) {
            return o.docData.srDetails.serviceDetails.selectedDate;
          },
        ]
      ).reverse();
      store.dispatch(fetchNewServiceRequests([...sortedSrList]));
    }*/
  };

  useEffect(() => {
    async function fetchPayments() {
      handleBackDropOpen();
      const results = await getAllPayments()
        .then((results) => {
          handleBackDropClose();
          return results;
        })
        .catch((error) => {
          handleBackDropClose();
          return [];
        });
      console.log(results);
      store.dispatch(fillPaymentDetails(results));
      setPayments(results);
      console.log("redux");
    }
    fetchPayments();
  }, []);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  function handleBackDropClose() {
    setOpenBackdrop(false);
  }

  function handleBackDropOpen() {
    setOpenBackdrop(true);
  }

  console.log(payments);
  return (
    <Page className={classes.root} title="My Payments">
      <Container maxWidth={false}>
        <Box mt={1}>
          <Paper className={classes.paper}>
            <Typography variant="h4" gutterBottom className={classes.header}>
              My Payments
            </Typography>
            {/*<Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Toolbar className={classes.toolbar}>
                  <AsynchronousSearch />
                </Toolbar>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Toolbar className={classes.toolbar}>
                  <Box display="flex" flexGrow={1}>
                  </Box>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    size="small"
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Sort
                    </InputLabel>
                    <Select
                      native
                      value={sortValue}
                      onChange={handleChange}
                      labelId="outlined-age-native-simple"
                      autoComplete
                      key="Confirmation Code"
                      label="Sort"
                    >
                      <option value={"Latest"}>Latest First</option>
                      <option value={"Oldest"}>Oldest First</option>
                    </Select>
                  </FormControl>
                </Toolbar>
              </Grid>
            </Grid> */}
            {/* Mobile View */}
            <TablePagination
              component="div"
              count={payments.length}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[25, 50, 200]}
            />
            <div
              style={{
                overflowX: "auto",
                display: isMobileView === true ? "block" : "none",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Payments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.slice(0, limit).map((payment, index) => (
                    <TableRow hover key={index}>
                      <TableCell>
                        <div className={classes.root}>
                          <Paper className={classes.paper}>
                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={12}
                                sm={2}
                                style={{ textAlign: "left" }}
                              >
                                <b>Sr Id: </b> {`${payment.referenceId}`}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={2}
                                style={{ textAlign: "left" }}
                              >
                                <b>Payment Id: </b>{" "}
                                {`${payment.razorpayPaymentId}`}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={2}
                                style={{ textAlign: "left" }}
                              >
                                <b>Payment Amount: </b> {`₹ ${payment.amount}`}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={2}
                                style={{ textAlign: "left" }}
                              >
                                <b>Payment Method: </b>{" "}
                                {`${payment.paymentMethod}`}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={2}
                                style={{ textAlign: "left" }}
                              >
                                <b>Payment Date: </b>{" "}
                                {payment.paymentDate === ""
                                  ? ""
                                  : moment
                                      .unix(payment.paymentDate)
                                      .format("DD/MM/YYYY")}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={2}
                                style={{ textAlign: "left" }}
                              >
                                <b>Payment Status: </b>{" "}
                                {`${payment.paymentStatus}`}
                              </Grid>
                            </Grid>
                          </Paper>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>

            {/* Desktop View */}
            <div
              style={{
                overflowX: "auto",
                display: isMobileView === true ? "none" : "block",
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Service Request ID</TableCell>
                    <TableCell>Payment ID</TableCell>
                    <TableCell>Payment Date</TableCell>
                    <TableCell>Payment Amount</TableCell>
                    <TableCell>Payment Method</TableCell>
                    <TableCell>Payment Status</TableCell>
                    <TableCell>Refund Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.slice(0, limit).map((payment, index) => (
                    <TableRow hover key={index}>
                      <TableCell>{`${payment.referenceId}`}</TableCell>
                      <TableCell>{`${payment.razorpayPaymentId}`}</TableCell>
                      <TableCell>
                        {payment.paymentDate === ""
                          ? ""
                          : payment.paymentMethod === "cash"
                          ? moment(payment.paymentDate).format("DD/MM/YYYY")
                          : moment
                              .unix(payment.paymentDate)
                              .format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>{`₹ ${payment.amount}`}</TableCell>
                      <TableCell>{payment.paymentMethod}</TableCell>
                      <TableCell>{payment.paymentStatus}</TableCell>
                      <TableCell>{payment.refundStatus}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <Backdrop className={classes.backdrop} open={openBackdrop}>
              <CircularProgressBar display={true} />
            </Backdrop>
          </Paper>
        </Box>{" "}
      </Container>{" "}
    </Page>
  );
};

Payments.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => state.paymentReducer;
export default connect(mapStateToProps)(Payments);
