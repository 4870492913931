import { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Dialog,
  useTheme,
  useMediaQuery,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import getInitials from "../../../../../utils/getInitials";
import SrProgressStepper from "./Progress";
import { connect } from "react-redux";
import UserInfo from "../view/UserInfo";
import RequestDetails from "./RequestDetails";
import EstimateDetails from "./EstimateDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Remarks from "./Remarks";
import Actions from "./Actions";
import EditIcon from "@material-ui/icons/Edit";
import app from "../../../../../services/firebase/FirebaseAppService";
import { getUserDetailsByUid } from "../../../../../services/ManageUsers/ManageUsersService";
import Feedback from "./Feedback";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    height: "document.body.scrollHeight",
  },
  main: {
    padding: theme.spacing(1, 4),
  },
  paper: {
    margin: theme.spacing(3),
    padding: theme.spacing(2),
    height: "100vh",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(6),
    },
  },
  wip: {
    alignContent: "center",
  },
  avatar2: {
    position: "relative",
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: "50%",
    padding: theme.spacing(0.5),
  },
  actionCard: {
    padding: theme.spacing(1),
    width: "100%",
  },
  actionsCardHeader: {
    paddingLeft: theme.spacing(5),
  },
  breadcrumb: {
    margin: theme.spacing(1, 2),
  },
}));

function SrDetails(props) {
  let serviceRequestId = props.match.params.id;
  let userId = props.match.params.uid;

  const classes = useStyles();

  const [isMobileView, setIsMobileView] = useState(false);
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleResize = (str) => {
    console.log(str);
    if (window.innerWidth < fullScreen) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize("hook"));
    return function cleanup() {
      window.removeEventListener("resize", handleResize("cleanup"));
    };
  });

  const [serviceRequest, setServiceRequest] = useState({});

  /**
   * product
   */
  const [productName, setProductName] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [productModel, setProductModel] = useState("");
  const [productSerialNumber, setProductSerialNumber] = useState("");

  /**
   * address
   */
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");

  /**
   * service
   */
  const [serviceDate, ssetServiceDate] = useState("");
  const [serviceFromTime, setServiceFromTime] = useState("");
  const [serviceTillTime, setServiceTillTime] = useState("");
  const [serviceType, setServiceType] = useState("");

  const [userRemark, setUserRemark] = useState("");

  const [minCharges, setMinCharges] = useState(0);

  const [initials, setInitials] = useState("");
  const [userName, setUserName] = useState("");
  const [userType, setUserType] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");

  const [requestStatus, setRequestStatus] = useState("Estimate to be sent");

  useEffect(() => {
    console.log("hook called");
    async function fetchData() {
      app
        .firestore()
        .collection("ServiceRequests")
        .doc(userId)
        .collection("requestsList")
        .doc(serviceRequestId)
        .onSnapshot((snapshot) => {
          setServiceRequest(snapshot.data());
          setServiceDetails(snapshot.data());
          setProductDetails(snapshot.data());
          setAddressDetails(snapshot.data());
          fetchUserData(snapshot.data().uid);
          if (
            snapshot.data().serviceRequestStatus === "Work Completed" ||
            snapshot.data().serviceRequestStatus === "Cancelled By User" ||
            snapshot.data().serviceRequestStatus === "Cancelled By Admin" ||
            snapshot.data().serviceRequestStatus === "Cancelled By Engineer"
          ) {
            setRequestStatus(
              `${snapshot.data().serviceRequestStatus} & ${
                snapshot.data().paymentStatus
              }`
            );
          } else {
            setRequestStatus(snapshot.data().serviceRequestStatus);
          }
        });
    }
    fetchData();

    async function fetchUserData(uid) {
      const result = await getUserDetailsByUid(uid);
      if (result.status === "success") {
        setUserType(result.docData.userType);
        setUserName(result.docData.firstName + " " + result.docData.lastName);
        setUserEmail(result.docData.email);
        setUserPhoneNumber(result.docData.phoneNumber);
        setInitials(
          getInitials(result.docData.firstName + " " + result.docData.lastName)
        );
      }
    }

    function setProductDetails(data) {
      console.log(data.serviceRequest);
      const mProductName = _.isUndefined(data.serviceRequest)
        ? "N/A"
        : data.serviceRequest.productDetails.productName;
      setProductName(mProductName);
      const mProductCategory = _.isUndefined(data.serviceRequest)
        ? "N/A"
        : data.serviceRequest.productDetails.productCategory;
      setProductCategory(mProductCategory);
      const mProductBrand = _.isUndefined(data.serviceRequest)
        ? "N/A"
        : data.serviceRequest.productDetails.productBrand;
      setProductBrand(mProductBrand);
      const mProductModel = _.isUndefined(data.serviceRequest)
        ? "N/A"
        : data.serviceRequest.productDetails.productModel;
      setProductModel(mProductModel);
      const mProductSerialNumber = _.isUndefined(data.serviceRequest)
        ? "N/A"
        : data.serviceRequest.productDetails.productSerialNumber;
      setProductSerialNumber(mProductSerialNumber);
      const mMinCharges = _.isUndefined(data.serviceRequest)
        ? "N/A"
        : data.serviceRequest.productDetails.minimumCharges;
      setMinCharges(mMinCharges);
    }

    function setServiceDetails(data) {
      const mServiceDate = _.isUndefined(data.serviceRequest)
        ? "N/A"
        : _.isUndefined(data.serviceRequest.serviceDetails.rescheduledDate)
        ? data.serviceRequest.serviceDetails.selectedDate
        : data.serviceRequest.serviceDetails.rescheduledDate;
      ssetServiceDate(mServiceDate);
      _.isUndefined(data.serviceRequest)
        ? setDateAndTimeColor("")
        : _.isUndefined(data.serviceRequest.serviceDetails.rescheduledDate)
        ? setDateAndTimeColor("")
        : setDateAndTimeColor("crimson");
      const mServicefromTIme = _.isUndefined(data.serviceRequest)
        ? "N/A"
        : _.isUndefined(data.serviceRequest.serviceDetails.rescheduledFromTime)
        ? data.serviceRequest.serviceDetails.serviceFromTime
        : data.serviceRequest.serviceDetails.rescheduledFromTime;
      setServiceFromTime(mServicefromTIme);
      const mServiceTillTIme = _.isUndefined(data.serviceRequest)
        ? "N/A"
        : _.isUndefined(data.serviceRequest.serviceDetails.rescheduledTillTime)
        ? data.serviceRequest.serviceDetails.serviceTillTime
        : data.serviceRequest.serviceDetails.rescheduledTillTime;
      setServiceTillTime(mServiceTillTIme);
      const mServiceType = _.isUndefined(data.serviceRequest)
        ? "N/A"
        : data.serviceRequest.serviceDetails.serviceType;
      setServiceType(mServiceType);
      const mUserRemark = _.isUndefined(data.serviceRequest)
        ? "N/A"
        : data.serviceRequest.serviceDetails.userRemarks;
      setUserRemark(mUserRemark);
    }

    function setAddressDetails(data) {
      const mAddresLine1 = _.isUndefined(data.serviceRequest)
        ? "N/A"
        : data.serviceRequest.addressDetails.addressLine1;
      setAddressLine1(mAddresLine1);
      const mAddresLine2 = _.isUndefined(data.serviceRequest)
        ? "N/A"
        : data.serviceRequest.addressDetails.addressLine2;
      setAddressLine2(mAddresLine2);
      const mCity = _.isUndefined(data.serviceRequest)
        ? "N/A"
        : data.serviceRequest.addressDetails.city;
      setCity(mCity);
      const mState = _.isUndefined(data.serviceRequest)
        ? "N/A"
        : data.serviceRequest.addressDetails.state;
      setState(mState);
      const mPinCode = _.isUndefined(data.serviceRequest)
        ? "N/A"
        : data.serviceRequest.addressDetails.pinCode;
      setPinCode(mPinCode);
    }
  }, [serviceRequestId, userId]);

  const [dateAndTimeColor, setDateAndTimeColor] = useState();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Container component="main" className={classes.main} maxWidth="lg">
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
          <Link>Service Requests</Link>
          <Typography color="textPrimary">
            {serviceRequest ? serviceRequest.referenceId : ""}
          </Typography>
        </Breadcrumbs>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: "none" }}>
            <Card>
              <CardContent>
                <SrProgressStepper
                  status={requestStatus}
                  docId={serviceRequestId}
                  uid={userId}
                />
              </CardContent>
            </Card>
          </Grid>
          <UserInfo
            docId={serviceRequest.docId}
            referenceId={serviceRequest.referenceId}
            uid={userId}
            requestStatus={requestStatus}
            userName={userName}
            userEmail={userEmail}
            initials={initials}
            serviceRequest={serviceRequest}
            userPhoneNumber={userPhoneNumber}
          />
          <RequestDetails
            productName={productName}
            productCategory={productCategory}
            productBrand={productBrand}
            productSerialNumber={productSerialNumber}
            productModel={productModel}
            minCharges={minCharges}
            addressLine1={addressLine1}
            addressLine2={addressLine2}
            city={city}
            state={state}
            pinCode={pinCode}
            serviceDate={serviceDate}
            serviceFromTime={serviceFromTime}
            serviceTillTime={serviceTillTime}
            serviceType={serviceType}
            userRemark={userRemark}
            dateAndTimeColor={dateAndTimeColor}
          />
          <Grid item xs={12} style={{ background: "#fff" }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  className={classes.heading}
                  style={{ fontWeight: "bold" }}
                >
                  Reported Issue
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12} style={{ background: "#fff" }}>
                  <Typography className={classes.heading}>
                    {userRemark}
                  </Typography>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Remarks
            uid={userId}
            docId={serviceRequestId}
            serviceRequest={serviceRequest}
          />
          {
            <div style={{ display: "none" }}>
              <EstimateDetails serviceRequest={serviceRequest} />
            </div>
          }
          {isMobileView || isMobile ? (
            <>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<EditIcon onClick={handleClickOpen} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      className={classes.heading}
                      style={{ fontWeight: "bold" }}
                    >
                      Estimate
                    </Typography>
                  </AccordionSummary>
                </Accordion>
              </Grid>
              <Dialog
                fullScreen={fullScreen}
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth="md"
              >
                <h2
                  style={{
                    margin: 0,
                    paddingLeft: 30,
                    paddingTop: 10,
                    backgroundColor: "#3f51b5",
                    height: 100,
                    paddingBottom: 10,
                    color: "#fff",
                  }}
                >
                  Estimate
                </h2>
                <EstimateDetails serviceRequest={serviceRequest} />
              </Dialog>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      className={classes.heading}
                      style={{ fontWeight: "bold" }}
                    >
                      Estimate
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <EstimateDetails serviceRequest={serviceRequest} />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Card className={classes.actionCard}>
              <Typography
                className={classes.heading}
                style={{ paddingLeft: "8px", fontWeight: "bold" }}
              >
                Actions
              </Typography>
              <Actions
                uid={userId}
                docId={serviceRequestId}
                userType={userType}
                serviceRequest={serviceRequest}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.actionCard}>
              <Typography
                className={classes.heading}
                style={{ paddingLeft: "8px", fontWeight: "bold" }}
              >
                Feedback
              </Typography>
              <Feedback
                uid={userId}
                docId={serviceRequestId}
                serviceRequest={serviceRequest}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(SrDetails);
