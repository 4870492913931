export const fillPaymentDetails = (paymentDetails) =>{
    return{
        type: 'complete/paymentDetails',
        payload: paymentDetails
    }
}

export const emptyPaymentDetails = () =>{
    return{
        type: 'empty/paymentDetails',
        payload:null
    }
}