import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import store from "../../../../../redux/store/store";
import { fillServiceDetails } from "../../../../../redux/actions/servicerequest";

export default function ServiceDetails() {
  const [serviceType, setServiceType] = useState("");
  const [serviceFromTime, setServiceFromTime] = useState("");
  const [serviceTillTime, setServiceTillTime] = useState("");

  const [selectedDate, setSelectedDate] = React.useState(Date.now());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function handelServiceType(e) {
    e.preventDefault();
    setServiceType(e.target.value);
  }

  function handelServiceFromTime(time) {
    setServiceFromTime(time);
  }

  function handelServiceTillTime(time) {
    setServiceTillTime(time);
  }

  useEffect(() => {
    const serviceDetails = {
      serviceType,
      selectedDate,
      serviceFromTime,
      serviceTillTime,
    };
    console.log(serviceDetails);
    store.dispatch(fillServiceDetails(serviceDetails));
  }, [serviceType, selectedDate, serviceFromTime, serviceTillTime]);
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Service Details{" "}
      </Typography>{" "}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputLabel id="servicetype" required>
            Service Type{" "}
          </InputLabel>{" "}
          <Select
            fullWidth
            labelId="servicetype"
            id="servicetype"
            value={serviceType}
            onChange={handelServiceType}
            placeHolder="serviceType"
          >
            <MenuItem value="Onsite">Onsite </MenuItem>{" "}
            <MenuItem value="Pickup">Pickup </MenuItem>{" "}
          </Select>{" "}
        </Grid>{" "}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={12} sm={6}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Date picker dialog"
                format="DD/MM/YYYY"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Time picker"
              value={serviceFromTime}
              onChange={handelServiceFromTime}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Time picker"
              value={serviceTillTime}
              onChange={handelServiceTillTime}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>{" "}
    </React.Fragment>
  );
}
