import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  makeStyles,
  TextField,
  Button,
  Divider,
  Box,
  Avatar,
  Typography,
  Paper,
  FormControl,
  IconButton,
  Checkbox,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem,
  ListItemText,
} from "@material-ui/core";
import Page from "../../support/page/Page";
import Profile from "../profile/Profile";
import {
  getDocuments,
  getUserProfile,
} from "../../../services/account/ProfileService";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PersonIcon from "@material-ui/icons/Person";
import HomeIcon from "@material-ui/icons/Home";
import BusinessIcon from "@material-ui/icons/Business";
import _, { isEmpty } from "lodash";
import { putUserProfile } from "../../../services/account/ProfileService";
import store from "../../../redux/store/store";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { isMobile } from "react-device-detect";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Corporate from "../corporate/Corporate";
import getEngineerProductCatagories from "../../../services/category";
import { isConstructorDeclaration } from "typescript";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
  },
  mainPaper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  avatar: {
    position: "relative",
    color: theme.palette.secondary.main,
    padding: theme.spacing(0.5),
    fontSize: theme.spacing(7),
  },
  typography: {},
  paper: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      height: window.scrollHeight,
    },
    width: "100vw",
  },

  avatar2: {
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    margin: "3%",
  },
  title: {
    margin: "2%",
    width: "95%",
  },
  // formControl: {
  //   margin: theme.spacing(1),
  //   minWidth: 120,
  // },
  header: {
    margin: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function UserDetails({ match }) {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  let params = match.params;
  let props = {};
  props.uid = params.uid;
  props.userType = params.userType;

  const classes = useStyles();

  const [fName, setFname] = useState();
  const [lName, setLname] = useState();

  const [userType, setUserType] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("Maharashtra");
  const [pinCode, setPinCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");

  const [grid, setGrid] = useState("");

  const handleFirstNameChange = (e) => {
    setFname(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setLname(e.target.value);
  };
  const handleUserphoneChange = (e) => {
    setUserPhone(e.target.value);
  };

  const handleAddressline1Change = (e) => {
    setAddressLine1(e.target.value);
  };
  const handleAddressline2change = (e) => {
    setAddressLine2(e.target.value);
  };
  const handleCityChange = (e) => {
    setCity(e.target.value);
  };
  const handleStateChange = (e) => {
    setState(e.target.value);
  };
  const handlePincodeChange = (e) => {
    setPinCode(e.target.value);
  };

  const handleCompanynameChange = (e) => {
    setCompanyName(e.target.value);
  };
  const handleCompanyaddressChange = (e) => {
    setCompanyAddress(e.target.value);
  };
  const handleGstnumberChange = (e) => {
    setGstNumber(e.target.value);
  };

  const [businessProofFile, setBusinessProofFile] = useState();
  const [bankStatementFile, setBankStatementFile] = useState();
  const [addressProofFile, setAddressProofFile] = useState();
  const [identityProof, setIdentityProof] = useState();
  const [addressProof, setAddressProof] = useState();

  const [businessProofFileName, setBusinessProofFileName] = useState();
  const [bankStatementFileName, setBankStatementFileName] = useState();
  const [addressProofFileName, setAddressProofFileName] = useState();
  const [identityProofFileName, setIdentityProofFileName] = useState();
  const [addressProofName, setAddressProofName] = useState();

  // Docs Verification
  const [companyDetailsVerified, setCompanyDetailsVerified] = useState(false);

  const [businessProofVerified, setBusinessProofVerified] = useState(false);
  const [bankStatementVerified, setBankStatementVerified] = useState(false);
  const [addressProofVerified, setAddressProofVerified] = useState(false);
  const [indIdentityProofVerified, setIndIdentityProofVerified] = useState(
    false
  );
  const [indAddressProofVerified, setIndAddressProofVerified] = useState(false);

  const handleBusinessProofVerifiedChange = (e) => {
    let checkbox = e.target.checked;
    setBusinessProofVerified(checkbox);
  };
  const handleBankStatementVerifiedChange = (e) => {
    let checkbox = e.target.checked;
    setBankStatementVerified(checkbox);
  };
  const handleAddressProofVerifiedChange = (e) => {
    let checkbox = e.target.checked;
    setAddressProofVerified(checkbox);
  };
  const handleIndIdentityProofVerifiedChange = (e) => {
    let checkbox = e.target.checked;
    setIndIdentityProofVerified(checkbox);
  };
  const handleIndAddressProofVerifiedChange = (e) => {
    let checkbox = e.target.checked;
    setIndAddressProofVerified(checkbox);
  };
  const handleCompanyDetailsVerifiedChange = (e) => {
    let checkbox = e.target.checked;
    setCompanyDetailsVerified(checkbox);
  };

  useEffect(() => {
    async function fetchProfile() {
      const docs = await getDocuments(props.uid);
      const categories = await getEngineerProductCatagories();
      if (categories.status === "success") {
        console.log("users " + categories.docs);
        setCategories([...categories.docs]);
      }
      if (docs.status === "success") {
        setBusinessProofFile(docs.BusinessProofUrl);
        setBankStatementFile(docs.BankStatementUrl);
        setAddressProofFile(docs.AddressProofUrl);
        setBusinessProofFileName(docs.BusinessProofName);
        setBankStatementFileName(docs.BankStatementName);
        setAddressProofFileName(docs.AddressProofName);

        setIdentityProof(docs.IndIdentityProof);
        setAddressProof(docs.IndAddressProof);
        setIdentityProofFileName(docs.IndIdentityProofName);
        setAddressProofName(docs.IndAddressProofName);
      }

      const result = await getUserProfile(props.uid);
      console.log(result.profile.docData);
      if (result.profile) {
        const addressDetails = result.profile.docData.addressDetails;
        const documentsVerificationDetails =
          result.profile.docData.documentsVerification;
        if (addressDetails) {
          setAddressLine1(addressDetails.addressLine1);
          setAddressLine2(addressDetails.addressLine2);
          setCity(addressDetails.city);
          setState(addressDetails.state);
          setPinCode(addressDetails.pinCode);
        }
        if (result.profile.docData.phoneNumber) {
          setPhoneNumber(result.profile.docData.phoneNumber);
        }
        if (documentsVerificationDetails) {
          console.log(documentsVerificationDetails);
          setBusinessProofVerified(
            documentsVerificationDetails.businessProofVerified
          );
          setBankStatementVerified(
            documentsVerificationDetails.bankStatementVerified
          );
          setAddressProofVerified(
            documentsVerificationDetails.addressProofVerified
          );
          setIndAddressProofVerified(
            documentsVerificationDetails.indAddressProofVerified
          );
          setIndIdentityProofVerified(
            documentsVerificationDetails.indIdentityProofVerified
          );
          setCompanyDetailsVerified(
            documentsVerificationDetails.companyDetailsVerified
          );
        }
      }

      _.isUndefined(result.profile.docData.firstName)
        ? setFname("")
        : setFname(result.profile.docData.firstName);

      _.isUndefined(result.profile.docData.lastName)
        ? setLname("")
        : setLname(result.profile.docData.lastName);

      _.isUndefined(result.profile.docData.email)
        ? setUserEmail("")
        : setUserEmail(result.profile.docData.email);

      _.isUndefined(result.profile.docData.phoneNumber)
        ? setUserPhone("")
        : setUserPhone(result.profile.docData.phoneNumber);

      _.isUndefined(result.profile.docData.category)
        ? setCategory([])
        : setCategory(result.profile.docData.category);

      _.isUndefined(result.profile.docData.userType)
        ? setUserType("")
        : setUserType(result.profile.docData.userType);

      _.isUndefined(
        result.profile.docData.companyDetails &&
          result.profile.docData.companyDetails
      )
        ? setCompanyAddress("")
        : setCompanyAddress(
            result.profile.docData.companyDetails.companyAddress
          );

      _.isUndefined(
        result.profile.docData.companyDetails &&
          result.profile.docData.companyDetails
      )
        ? setCompanyName("")
        : setCompanyName(result.profile.docData.companyDetails.companyName);

      _.isUndefined(
        result.profile.docData.companyDetails &&
          result.profile.docData.companyDetails
      )
        ? setGstNumber("")
        : setGstNumber(result.profile.docData.companyDetails.gstNumber);
    }
    fetchProfile();
    console.log("test");
  }, [props.uid]);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("");

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);

  const handleClickOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  async function handleClick(e) {
    e.preventDefault();
    const userAddressDetails = {
      addressLine1,
      addressLine2,
      city,
      state,
      pinCode,
      phoneNumber,
      companyDetails: {
        companyAddress,
        companyName,
        gstNumber,
      },
    };
    const documentsVerification = {
      businessProofVerified: businessProofVerified,
      bankStatementVerified: bankStatementVerified,
      addressProofVerified: addressProofVerified,
      indIdentityProofVerified: indIdentityProofVerified,
      indAddressProofVerified: indAddressProofVerified,
      companyDetailsVerified: companyDetailsVerified,
    };
    const result = await putUserProfile(
      userAddressDetails,
      props.uid,
      fName,
      lName,
      category,
      userPhone,
      documentsVerification
    );
    console.log(result);
    if (result.status === "success") {
      setSnackBarMsg("Profile updated Puccessfully");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
    } else {
      setSnackBarMsg("Unable to update Profile");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  }

  const [isMobileView, setIsMobileView] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs" || "767"));
  const handleResize = () => {
    if (window.innerWidth <= fullScreen) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <>
      <Page className={classes.root}>
        <Container maxWidth={false}>
          <Box mt={1}>
            <Paper
              className={classes.mainPaper}
              style={{ paddingBottom: isMobile || isMobileView ? "15%" : "4%" }}
            >
              <Typography variant="h4" gutterBottom className={classes.header}>
                {userType} Profile
              </Typography>

              <Grid container xs={12} sm={12}>
                <Grid item xs={12} sm={6} style={{ background: "#fff" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AccountCircleIcon className={classes.avatar2} />
                    <Typography variant="h5" style={{ color: "grey" }}>
                      User Details
                    </Typography>
                  </div>

                  <TextField
                    fullWidth
                    name="fname"
                    onChange={handleFirstNameChange}
                    required
                    value={fName}
                    size="small"
                    label="First Name"
                    variant="outlined"
                    className={classes.title}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    fullWidth
                    name="lname"
                    onChange={handleLastNameChange}
                    required
                    value={lName}
                    size="small"
                    label="Last Name"
                    variant="outlined"
                    className={classes.title}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    fullWidth
                    name="usertype"
                    required
                    value={userType}
                    size="small"
                    label="User Type"
                    variant="outlined"
                    className={classes.title}
                  />
                  <FormControl
                    variant="outlined"
                    fullWidth
                    size="small"
                    className={classes.formControl}
                    style={{
                      display:
                        userType === "Service Engineer" ? "block" : "none",
                      margin: isMobile || isMobileView ? 4 : 10,
                      width: "95%",
                    }}
                    disabled={userType === "Service Engineer" ? false : true}
                  >
                    <InputLabel id="demo-mutiple-checkbox-label">
                      Category
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple={true}
                      label="Category"
                      value={category || []}
                      onChange={(e) => handleChange(e)}
                      variant="outlined"
                      style={{
                        display:
                          userType === "Service Engineer" ? "block" : "none",
                      }}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      disabled={userType === "Service Engineer" ? false : true}
                    >
                      {categories.map((mCategory) => (
                        <MenuItem
                          key={mCategory.docData.categoryName}
                          value={mCategory.docData.categoryName}
                        >
                          <Checkbox
                            color="primary"
                            checked={
                              category.indexOf(mCategory.docData.categoryName) >
                              -1
                            }
                          />
                          <ListItemText
                            primary={mCategory.docData.categoryName}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    name="useremail"
                    required
                    disabled
                    value={userEmail}
                    size="small"
                    label="Email"
                    variant="outlined"
                    className={classes.title}
                  />
                  <TextField
                    fullWidth
                    name="userphone"
                    onChange={handleUserphoneChange}
                    required
                    value={userPhone}
                    size="small"
                    label="Phone "
                    variant="outlined"
                    className={classes.title}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ background: "#fff" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <HomeIcon className={classes.avatar2} />
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{ color: "grey" }}
                    >
                      Address Details
                    </Typography>
                  </div>
                  <TextField
                    fullWidth
                    name="addressline1"
                    onChange={handleAddressline1Change}
                    required
                    value={addressLine1}
                    size="small"
                    label="Address Line 1"
                    variant="outlined"
                    className={classes.title}
                  />
                  <TextField
                    fullWidth
                    name="addressline2"
                    onChange={handleAddressline2change}
                    required
                    value={addressLine2}
                    size="small"
                    label="Address Line 2"
                    variant="outlined"
                    className={classes.title}
                  />
                  <TextField
                    fullWidth
                    name="city"
                    onChange={handleCityChange}
                    required
                    value={city}
                    size="small"
                    label="City"
                    variant="outlined"
                    className={classes.title}
                  />
                  <TextField
                    fullWidth
                    name="state"
                    onChange={handleStateChange}
                    required
                    value={state}
                    size="small"
                    label="State"
                    variant="outlined"
                    className={classes.title}
                  />
                  <TextField
                    fullWidth
                    name="pincode"
                    onChange={handlePincodeChange}
                    required
                    value={pinCode}
                    size="small"
                    label="Pincode"
                    variant="outlined"
                    className={classes.title}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{
                    background: "#fff",
                    display:
                      props.userType === "Admin User" ||
                      props.userType === "Service Engineer" ||
                      props.userType === "Super Admin"
                        ? "none"
                        : "block",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <BusinessIcon className={classes.avatar2} />
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{ color: "grey" }}
                    >
                      {"Company Details"}
                    </Typography>
                    <FormControlLabel
                      style={{ marginLeft: "5%" }}
                      control={
                        <Checkbox
                          checked={companyDetailsVerified}
                          color="primary"
                          inputProps={{
                            "aria-label": "secondary checkbox",
                          }}
                          onChange={handleCompanyDetailsVerifiedChange}
                        />
                      }
                      label="Verified"
                    />
                  </div>
                  <TextField
                    fullWidth
                    name="companyname"
                    onChange={handleCompanynameChange}
                    required
                    value={companyName}
                    size="small"
                    label="Company Name"
                    variant="outlined"
                    className={classes.title}
                    style={{ marginLeft: "2%" }}
                  />
                  <TextField
                    fullWidth
                    name="gstnumber"
                    onChange={handleGstnumberChange}
                    required
                    value={gstNumber}
                    size="small"
                    label="Gst Number "
                    variant="outlined"
                    className={classes.title}
                  />
                  <TextField
                    fullWidth
                    name="companyaddress"
                    onChange={handleCompanyaddressChange}
                    required
                    value={companyAddress}
                    size="small"
                    label="Company Address"
                    variant="outlined"
                    className={classes.title}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{
                    background: "#fff",
                    display:
                      props.userType === "Admin User" ||
                      props.userType === "Service Engineer" ||
                      props.userType === "Super Admin"
                        ? "none"
                        : "block",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PictureAsPdfIcon className={classes.avatar2} />{" "}
                    <Typography
                      variant="h5"
                      component="h2"
                      style={{ color: "grey" }}
                    >
                      {(!_.isUndefined(businessProofFile) &&
                        !_.isUndefined(identityProof)) ||
                      _.isUndefined(identityProof)
                        ? "Company Documents"
                        : "Individual User Documents"}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={12} sm={4}>
                      <Paper
                        style={{
                          marginLeft: "16px",
                          marginTop: "8px",
                          marginBottom: "8px",
                          paddingLeft: "8px",
                          paddingTop: "1px",
                          textAlign: "center",
                          paddingBottom: "1px",
                        }}
                      >
                        <h3>
                          <Checkbox
                            checked={businessProofVerified}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                            onChange={handleBusinessProofVerifiedChange}
                          />
                          Business Proof <br />
                          <a
                            href={businessProofFile}
                            target="blank"
                            style={{ fontWeight: "normal" }}
                          >
                            {businessProofFileName}
                          </a>
                        </h3>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Paper
                        style={{
                          marginLeft: "16px",
                          marginTop: "8px",
                          paddingLeft: "8px",
                          paddingTop: "1px",
                          textAlign: "center",
                          paddingBottom: "1px",
                        }}
                      >
                        <h3>
                          <Checkbox
                            defaultChecked
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                            checked={bankStatementVerified}
                            onChange={handleBankStatementVerifiedChange}
                          />
                          Bank Statement <br />
                          <a
                            href={bankStatementFile}
                            target="blank"
                            style={{ fontWeight: "normal" }}
                          >
                            {bankStatementFileName}
                          </a>
                        </h3>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Paper
                        style={{
                          marginLeft: "16px",
                          marginTop: "8px",
                          paddingLeft: "8px",
                          paddingTop: "1px",
                          textAlign: "center",
                          paddingBottom: "1px",
                        }}
                      >
                        <h3>
                          <Checkbox
                            defaultChecked
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                            checked={addressProofVerified}
                            onChange={handleAddressProofVerifiedChange}
                          />
                          Address Proof <br />
                          <a
                            href={addressProofFile}
                            target="blank"
                            style={{ fontWeight: "normal" }}
                          >
                            {addressProofFileName}
                          </a>
                        </h3>
                      </Paper>
                    </Grid>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={12} sm={4}>
                      <Paper
                        style={{
                          marginLeft: "16px",
                          marginTop: "8px",
                          paddingLeft: "8px",
                          paddingTop: "1px",
                          textAlign: "center",
                          paddingBottom: "1px",
                        }}
                      >
                        <h3>
                          <Checkbox
                            defaultChecked
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                            checked={indIdentityProofVerified}
                            onChange={handleIndIdentityProofVerifiedChange}
                          />
                          Identity Proof <br />
                          <a
                            href={identityProof}
                            target="blank"
                            style={{ fontWeight: "normal" }}
                          >
                            {identityProofFileName}
                          </a>
                        </h3>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Paper
                        style={{
                          marginLeft: "16px",
                          marginTop: "8px",
                          paddingLeft: "8px",
                          paddingTop: "1px",
                          textAlign: "center",
                          paddingBottom: "1px",
                        }}
                      >
                        <h3>
                          <Checkbox
                            defaultChecked
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                            checked={indAddressProofVerified}
                            onChange={handleIndAddressProofVerifiedChange}
                          />
                          Address Proof <br />
                          <a
                            href={addressProof}
                            target="blank"
                            style={{ fontWeight: "normal" }}
                          >
                            {addressProofName}
                          </a>
                        </h3>
                      </Paper>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                style={{
                  float: "right",
                  marginTop: "1%",
                }}
                onClick={handleClick}
              >
                Save Details
              </Button>
            </Paper>
          </Box>{" "}
        </Container>{" "}
      </Page>

      <Container maxWidth={false}>
        <Paper
          style={{
            paddingTop: "1px",
            textAlign: "center",
            paddingBottom: "1px",
            display: props.userType === "Corporate" ? "block" : "none",
          }}
        >
          <Corporate uid={props.uid} />
        </Paper>
      </Container>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
