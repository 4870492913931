import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import store from "../../../../../redux/store/store";
import { fillAddressDetails } from "../../../../../redux/actions/servicerequest";

export default function AddressDetails() {
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");

  function handelAddressLineOne(e) {
    e.preventDefault();
    setAddressLine1(e.target.value);
  }
  function handelAddressLineTwo(e) {
    e.preventDefault();
    setAddressLine2(e.target.value);
  }
  function handelCityChange(e) {
    e.preventDefault();
    setCity(e.target.value);
  }
  function handelStateChange(e) {
    e.preventDefault();
    setState(e.target.value);
  }
  function handelPincodeChange(e) {
    e.preventDefault();
    setPinCode(e.target.value);
  }

  useEffect(() => {
    const addressDetails = {
      addressLine1,
      addressLine2,
      city,
      state,
      pinCode,
    };
    console.log(addressDetails);
    store.dispatch(fillAddressDetails(addressDetails));
  }, [addressLine1, addressLine2, city, state, pinCode]);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Address Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="addressline1"
            label="Address Line 1"
            name="addressline1"
            autoComplete="addressline1"
            autoFocus
            value={addressLine1}
            onChange={handelAddressLineOne}
            size="small"
          />{" "}
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="addressline2"
            label="Address Line 2"
            name="addressline2"
            value={addressLine2}
            onChange={handelAddressLineTwo}
            autoComplete="addressline2"
            autoFocus
            size="small"
          />{" "}
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="city"
            label="City"
            name="city"
            autoComplete="city"
            autoFocus
            value={city}
            onChange={handelCityChange}
            size="small"
          />{" "}
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="state"
            label="State"
            name="state"
            autoComplete="state"
            autoFocus
            value={state}
            onChange={handelStateChange}
            size="small"
          />{" "}
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="pincode"
            label="Pin Code"
            name="pincode"
            autoComplete="pincode"
            autoFocus
            value={pinCode}
            onChange={handelPincodeChange}
            size="small"
          />{" "}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
