import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useEffect, useState } from "react";
import { postRemark } from "../../../../../services/service-request/remarks";
import "./Remarks.css";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  cell: {
    width: theme.spacing(18),
    margin: theme.spacing(1),
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  container: {
    border: "2px solid #dedede",
    backgroundColor: "",
    borderRadius: "5px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  timeRight: {
    float: "right",
    color: "#aaa",
    margin: theme.spacing(0.5),
  },
  timeLeft: {
    float: "left",
    color: "#aaa",
    margin: theme.spacing(0.5),
  },

  talkBubble: {
    margin: theme.spacing(1),
    display: "inline-block",
    position: "relative",
    width: "100%",
    height: "auto",
    backgroundColor: "lightblue",
  },
  remarksCardActions: {
    margin: theme.spacing(2),
    float: "right",
  },
}));

const Remarks = (props) => {
  const classes = useStyles();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [remark, setRemark] = useState();
  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
  };
  const [remarks, setRemarks] = useState([]);
  const [adminRemarks, setAdminRemarks] = useState([]);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("");

  const handleClickOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleSubmitRemark = async (e) => {
    e.preventDefault();
    setAdminRemarks([
      ...adminRemarks,
      {
        remarkBy: "SuperAdmin" || "ServiceEngineer",
        remarks: remark,
        date: moment().format(),
      },
    ]);
    const sendRemark = await postRemark(
      [
        ...adminRemarks,
        {
          remarkBy: "SuperAdmin" || "ServiceEngineer",
          remarks: remark,
          date: moment().format(),
        },
      ],
      props.docId,
      props.uid
    );
    console.log(sendRemark);
    if (sendRemark.status === "success") {
      setSnackBarMsg("Remark sent successfully");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
      setRemark("");
    } else {
      setSnackBarMsg("Unable to send Remark");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (props && props.serviceRequest) {
        const userRemarks = props.serviceRequest.userRemarks
          ? props.serviceRequest.userRemarks
          : [];
        const adminRemarks = props.serviceRequest.adminRemarks
          ? props.serviceRequest.adminRemarks
          : [];
        const engineerRemarks = props.serviceRequest.engineerRemarks
          ? props.serviceRequest.engineerRemarks
          : [];
        const remarks = _.sortBy(
          [...userRemarks, ...adminRemarks, ...engineerRemarks],
          ["date"]
        );
        setRemarks([...remarks]);
        setAdminRemarks(adminRemarks);
      }
    }
    fetchData();
  }, [props]);

  const [sendRemarkBtnDisable, setSendRemarkBtnDisabled] = useState(false);

  useEffect(() => {
    _.isEmpty(remark)
      ? setSendRemarkBtnDisabled(true)
      : setSendRemarkBtnDisabled(false);
  }, [remark]);

  return (
    <>
      <Grid item xs={12} style={{ background: "#fff" }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              className={classes.heading}
              style={{ fontWeight: "bold" }}
            >
              Remarks
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item xs={12} style={{ background: "#fff" }}>
              {remarks.map((remark, index) => (
                <>
                  <Grid item xs={12}>
                    <div
                      className={
                        remark.remarkBy !== "SuperAdmin" &&
                        remark.remarkBy !== "ServiceEngineer"
                          ? "talk-bubble tri-right left-top"
                          : "talk-bubble tri-right btm-right"
                      }
                      style={
                        remark.remarkBy !== "SuperAdmin" &&
                        remark.remarkBy !== "ServiceEngineer"
                          ? { width: "80%", float: "left" }
                          : { width: "80%", float: "right" }
                      }
                    >
                      <div class="talktext">
                        <p style={{ margin: "1%" }}>{remark.remarks}</p>
                        <div className={classes.timeRight}>
                          {`${remark.remarkBy} `}

                          {`${moment(remark.date).format(
                            "DD/MM/YYYY HH:mm A"
                          )} `}
                        </div>
                      </div>
                    </div>
                  </Grid>
                </>
              ))}
              <TextField
                style={{ textAlign: "left", marginTop: "2%" }}
                label="Remarks"
                multiline
                rows={2}
                fullWidth
                variant="outlined"
                value={remark}
                onChange={handleRemarkChange}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "2%", float: "right" }}
                onClick={(e) => handleSubmitRemark(e)}
                disabled={sendRemarkBtnDisable}
              >
                Send Remark
              </Button>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (state) => state.authReducer;
export default connect(mapStateToProps)(Remarks);
