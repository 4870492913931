import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import getAllProductCategories from "../../../../../services/product/index";
import store from "../../../../../redux/store/store";
import { fillProductDetails } from "../../../../../redux/actions/servicerequest";

export default function ProductDetails(props) {
  const [productName, setProductName] = useState("");
  const [productModel, setProductModel] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [productSerialNumber, setProductSerialNumber] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productCategories, setProductCategories] = useState([]);

  function handleCategoryChange(e) {
    e.preventDefault();
    setProductCategory(e.target.value);
  }
  function productNameChange(e) {
    e.preventDefault();
    setProductName(e.target.value);
  }

  function handleProductModelChange(e) {
    e.preventDefault();
    setProductModel(e.target.value);
  }
  function handleProductBrandChange(e) {
    e.preventDefault();
    setProductBrand(e.target.value);
  }
  function handleProductSerialNumberChange(e) {
    e.preventDefault();
    setProductSerialNumber(e.target.value);
  }

  useEffect(() => {
    console.log("222");
    getCategoriesData();
  }, []);

  async function getCategoriesData() {
    const result = await getAllProductCategories();
    if (result.status === "success") {
      console.log(result.docs);
      setProductCategories([...result.docs]);
      console.log(productCategories);
    }
  }

  useEffect(() => {
    console.log(productCategory + productModel + productName);
    const productDetails = {
      productCategory,
      productModel,
      productName,
      productBrand,
      productSerialNumber,
    };
    console.log(productDetails);
    store.dispatch(fillProductDetails(productDetails));
  }, [
    productCategory,
    productModel,
    productName,
    productBrand,
    productSerialNumber,
  ]);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Product Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputLabel id="demo-simple-select-label" required>
            Product Categories
          </InputLabel>
          <Select
            fullWidth
            labelId="demo-simple-select-label"
            id="productcategory"
            onChange={handleCategoryChange}
          >
            {productCategories.map((doc, index) => (
              <MenuItem value={doc.docData.categoryName}>
                {doc.docData.categoryName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="productname"
            name="productname"
            label="Product Name"
            fullWidth
            autoComplete="productname"
            variant="standard"
            value={productName}
            onChange={productNameChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="productmodel"
            name="productbmodel"
            label="Product Model"
            fullWidth
            autoComplete="product-model"
            variant="standard"
            value={productModel}
            onChange={handleProductModelChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="productbrand"
            name="productbrand"
            label="Product Brand"
            fullWidth
            autoComplete="product-brand"
            variant="standard"
            value={productBrand}
            onChange={handleProductBrandChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="productserialNo"
            name="productserialNo"
            label="Product Serial Number"
            fullWidth
            autoComplete="product serial-number"
            variant="standard"
            value={productSerialNumber}
            onChange={handleProductSerialNumberChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
