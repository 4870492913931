import app from "../firebase/FirebaseAppService";
import store from "../../redux/store/store";
import moment from "moment";

const db = app.firestore();
const collectionName = "ServiceRequests";

export async function postRemark(remarks, docId, uid) {
    let docRef;
    const state = store.getState();
    try {
        if (state.authReducer.claims === "admin") {
            docRef = db.collection(collectionName).doc(uid).collection("requestsList").doc(docId);
            console.log(remarks);
            return await docRef.set({
                adminRemarks: remarks,
            }, {
                merge: true
            }).then(() => {
                return {
                    status: "success",
                    docId: docRef.id,
                    docData: {
                        remarks,
                        date: moment().format(),
                    }
                };
            });
        } else if (state.authReducer.claims === "serviceEngineer") {
            docRef = db.collection(collectionName).doc(uid).collection("requestsList").doc(docId);
            return await docRef.set({
                engineerRemarks: remarks,
            }, {
                merge: true
            }).then(() => {
                return {
                    status: "success",
                    docId: docRef.id,
                    docData: {
                        remarks,
                        date: moment().format(),
                    }
                };
            });
        }
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
            docId: docRef.id,
            docData: {

            }
        };
    }
}