import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import verifyEmail from "../../services/auth/VerifyEmailService";
import GlassCard from "../../utils/glassCard";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function VerifyEmail(props) {
  const classes = useStyles();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [appUser, setAppUser] = useState(props.user);

  useEffect(() => {
    setAppUser(props.user);
  }, [props.user]);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("");

  const handleClickOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  async function handleClick(e) {
    e.preventDefault();
    const VerifyEmail = await verifyEmail();
    if (VerifyEmail.status === "success") {
      setSnackBarMsg("Verification Email sent Successfully");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
    } else {
      setSnackBarMsg("Oops..Verification Email not sent");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  }

  return (
    <div className={classes.mainDiv}>
      <Container
        className={classes.container}
        style={{ paddingTop: "7%" }}
        component="main"
        maxWidth="xs"
      >
        <CssBaseline />
        <GlassCard>
          <div style={{ padding: "2ch" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>{" "}
              <Typography component="h1" variant="h5">
                Verify Email
              </Typography>{" "}
              <form className={classes.form} noValidate>
                <TextField
                  disabled
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={appUser.email}
                />{" "}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleClick}
                >
                  SEND VERIFICATION EMAIL{" "}
                </Button>{" "}
              </form>{" "}
            </div>{" "}
          </div>
        </GlassCard>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => state.authReducer;
export default connect(mapStateToProps)(VerifyEmail);
