import { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {
  Select,
  InputLabel,
  TableRow,
  TableBody,
  TableHead,
  Table,
  Chip,
  CardContent,
  useMediaQuery,
  useTheme,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  Fab,
  IconButton,
  Backdrop,
  ListSubheader,
  MenuItem,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import getAllUsers, {
  deleteUserByUid,
} from "../../../services/ManageUsers/ManageUsersService";
import { Card, TableCell } from "@material-ui/core";
import _ from "lodash";
import userSignUp from "../../../services/admin";
import { Link } from "react-router-dom";
import Page from "../../support/page/Page";
import validator from "validator";
import { FormHelperText } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Edit } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgressBar from "../../support/progress/CircularProgressBar";
import { isMobile } from "react-device-detect";
import getProductCatagories from "../../../services/category/index";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "85%",
    paddingBottom: theme.spacing(3),
  },
  // formControl: {
  //   margin: "1.5% 0",
  //   minWidth: "100%",
  // },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  paper1: {
    padding: theme.spacing(1),
    textAlign: "right",
    color: theme.palette.text.secondary,
  },
  toolbar: {
    marginLeft: theme.spacing(-2),
  },
  header: {
    margin: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: "50%",
    padding: theme.spacing(0.5),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function ManageUsers(className) {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userType, setUsertype] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const itemsPerPage = 5;
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);

  function handleEmailChange(e) {
    e.preventDefault();
    setEmail(e.target.value);
    validateEmail(e);
  }

  function handleFnameChange(e) {
    e.preventDefault();
    setFname(e.target.value);
    validateFname(e);
  }

  function handleLnameChnage(e) {
    e.preventDefault();
    setLname(e.target.value);
    validateLname(e);
  }

  function handleSelectChange(e) {
    setUsertype(e.target.value);
    validateSelect(e);
  }

  function handlePhoneChange(e) {
    setPhoneNumber(e.target.value);
    validatePhone(e);
  }

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("");

  const handleClickOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };
  async function handleClick(e) {
    e.preventDefault();
    handleClose();
    handleBackDropOpen();
    const result = await userSignUp(
      userType,
      email,
      fname,
      lname,
      phoneNumber,
      category
    );
    console.log(result);
    if (result.status === "success") {
      setUsers([
        ...users,
        {
          docData: {
            email: email,
            emailVerified: false,
            firstName: fname,
            lastName: lname,
            userType: userType,
            phoneNumber,
            category,
          },
          docId: result.userRecord.uid,
        },
      ]);
      handleBackDropClose();
      setSnackBarMsg("User Successfully Created");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
      setUsertype("");
      setEmail("");
      setFname("");
      setLname("");
      setPhoneNumber("");
    } else {
      handleBackDropClose();
      setSnackBarMsg("Unable to create User, Email Id might already exists");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  }

  async function deleteUser(e, uid, email) {
    e.preventDefault();
    handleBackDropOpen();
    const result = await deleteUserByUid(uid);
    console.log(result);
    if (result.status === "success") {
      const mUsers = _.filter([...users], function (o) {
        return o.docData.email !== email;
      });
      setUsers(mUsers);
      handleBackDropClose();
      setSnackBarMsg("User Deletion Success");
      setSnackBarSeverity("success");
      handleClickOpenSnackBar();
    } else {
      handleBackDropClose();
      setSnackBarMsg("Unable Deletion Failed");
      setSnackBarSeverity("error");
      handleClickOpenSnackBar();
    }
  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChane = (event, value) => {
    setPage(value);
  };

  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      handleBackDropOpen();
      const result = await getAllUsers();
      const categories = await getProductCatagories();
      if (categories.status === "success") {
        console.log("users " + categories.docs);
        setCategories([...categories.docs]);
      }
      if (result.status === "success") {
        setUsers([...result.docs]);
        console.log("users " + result);
        handleBackDropClose();
      } else {
        handleBackDropClose();
      }
    }
    fetchData();
  }, []);

  const [selectError, setSelectError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [fnameError, setFnameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const [btndisabled, setBtndisabled] = useState(true);

  const validateFname = (e) => {
    var fname = e.target.value;
    if (!fname.match(/^[A-Za-z]+$/) || _.isEmpty(fname)) {
      setFnameError("Enter valid Characters !");
      setBtndisabled(true);
    } else {
      setFnameError("");
    }
  };

  const validateLname = (e) => {
    var lname = e.target.value;
    if (!lname.match(/^[A-Za-z]+$/) || _.isEmpty(lname)) {
      setLnameError("Enter valid Characters !");
      setBtndisabled(true);
    } else {
      setLnameError("");
    }
  };

  const validateEmail = (e) => {
    var email = e.target.value;
    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Enter valid Email!");
      setBtndisabled(true);
    }
  };

  const validatePhone = (e) => {
    var phoneNumber = e.target.value;
    console.log(phoneNumber.length < 10);
    if (
      _.isEmpty(phoneNumber) ||
      phoneNumber.length < 10 ||
      phoneNumber.length > 13
    ) {
      setPhoneError("Enter valid Phone Number!");
      setBtndisabled(true);
    } else {
      setPhoneError("");
    }
  };

  console.log(category);

  const validateSelect = (e) => {
    var userType = e.target.value;
    if (_.isEmpty(userType)) {
      setSelectError("User Type Required");
      setBtndisabled(true);
    } else {
      setSelectError("");
    }
  };

  const validateEngineerCategory = (e) => {
    var engineerCategory = e.target.value;
    if (_.isEmpty(engineerCategory)) {
      setCategoryError("Service Engineer Product Category Required");
      setBtndisabled(true);
    } else {
      setCategoryError("");
    }
  };

  useEffect(() => {
    if (
      _.isEmpty(fnameError) &&
      _.isEmpty(lnameError) &&
      _.isEmpty(phoneError) &&
      _.isEmpty(emailError) &&
      !_.isEmpty(userType) &&
      !_.isEmpty(fname) &&
      !_.isEmpty(lname) &&
      !_.isEmpty(phoneNumber) &&
      validator.isEmail(email)
    ) {
      setBtndisabled(false);
    }
  }, [
    fname,
    lname,
    phoneNumber,
    email,
    fnameError,
    lnameError,
    phoneError,
    emailError,
    userType,
  ]);

  console.log(_.isEmpty(userType));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [isMobileView, setIsMobileView] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < fullScreen) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openBackdrop, setOpenBackdrop] = useState(false);

  function handleBackDropClose() {
    setOpenBackdrop(false);
  }

  function handleBackDropOpen() {
    setOpenBackdrop(true);
  }

  // Material

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  console.log(userType);
  return (
    <>
      <Page className={classes.root}>
        <Container maxWidth={false}>
          <Box mt={1}>
            <Paper className={classes.paper}>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={"xs"}
              >
                <DialogContent>
                  <Box mt={1}>
                    <Box
                      alignItems="center"
                      display="flex"
                      flexDirection="column"
                    >
                      <LockOutlinedIcon className={classes.avatar} />
                      <Typography
                        component="h1"
                        variant="h5"
                        className={classes.Typography}
                      >
                        Manage User
                      </Typography>{" "}
                    </Box>
                    <form noValidate>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        size="small"
                        className={classes.formControl}
                      >
                        <InputLabel htmlFor="manageuser">
                          Manage User
                        </InputLabel>
                        <Select
                          native
                          value={userType}
                          onChange={handleSelectChange}
                          label="Manage User"
                          helperText={selectError}
                          error={selectError}
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                          inputProps={{
                            name: "manageUser",
                            id: "manageuser",
                          }}
                        >
                          <option aria-label="None" value="" />
                          <option value="ServiceEngineer">
                            Service Engineer
                          </option>
                          <option value="Admin">Admin</option>
                          <option value="Dealer">Dealer</option>
                          <option value="Corporate">Corporate</option>
                          <option value="EndUser">EndUser</option>
                        </Select>
                        <FormHelperText error id="accountId-error">
                          {selectError}
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        size="small"
                        className={classes.formControl}
                        style={{
                          display:
                            userType === "ServiceEngineer" ? "block" : "none",
                        }}
                      >
                        <InputLabel id="demo-mutiple-checkbox-label">
                          Category
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          multiple
                          label="Category"
                          value={category}
                          onChange={(e) => handleChange(e)}
                          variant="outlined"
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {categories.map((mCategory) => (
                            <MenuItem
                              key={mCategory.docData.categoryName}
                              value={mCategory.docData.categoryName}
                            >
                              <Checkbox
                                color="primary"
                                checked={
                                  category.indexOf(
                                    mCategory.docData.categoryName
                                  ) > -1
                                }
                              />
                              <ListItemText
                                primary={mCategory.docData.categoryName}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        autoComplete="fname"
                        name="firstName"
                        variant="outlined"
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        onChange={handleFnameChange}
                        helperText={fnameError}
                        error={fnameError}
                        className={classes.formControl}
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        size="small"
                      />{" "}
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="lname"
                        onChange={handleLnameChnage}
                        className={classes.formControl}
                        size="small"
                        helperText={lnameError}
                        error={lnameError}
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                      />{" "}
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email1"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        onChange={handleEmailChange}
                        className={classes.formControl}
                        size="small"
                        helperText={emailError}
                        error={emailError}
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                      />{" "}
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="phoneNumber"
                        label="Phone Number "
                        name="phoneNumber"
                        autoComplete="phoneNumber"
                        onChange={handlePhoneChange}
                        className={classes.formControl}
                        size="small"
                        helperText={phoneError}
                        error={phoneError}
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                      />{" "}
                    </form>{" "}
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    onClick={(e) => handleClick(e)}
                    disabled={btndisabled}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>

              <div
                style={{
                  overflowX: "auto",
                  display: isMobileView || isMobile === true ? "block" : "none",
                }}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.header}
                >
                  Existing Users
                  <Fab
                    color="primary"
                    aria-label="add"
                    size="small"
                    style={{ float: "right" }}
                    onClick={handleClickOpen}
                  >
                    <AddIcon />
                  </Fab>
                </Typography>

                <Card alignItems="center">
                  <CardContent>
                    <Table size="small">
                      <TableBody>
                        {users.slice(0, limit).map((user) => (
                          <TableRow hover key={user.docId}>
                            <TableCell>
                              <div className={classes.root}>
                                <Paper className={classes.paper1}>
                                  <Grid container spacing={1}>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={2}
                                      style={{ textAlign: "left" }}
                                    >
                                      <b> Usertype: </b>{" "}
                                      {`${user.docData.userType}`}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={2}
                                      style={{ textAlign: "left" }}
                                    >
                                      <b> Name:</b>{" "}
                                      {`${user.docData.firstName} ${user.docData.lastName} `}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={2}
                                      style={{ textAlign: "left" }}
                                    >
                                      <b>Phone:</b>{" "}
                                      {` ${
                                        _.isUndefined(user.docData.phoneNumber)
                                          ? ""
                                          : user.docData.phoneNumber
                                      }`}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={2}
                                      style={{ textAlign: "left" }}
                                    >
                                      <b>Email:</b> {` ${user.docData.email}`}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={2}
                                      style={{ textAlign: "center" }}
                                    >
                                      <Link
                                        to={`user-details/${user.docData.userType}/${user.docId}`}
                                      >
                                        <IconButton>
                                          <Edit />
                                        </IconButton>
                                      </Link>
                                      <Link
                                        to={`user-details/${user.docData.userType}/${user.docId}`}
                                      >
                                        <IconButton
                                          onClick={(e) =>
                                            deleteUser(
                                              e,
                                              user.docData.uid,
                                              user.docData.email
                                            )
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Link>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </div>

              <div
                style={{
                  display: isMobileView || isMobile === true ? "none" : "block",
                }}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.header}
                >
                  Existing Users
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      float: "right",
                      display: isMobileView || isMobile ? "none" : "block",
                    }}
                    onClick={handleClickOpen}
                  >
                    Add User
                  </Button>
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>User Type</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.slice(0, limit).map((user) => (
                      <TableRow hover key={user.docId}>
                        <TableCell>{`${user.docData.userType}`}</TableCell>
                        <TableCell>
                          {`${user.docData.firstName} ${user.docData.lastName} `}
                        </TableCell>
                        <TableCell>{user.docData.email}</TableCell>
                        <TableCell>
                          {_.isUndefined(user.docData.phoneNumber)
                            ? ""
                            : user.docData.phoneNumber}
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`user-details/${user.docData.userType}/${user.docId}`}
                          >
                            <IconButton>
                              <Edit />
                            </IconButton>
                          </Link>
                          <Link
                            to={`user-details/${user.docData.userType}/${user.docId}`}
                          >
                            <IconButton
                              onClick={(e) =>
                                deleteUser(
                                  e,
                                  user.docData.uid,
                                  user.docData.email
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Paper>
          </Box>{" "}
        </Container>{" "}
      </Page>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgressBar display={true} />
      </Backdrop>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
