import { Switch, Route } from "react-router-dom";
import SignOut from "./auth/SignOut";
import ManageUsers from "./dashboard/users/ManageUsers";
import VerifyEmail from "./auth/VerifyEmail";
import EnquiryListView from "./dashboard/enquiry/list";
import ResponseDialog from "./dashboard/enquiry/list/ResponseDialog";
import ServiceRequestListView from "./dashboard/service-request/list";
import NotFoundView from "./errors/NotFoundView";
import Account from "./dashboard/profile";
import Categories from "./dashboard/category/Categories";
import UserDetails from "./dashboard/users/UserDetails";
import SrDetails from "./dashboard/service-request/forms/view/SrDetails";
import ServiceRequestCheckout from "./dashboard/service-request/forms/create/ServiceRequestCheckout";
import payments from "./dashboard/payments/list/Payments";
import Settings from "./dashboard/settings/Settings";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/manageusers" component={ManageUsers} />
      <Route exact path="/signout" component={SignOut} />
      <Route exact path="/verify" component={VerifyEmail} />
      <Route exact path="/sr" component={ServiceRequestCheckout} />
      <Route exact path="/account" component={Account} />
      <Route exact path="/categories" component={Categories} />
      <Route exact path="/sr-details/:id/:uid" component={SrDetails} />
      <Route
        exact
        path="/user-details/:userType/:uid"
        component={UserDetails}
      />
      <Route exact path="/enquiry-response" component={ResponseDialog} />
      <Route exact path="/enquiries" component={EnquiryListView} />
      <Route exact path="/payments" component={payments} />
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/" component={ServiceRequestListView} />
      <Route path="*" component={NotFoundView} />
    </Switch>
  );
}
