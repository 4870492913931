import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import moment from "moment";

const RequestDetails = (props) => {
  return (
    <>
      <Grid item xs={12} sm={4}>
        <Card>
          <CardContent>
            <Typography
              component="h1"
              variant="body1"
              style={{ fontWeight: "bold" }}
            >
              Service Details
            </Typography>{" "}
            <Typography
              component="h1"
              variant="body2"
              style={{ color: props.dateAndTimeColor }}
            >
              {`${props.serviceType} Service Date: `}{" "}
              {moment.unix(props.serviceDate.seconds).format("DD/MM/YYYY")}
            </Typography>{" "}
            <Typography
              component="h1"
              variant="body2"
              style={{ color: props.dateAndTimeColor }}
            >
              {`${props.serviceType} From Time: `}{" "}
              {moment.unix(props.serviceFromTime).format("HH:mm A")}
            </Typography>{" "}
            <Typography
              component="h1"
              variant="body2"
              style={{ color: props.dateAndTimeColor }}
            >
              {`${props.serviceType} Till Time: `}{" "}
              {moment.unix(props.serviceTillTime).format("HH:mm A")}
            </Typography>{" "}
            <Typography
              component="h1"
              variant="body2"
              style={{
                color: props.minCharges === 0 ? "red" : "black",
              }}
            >
              Minimum Service Charges: ₹ {props.minCharges}
            </Typography>{" "}
            <Typography component="h1" variant="body2">
              Service Type:{props.serviceType}
            </Typography>{" "}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card>
          <CardContent>
            <Typography
              component="h1"
              variant="body1"
              style={{ fontWeight: "bold" }}
            >
              Product Details
            </Typography>{" "}
            <Typography component="h1" variant="body2">
              Product Name: {props.productName}
            </Typography>{" "}
            <Typography component="h1" variant="body2">
              Product Category: {props.productCategory}
            </Typography>{" "}
            <Typography component="h1" variant="body2">
              Product Brand: {props.productBrand}
            </Typography>{" "}
            <Typography component="h1" variant="body2">
              Product Serial Number: {props.productSerialNumber}
            </Typography>{" "}
            <Typography component="h1" variant="body2">
              Product Model:{props.productModel}
            </Typography>{" "}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card>
          <CardContent>
            <Typography
              component="h1"
              variant="body1"
              style={{ fontWeight: "bold" }}
            >
              Address Details
            </Typography>{" "}
            <Typography component="h1" variant="body2">
              Address Line1: {props.addressLine1}
            </Typography>{" "}
            <Typography component="h1" variant="body2">
              Address Line2: {props.addressLine2}
            </Typography>{" "}
            <Typography component="h1" variant="body2">
              City: {props.city}
            </Typography>{" "}
            <Typography component="h1" variant="body2">
              State:{props.state}
            </Typography>{" "}
            <Typography component="h1" variant="body2">
              Pin Code:{props.pinCode}
            </Typography>{" "}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};
export default RequestDetails;
