import app from "../firebase/FirebaseAppService";

export default async function userSignUp(userType, email, firstName, lastName, phoneNumber,category) {
    try {
        let claims = {};
        let mUserType = "";
        if (userType === "Admin") {
            claims.adminUser = true;
            claims.admin = false;
            claims.serviceEngineer = false;
            claims.corporateUser = false;
            claims.dealer = false;
            claims.user = false;
            mUserType = "Admin User";
        } else if (userType === "Dealer") {
            claims.adminUser = false;
            claims.admin = false;
            claims.serviceEngineer = false;
            claims.corporateUser = false;
            claims.dealer = true;
            claims.user = false;
            mUserType = "Dealer";
        } else if (userType === "Corporate") {
            claims.adminUser = false;
            claims.admin = false;
            claims.serviceEngineer = false;
            claims.corporateUser = true;
            claims.dealer = false;
            claims.user = false;
            mUserType = "Corporate";
        } else if (userType === "ServiceEngineer") {
            claims.adminUser = false;
            claims.admin = false;
            claims.serviceEngineer = true;
            claims.corporateUser = false;
            claims.dealer = false;
            claims.user = false;
            mUserType = "Service Engineer";
        } else if (userType === "EndUser") {
            claims.adminUser = false;
            claims.admin = false;
            claims.serviceEngineer = false;
            claims.corporateUser = false;
            claims.dealer = false;
            claims.user = true;
            mUserType = "End User";
        }
        const createUserWithClaims = app.functions().httpsCallable('createUserWithClaims');
        const idToken = await app.auth().currentUser.getIdToken();
        const result = await createUserWithClaims({
            email: email,
            idToken,
            fname: firstName,
            lname: lastName,
            phoneNumber: phoneNumber,
            userType: mUserType,
            customClaims: claims,
            category
        });
        console.log(result);
        if (result.data.status === "success") {
            return {
                status: "success",
                userRecord: result.data.userRecord
            }
        } else {
            return {
                status: "failure",
            }
        }
        // [END fb_functions_call_add_message]*/
    } catch (error) {
        console.log(error);
        alert('unable to create user, email id might already exists');

        return {
            status: "failure",
        }
    }
}