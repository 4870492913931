export const fillProductDetails = (productDetails) => {
    return {
        type: "complete/productDetails",
        payload: productDetails,
    };
};

export const fillServiceDetails = (serviceDetails) => {
    return {
        type: "complete/serviceDetails",
        payload: serviceDetails,
    };
};

export const fillAddressDetails = (addressDetails) => {
    return {
        type: "complete/addressDetails",
        payload: addressDetails,
    };
};

export const emptyServiceRequest = () => {
    return {
        type: "empty/sr",
        payload: null,
    };
};

export const setWorkFlowActiveState = (activeStep) => {
    return {
        type: "activestep/sr",
        payload: activeStep,
    };
}

export const fetchNewServiceRequests = (newSrList) => {
    return {
        type: "fetch/newSrList",
        payload: newSrList,
    };
}

export const emptyNewServiceRequests = () => {
    return {
        type: "empty/newSrList",
        payload: null,
    };
}

export const fetchEstimate = (estimate) => {
    return {
        type: "set/estimate",
        payload: estimate,
    };
}

export const emptyEstimate = () => {
    return {
        type: "empty/estimate",
        payload: null,
    };
}