const initialState = {
    workFlowActiveStep: 0,
    newEnquiriesList: []
}

export default function enquiryReducer(state = initialState, action) {
    switch (action.type) {
        case 'activestep/enquiry':
            return {
                ...state,
                workFlowActiveStep: action.payload
            }
        case 'fetch/newEnquiryList':
            return {
                ...state,
                newEnquiriesList: action.payload
            }
        case 'empty/newEnquiryList':
            return {
                ...state,
                newEnquiriesList: []
            }
        default:
            return state
    }
}