import { Avatar, Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import { useEffect, useState } from "react";
import getInitials from "../../../../../utils/getInitials";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import _ from "lodash";
import ContactDetails from "./ContactDetails";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  avatar2: {
    position: "relative",
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: "50%",
    padding: theme.spacing(0.5),
  },
  card:{
    height:"130px"
  }
}));

const UserInfo = (props) => {
  const [serviceEngineerfName, setServiceEngineerfName] = useState("");
  const [serviceEngineerlName, setServiceEngineerlName] = useState("");

  const [serviceEngineerEmail, setServiceEngineerEmail] = useState("");
  const [serviceEngineerContact, setServiceEngineerContact] = useState("");

  const [hiddenEngineer, setHideEngineer] = useState(true);
  const [shortGrid, setShortGrid] = useState(6);

  const [userHidden, setUserHidden] = useState(true);

  useEffect(() => {
    async function fetchEngineerDetails() {
      if (
        props.serviceRequest &&
        props.serviceRequest.workAssignment &&
        props.serviceRequest.workAssignment.engineer
      ) {
        const engineer = props.serviceRequest.workAssignment.engineer;
        setHideEngineer(false);
        if (props.serviceRequest && props.serviceRequest.serviceRequestStatus) {
          if (
            props.serviceRequest.serviceRequestStatus.includes(
              "Work Completed"
            ) ||
            props.serviceRequest.serviceRequestStatus.includes("Cancelled")
          ) {
            if (props.authReducer.claims !== "serviceEngineer") {
              setShortGrid(4);
              setUserHidden(false);
            } else {
              setShortGrid(6);
              setUserHidden(true);
            }
          } else {
            setShortGrid(4);
            setUserHidden(false);
          }
        }
        _.isUndefined(engineer)
          ? setServiceEngineerfName("N/A")
          : setServiceEngineerfName(engineer.firstName);

        _.isUndefined(engineer)
          ? setServiceEngineerlName("N/A")
          : setServiceEngineerlName(engineer.lastName);

        _.isUndefined(engineer)
          ? setServiceEngineerEmail("N/A")
          : setServiceEngineerEmail(engineer.email);

        _.isUndefined(engineer)
          ? setServiceEngineerContact("N/A")
          : setServiceEngineerContact(engineer.phoneNumber);
      } else {
        setShortGrid(6);
        setUserHidden(false);
      }
    }
    fetchEngineerDetails();
  }, [props, props.authReducer.claims, props.serviceRequest, props.userName]);

  const classes = useStyles();

  return (
    <>
      {console.log(shortGrid)}
      <Grid item xs={12} sm={shortGrid}>
        <Card className={classes.card}>
           <Typography variant="subtitle1" style={{margin:"1% 0 0 5%",fontWeight:"bold"}}>
            Service Request Information
          </Typography>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar2}>
                <InfoIcon />
              </Avatar>
            }
            title={`Service Request Id: ${props.referenceId}`}
            subheader={`Status: ${props.requestStatus}`}
            
          />
          {props.serviceRequest.cancellationReason ? (
            <CardContent>
              Cancellation Reason: {props.serviceRequest.cancellationReason}
            </CardContent>
          ) : (
            ""
          )}
        </Card>
      </Grid>
      <Grid item xs={12} sm={shortGrid} hidden={userHidden}>
        <Card className={classes.card}>
         <Typography variant="subtitle1" style={{margin:"1% 0 0 5%",fontWeight:"bold"}}>
            User Information
          </Typography>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar2}>
                {props.initials}
              </Avatar>
            }
            title={props.userName}
            subheader={
              <ContactDetails
                email={`Email: ${props.userEmail}`}
                phoneNumber={`Phone: ${props.userPhoneNumber}`}
              />
            }
          />
        </Card>
      </Grid>
      <Grid item xs={12} sm={shortGrid} hidden={hiddenEngineer}>
        <Card className={classes.card}>
        <Typography variant="subtitle1" style={{margin:"1% 0 0 5%",fontWeight:"bold"}}>
           Service Engineer Information
          </Typography>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar2}>
                <PersonOutlineIcon />
              </Avatar>
            }
            title={`Service Engineer: ${serviceEngineerfName} ${serviceEngineerlName}`}
            subheader={
              <ContactDetails
                email={`Email: ${serviceEngineerEmail}`}
                phoneNumber={`Phone: ${serviceEngineerContact}`}
              />
            }
          />
        </Card>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(UserInfo);
