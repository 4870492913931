import { combineReducers } from "redux";
import authReducer from "./auth";
import srReducer from "./sr";
import enquiryReducer from "./enq"
import profileReducer from "./profile";
import paymentReducer from "./payment";

export default combineReducers({
    authReducer,
    srReducer,
    enquiryReducer,
    profileReducer,
    paymentReducer
})