import {
  Container,
  Box,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  useMediaQuery,
  useTheme,
  Paper,
  Divider,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Page from "../../support/page/Page";
import TextField from "@material-ui/core/TextField";
import getProductCatagories from "../../../services/category/index";
import { addCategory } from "../../../services/category/index";
import AddIcon from "@material-ui/icons/Add";
import { getUserCategories } from "../../../services/category/index";
import PerfectScrollbar from "react-perfect-scrollbar";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteExistingCategory } from "../../../services/category/index";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "85%",
    paddingBottom: theme.spacing(3),
  },
  btn: {
    border: "none",
    "&:hover": {
      color: "crimson",
    },
  },
  addBtn: {
    border: "none",
    fontWeight: "bold",
    "&:hover": {
      color: "Green",
    },
  },
  avatar2: {
    position: "relative",
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    // height: theme.spacing(6),
    // width: theme.spacing(6),
    // margin: theme.spacing(2),
    borderRadius: "50%",
    padding: theme.spacing(0.5),
    fontSize: theme.spacing(5),
    marginLeft: "2%",
  },
  Typography: {
    float: "inherit",
    display: "flex",
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginTop: "1%",
  },
  box: {
    display: "flex",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3.5),
  },
  addinp: {
    margin: "0 3% ",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  header: {
    margin: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  listRoot: {
    width: "100%",
    maxWidth: "relative",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 450,
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  listRootOfManageCategories: {
    width: "100%",
    maxWidth: "relative",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 400,
  },
}));

export default function Categories() {
  const classes = useStyles();
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [userCategories, setUserCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const itemsPerPage = 10;

  const [isMobileView, setIsMobileView] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 767) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  });

  const [open, setOpen] = React.useState(false);
  const [openSubCat, setOpenSubCat] = React.useState(false);
  const [openManageCat, setOpenManageCat] = useState(false);
  const [minCharges, setMinCharges] = useState();

  const [MainCategory, setMainCategory] = useState("");

  const handleMinChargesChange = (e) => {
    setMinCharges(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenSubCat = () => {
    setOpenSubCat(true);
  };
  const handleCloseSubCat = () => {
    setOpenSubCat(false);
  };

  const handleOpenManageUserCat = () => {
    setOpenManageCat(true);
  };

  const handleCloseManageUserCat = () => {
    setOpenManageCat(false);
  };

  const handleSelectMainCategory = (e) => {
    setMainCategory(e.target.value);
  };

  useEffect(() => {
    async function fetchData() {
      const result = await getProductCatagories();
      console.log("users " + result.docs);
      if (result.status === "success") {
        setCategories([...result.docs]);
        console.log("users " + result.docs);
      }
      const userCategory = await getUserCategories();
      if (userCategory.status === "success") {
        setUserCategories([...userCategory.docs]);
        console.log("users " + [...userCategory.docs]);
      }
    }
    fetchData();
  }, []);

  async function handleClick(e, category) {
    e.preventDefault();
    const result = await addCategory("parent", category, null, null, null);
    setOpen(false);

    if (result.status === "success") {
      setCategories([
        ...categories,
        {
          docData: {
            categoryName: _.capitalize(category),
            isParentCategory: true,
            parentCategoryId: null,
            parentCategoryName: null,
            isEnabled: true,
          },
          docId: result.docId,
          docRef: result.docRef,
        },
      ]);
    }
  }
  console.log(categories);

  async function handleClickSubCategory(
    e,
    SubCategory,
    mainCategoryId,
    mainCategory,
    MinCharges
  ) {
    setOpenSubCat(false);

    const result = await addCategory(
      "child",
      SubCategory,
      mainCategoryId,
      mainCategory,
      MinCharges
    );
    if (result.status === "success") {
      console.log(subCategory);
      setCategories([
        ...categories,
        {
          docData: {
            categoryName: _.capitalize(subCategory),
            isParentCategory: false,
            parentCategoryId: mainCategoryId,
            parentCategoryName: mainCategory,
            isEnabled: true,
            minimumCharges: MinCharges,
          },
          docId: result.docId,
          docRef: result.docRef,
        },
      ]);
      setMainCategory("");
      setSubCategory("");
      setMinCharges();
    }
  }

  function handleCategoryChange(e) {
    e.preventDefault();
    setCategory(e.target.value);
  }

  function handleSubCategoryChange(e) {
    e.preventDefault();
    setSubCategory(e.target.value);
  }

  async function handleAddIcon(e, category, docRef) {
    e.preventDefault();
    setOpenSubCat(true);
    setSubCategory(category);
  }

  async function handleDeleteIcon(e, category, docRef) {
    console.log(docRef);
    e.preventDefault();
    const deleteCategory = await deleteExistingCategory(docRef);
    console.log(deleteCategory);
    if (deleteCategory.status === "success") {
      const filteredResult = categories.filter(
        (categoryItem) => categoryItem.docData.categoryName !== category
      );
      setCategories([...filteredResult]);

      const filteredUserCategory = userCategories.filter(
        (categoryItem) => categoryItem.docData.categoryName !== category
      );
      setUserCategories([...filteredUserCategory]);
    }
  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [noOfPages] = React.useState(
    Math.ceil(categories.length / itemsPerPage)
  );

  const handleChange = (event, value) => {
    setPage(value);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Page className={classes.root}>
        <Container maxWidth={false}>
          <Box mt={1}>
            <Paper className={classes.paper}>
              <Typography variant="h4" gutterBottom className={classes.header}>
                Product Categories
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClickOpen}
                  style={{
                    float: "right",
                    margin: "2% 2%",
                  }}
                  fullWidth={isMobileView ? true : false}
                >
                  Add Main Category
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClickOpenSubCat}
                  style={{ float: "right", margin: "2% 2%" }}
                  fullWidth={isMobileView ? true : false}
                >
                  Add Sub Category
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleOpenManageUserCat}
                  style={{ float: "right", margin: "2% 2%" }}
                  fullWidth={isMobileView ? true : false}
                >
                  Manage Other Categories
                </Button>
              </Typography>

              {/* User suggested categories */}
              <Dialog
                open={openManageCat}
                onClose={handleCloseManageUserCat}
                aria-labelledby="form-dialog-title"
                maxWidth={"xs"}
              >
                <DialogTitle id="form-dialog-title">
                  User Suggested Categories{" "}
                </DialogTitle>
                <PerfectScrollbar>
                  <List
                    className={classes.listRootOfManageCategories}
                    subheader={<li />}
                  >
                    {userCategories.slice(0).map((userCategory) => (
                      <li
                        key={`userCategory-${userCategory.docId}`}
                        className={classes.listSection}
                      >
                        <ul className={classes.ul}>
                          <ListItem key={`${userCategory.docId}`}>
                            <ListItemText
                              primary={`${userCategory.docData.categoryName}`}
                            />
                            <Button
                              className={classes.addBtn}
                              onClick={(e) =>
                                handleAddIcon(
                                  e,
                                  userCategory.docData.categoryName,
                                  userCategory.docRef
                                )
                              }
                            >
                              <AddIcon />
                            </Button>
                            <Button
                              className={classes.btn}
                              onClick={(e) =>
                                handleDeleteIcon(
                                  e,
                                  userCategory.docData.categoryName,
                                  userCategory.docRef
                                )
                              }
                            >
                              <DeleteIcon />
                            </Button>
                          </ListItem>
                        </ul>
                      </li>
                    ))}
                  </List>
                </PerfectScrollbar>

                <DialogActions>
                  <Button onClick={handleCloseManageUserCat} color="primary">
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Main Categories */}
              <div className={classes.addinp}>
                <Dialog
                  fullWidth
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Add Main Category
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      label="Category Name"
                      variant="outlined"
                      value={category}
                      onChange={handleCategoryChange}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button
                      onClick={(e) => handleClick(e, category)}
                      color="primary"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>

              {/* Add Sub Category */}
              <div className={classes.addinp}>
                <Dialog
                  fullWidth
                  open={openSubCat}
                  onClose={handleCloseSubCat}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Add Sub Category
                  </DialogTitle>
                  <DialogContent>
                    <FormControl variant="outlined" fullWidth size="small">
                      <InputLabel htmlFor="productcategory">
                        Main Category
                      </InputLabel>
                      <Select
                        fullWidth
                        label="Main Category"
                        id="productcategory"
                        value={MainCategory || ""}
                        onChange={handleSelectMainCategory}
                        variant="outlined"
                        size="small"
                        style={{ marginBottom: "2ch" }}
                      >
                        {categories
                          .filter(
                            (category) =>
                              category.docData.isParentCategory === true
                          )
                          .map((mainCategory) => (
                            <MenuItem
                              value={mainCategory}
                            >{`${mainCategory.docData.categoryName}`}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      label="Category Name"
                      variant="outlined"
                      value={subCategory}
                      onChange={handleSubCategoryChange}
                      style={{ marginBottom: "2ch" }}
                    />
                    <TextField
                      fullWidth
                      type="number"
                      size="small"
                      id="outlined-basic"
                      label="Minimum Charges"
                      variant="outlined"
                      value={minCharges}
                      onChange={handleMinChargesChange}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseSubCat} color="primary">
                      Cancel
                    </Button>
                    <Button
                      onClick={(e) =>
                        handleClickSubCategory(
                          e,
                          subCategory,
                          MainCategory.docId,
                          MainCategory.docData.categoryName,
                          minCharges
                        )
                      }
                      color="primary"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>

              {/* Existing product categories */}
              <div>
                <PerfectScrollbar>
                  <List className={classes.listRoot} subheader={<li />}>
                    {categories
                      .filter(
                        (category) => category.docData.isParentCategory === true
                      )
                      .map((mainCategory) => (
                        <li
                          key={`mainCategory-${mainCategory.docId}`}
                          className={classes.listSection}
                        >
                          <ul className={classes.ul}>
                            <List>
                              <ListSubheader>
                                {`${mainCategory.docData.categoryName} `}
                              </ListSubheader>
                            </List>

                            {categories
                              .filter(
                                (category) =>
                                  category.docData.parentCategoryId ===
                                  mainCategory.docId
                              )
                              .map((subCategory) => (
                                <ListItem key={`${subCategory.docId}`}>
                                  <ListItemText
                                    primary={` ${subCategory.docData.categoryName} `}
                                  />
                                  {`${
                                    _.isUndefined(
                                      subCategory.docData.minimumCharges
                                    )
                                      ? " "
                                      : "Minimum Charges: " +
                                        subCategory.docData.minimumCharges
                                  }`}

                                  <Button
                                    className={classes.btn}
                                    onClick={(e) =>
                                      handleDeleteIcon(
                                        e,
                                        subCategory.docData.categoryName,
                                        subCategory.docRef
                                      )
                                    }
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </ListItem>
                              ))}
                            <Divider />
                          </ul>
                        </li>
                      ))}
                  </List>
                </PerfectScrollbar>
              </div>
            </Paper>
          </Box>
        </Container>
      </Page>
    </>
  );
}
