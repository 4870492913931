export const userSignedIn = (user) => {
    return {
        type: "user/signedin",
        payload: user,
    };
};
export const userSignedOut = () => {
    return {
        type: "user/signedout",
        payload: null,
    };
};

export const authCheckDone = (claims) => {
    return {
        type: "auth/check/done",
        payload: claims
    };
}